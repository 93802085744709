#logOut {
    margin: 150px auto;
    text-align: center;
    margin-left: 10px;
    width: 300px;
    h2 {
        text-align: center;
        margin-bottom: 0;
    }

   
}

@media only screen and (max-width: 767px) {
    #logOut {
    text-align: center;
    margin: auto;
    }
}