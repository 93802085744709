#ambassadorCodes {
    margin-top: 50px;
    width: 100%;

    span.generateVoucher {
        margin-left: 30px;
        cursor: pointer;
        &#isMobile {
            display: none;
        }
        &#isDesktop {
            display: block;
        }
        .arrow {
            border: solid black;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            margin-left: 6px;
            margin-bottom: 2px;
        }
    }

    .greenBox p {
        max-width: fit-content;
    }

    .containerButton {
        display: flex;
        flex-direction: column;
        width: fit-content;
        justify-content: space-between;
    }

    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    .table {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border-spacing: 0;

        .tableHead {
            background-color: #e4f4fb;
            border-radius: 8px 8px 0 0;
            border-bottom: 0;
        }

        th {
            padding: 30px 30px 30px 30px;
            color: #1d313e;
            text-align: center;
            font-weight: 600;
            width: 30%;
            font-size: 14.5px;

            &:first-child {
                border-radius: 10px 0 0 0;
                width: 40%;
            }

            &:last-child {
                border-radius: 10px 0 0 0;
                border-radius: 0 10px 0 0;
            }
        }

        tr {
            // cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14.5px;
        }

        td {
            padding: 20px 30px 20px 30px;
            text-align: center;
            // cursor: pointer;
            width: 30%;

            &:first-child {
                width: 40%;
            }

            span {
                font-family: "SpaceGrotesk-Medium";
            }

            input {
                height: 30px;
                border-radius: 7px;
                font-size: 16px;
                font-family: "SpaceGrotesk";
                box-sizing: border-box;
                padding: 23px 15px;

                &:focus {
                    outline: #326bc8 auto 1px;
                    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
                }
            }
            .usersList {
                span {
                    text-align: right;
                    font-family: "SpaceGrotesk";
                }
            }
        }

        .titleMessages {
            padding: 5px;
            background-color: #e4f4fb;
            border-radius: 5px;
            width: max-content;
            margin-top: 5px;
            font-size: 13px;
        }
    }

    p.infoText {
        margin-top: 0;
        color: #1eb74c;
    }

    .primaryBtn {
        margin-top: 30px;
        width: auto;
        padding: 15px 20px;
        height: auto;
        font-size: 16px;

        &:hover {
            border: none;
        }
    }
}

@media only screen and (max-width: 996px) {
    #ambassadorCodes {
        margin-top: 50px;
        width: 100%;
        margin: auto;

        span.generateVoucher {
            padding: 10px 0 30px;
            font-family: 'SpaceGrotesk-SemiBold';
            &#isMobile {
                display: block;
            }
            &#isDesktop {
                display: none;
            }
        }
        h2 {
            margin-bottom: 60px;
            margin-top: 40px;
        }

        button {
            display: flex;
            margin: auto;
            margin-top: 47px;
        }
    }
}

@media (max-width: 30em) {
    #ambassadorCodes {
        .codeName {
            width: 100% !important;
            padding-left: 0 !important;
            box-sizing: border-box !important;
            input {
                width: 90%;
            }
        }

        .table {
            .tableHead {
                position: absolute;
                top: -9999em;
                left: -9999em;
            }

            .ambassadorRow {
                display: block;
                width: auto;
                border-radius: 10px;
                margin-bottom: 20px;
                border-bottom: 0;
                box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 33%);
                padding: 0 10px;
            }

            td {
                padding-left: 50%;
                display: flex;
                width: auto;
                border: none;
                text-align: left;
                justify-content: space-between;
                align-items: center;

                &:first-child {
                    width: auto;
                }

                &:before {
                    content: attr(data-label);
                    display: inline-block;
                    font-weight: bold;
                    line-height: 1.5;
                    margin-left: -100%;
                    width: 100%;
                }

                .usersList {
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                    span {
                        text-align: right;
                        font-family: "SpaceGrotesk";
                    }
                }
            }
        }
    }
}

@media (max-width: 20em) {
    #ambassadorCodes {
        .table {
            td {
                padding-left: 0.75em;

                &:before {
                    display: block;
                    margin-bottom: 0.75em;
                    margin-left: 0;
                }
            }
        }
    }
}
