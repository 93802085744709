#ourTrips {
    display: flex;

    #listOfTrips {
        margin-top: 50px;
        width: 60%;
        background-color: white;
        min-height: 700px;
        padding: 100px 25px 80px 25px;
        margin-left: auto;
        margin-right: 0;

        .container {
            margin-left: -150px;
            width: auto;
            h2, h1 {
                text-align: center;
                margin-bottom: 60px;
                font-size: 30px;
                p {
                    float: right;
                    font-size: 15px;
                    span {
                        font-family: "SpaceGrotesk-Medium";
                        cursor: pointer;

                        i.arrow {
                            border: solid #000000c2;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            margin-bottom: 2px;
                            margin-left: 3px;
                            &.down {
                                transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    #ourTrips {
        #listOfTrips {
            .container {
                margin: 0;
                h2, h1 {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;

                    p {
                        span {
                            i.arrow {
                                &.down {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #ourTrips {
        display: flex;
        flex-direction: column;
        background: white;
        justify-content: center;
        align-items: center;

        #listOfTrips {
            margin-top: 50px;
            width: 100%;
            margin: auto;
            width: 100%;
            background-color: #fff;
            min-height: 700px;
            padding: 0;
            margin: 0;

            .container {
                margin: 0;
                padding: 0 20px;
                box-sizing: border-box;
                h2, h1 {
                    margin-bottom: 30px;
                    margin-top: 100px;

                    p {
                        float: none;
                        font-size: 15px;
                        text-align: center;
                        span {
                            i.arrow {
                                &.down {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
