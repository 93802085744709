#partnersContainer {
    background-color: #f2f2f2;
    display: flex;
    .partnersWrapper {
        width: 1000px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        max-width: 90vw;
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    #partnersContainer {
   
        .partnersWrapper {
            padding: 0;
            width: 100%;
            max-width: 100%;
          
        }
    }
    
}
