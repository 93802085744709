#team {
    padding-top: 70px;
    // min-height: 1000px;
    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2, h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            margin: 0;
            font-size: 35px;
            width: 100%;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: brightness(0.8);
        }
    }
    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 100px;

        .container {
            width: 1100px;
            margin: auto;
            // border: 1px solid grey;
            height: 100%;
            max-width: 95vw;

            .textSection {
                text-align: center;
                width: 650px;
                margin: auto;
                max-width: 90vw;
                h2, h1 {
                    font-size: 25px;
                    margin-bottom: 0;
                    margin-top: 0;
                    padding-top: 50px;
                }
                P {
                    font-size: 16px;
                    font-family: "SpaceGrotesk";
                    line-height: 1.6;
                    margin-top: 40px;
                }
            }
        }
        .boxes {
            width: 1100px;
            display: flex;
            justify-content: space-around;
            margin: auto;
            margin-top: 80px;
            flex-wrap: wrap;
            .oneBox {
                width: 300px;
                height: auto;
                border-radius: 1px solid grey;
                margin: auto;
                margin-bottom: 50px;

                .imageBox {
                    width: 100%;
                    height: 230px;
                    margin: auto;
                    box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    border-radius: 15px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 15px;
                    }
                }

                p {
                    &.name {
                        text-align: center;
                        font-family: "SpaceGrotesk-Medium";
                    }

                    &.description {
                        color: black;
                        text-align: center;
                        font-family: "SpaceGrotesk";
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #team {
        
        .slider {
            h2, h1 {
                top: 60%;
            }
            img {
            }
        }
        .info {
            .container {
                .textSection {
                    h2, h1 {
                    }
                    P {
                    }
                }
            }
            .boxes {
                width: auto;
                .oneBox {
                    .imageBox {
                        img {
                        }
                    }

                    p {
                        &.name {
                        }

                        &.description {
                        }
                    }
                }
            }
        }
    }
}
