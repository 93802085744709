#stepSubmit {
    padding: 50px;
    h4 {
        text-align: center;
    }

    .container {
        width: 50%;
        background-color: white;
        height: auto;
        min-height: 400px;
        background: white;
        border-radius: 10px;
        padding: 0;
        padding-bottom: 50px;
        box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
        .greenBox {
            width: 400px;
            margin: auto;
            
        }

        .image {
            width: 100%;
            height: 300px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0 0;
            }
        }

        
    }
}

@media only screen and (max-width: 768px) {
    #stepSubmit {
    
        .container {
       width: 100%;
            .greenBox {
                width: 90%;
            }
    
            .image {
               
    
                img {
                   
                }
            }
    
            
        }
    }
}