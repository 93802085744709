#classicCruiseWrapper {
    padding: 50px 0;
    min-height: 1000px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .classicCruiseTitle {
        text-align: center;
        padding: 0 15px;

        h2 {
            margin: 0 0 15px;
        }
    }

    #filteredTrips {
        padding: 30px 0 0 0;
        margin: auto;
    }

    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            font-size: 35px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }
    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 100px;

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 100px;
                .textSection {
                    width: 40%;
                    h2 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                    }
                }
                .imageSection {
                    width: 45%;
                    height: 320px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;
                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }
                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }
                .textSection {
                    width: 45%;
                }
            }
        }
    }
    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        flex-wrap: wrap;
        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;
            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #classicCruiseWrapper {
        padding-top: 70px;
        .info {
            .container {
                box-sizing: border-box;
                padding-top: 40px;
                .firstSection,
                .secondSection {
                    padding-top: 0;
                    .textSection {
                        width: 100%;
                        h2 {
                            margin-top: 30px;
                        }
                    }
                    .imageSection {
                        width: 100%;
                    }
                }
            }
        }
        .tags {
            width: auto;
            justify-content: center;
            .oneTag {
                margin-bottom: 30px;
            }
        }
    }
}

#oneTripFiltered {
    width: 100%;
    min-height: 300px;
    border-radius: 5px;
    box-shadow: 2px 26px 68px -21px #b8b6b8;
    display: flex;
    margin-bottom: 50px;
    height: auto;
    cursor: pointer;
    .photoContainer {
        width: 30%;
        position: relative;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            cursor: pointer;
            // filter: brightness(0.4);
        }
        h4 {
            position: absolute;
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
            color: white;
            width: 100%;
            font-weight: 500;
        }
    }

    .photoContainer.reservation {
        width: 30%;
    }
    .tripInfo {
        h3 {
            cursor: pointer;
            color: white;
        }

        .infoTripBox {
            cursor: pointer;
            .far {
                font-size: 20px;
                margin-right: 12px;
            }

            i {
                margin-left: 2px;
            }

            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }
            }
            .dataBox {
                display: flex;
                cursor: pointer;
                .confirmedTrip {
                    font-size: 15px;
                    font-weight: 600;
                    margin-left: 10px;
                    span {
                        margin-right: 5px;

                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }

        .optionsBox {
            margin-top: 30px;
            margin-bottom: 30px;
            cursor: pointer;
            p {
                margin-top: 20px;
                font-size: 15px;
                font-family: "SpaceGrotesk-SemiBold";
            }
        }

        .additionalOptions {
            color: grey;
            p,
            span {
                font-size: 14px;
                color: grey;
                margin: 0;
            }
        }
    }

    .tripInfo.reservation {
        width: auto;
    }
    button {
        margin-top: 30px;
        margin-bottom: 0;
        height: auto;
        padding: 10px 20px;
        width: auto;
        color: #000000;
        font-size: 16px;
        border: none;
        font-family: "SpaceGrotesk-Medium";
    }
}

@media only screen and (max-width: 767px) {
    #oneTripFiltered {
        display: flex;
        flex-direction: column;

        .photoContainer {
            width: 100%;
            height: 30%;
            img {
                border-radius: 10px 10px 0 0;
            }
        }

        .tripInfo {
            h3 {
                text-align: center;
                font-size: 20px;
            }
        }

        .infoTripBox {
            p {
                .confirmedTrip {
                    display: block;
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
        }
        .tripInfo .infoTripBox .dataBox {
            flex-direction: column;
            .confirmedTrip {
                margin-left: 5px;
                span {
                    margin-right: 15px;
                }
            }
            .confirmedTrip {
                display: block;
                margin-top: 100px;
                margin-left: 0;
            }
        }
    }
}
