.onePartner {
    width: 160px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .imageLogo {
        height: 40px;

        img {
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }
    }
    #sf {
        height: 45px;
    }

    .partnersInfo {
        margin-top: 30px;
        margin-left: -5px;

        p {
            min-height: 80px;
            line-height: 1.3;
            width: 140px;
        }

        a {
            text-decoration: none;
            font-weight: 600;
            
            font-size: 15px;
            text-align: left;

            i.arrow {
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                margin-left: 4px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .onePartner {
        padding: 30px 5px;

        .imageLogo {
            height: 25px;
        }
    }
}
