.menuPageSticky{
    position: sticky !important;
    z-index: 10 !important;
}

#menuPage {
    position: absolute;
    background: #123852fc;
    width: 100%;
    top: 0;
    z-index: 1;
    
    .led-red {
        margin: 0;
        margin-left: -8px;
        margin-top: 5px;
        box-shadow: none;
        background-color: #ea4a4a;
        width: 10px;
        height: 10px;
    }

    #linkLog {
      
        color: #ffd15f;
    }

    .imageLogo {
        width: auto;
        height: 100%;
        object-fit: contain;
        max-height: 50px;
        cursor: pointer;
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: transparent;
        color: #ffd15f;
    }

    .nav-link {
        margin: 0 3px;
        font-size: 16px;
        color: white;

        &.active {
            color: #ffd15f;
        }

        &:hover {
            color: #ffd15f;
        }
    }

    navbar-collapse collapse {
        justify-content: space-between;
        margin-left: 20px;
    }

    mr-auto navbar-nav {
        margin-right: 0 !important;
    }

    .dropdown-item {
        box-sizing: border-box;
        color: white;
    }

    .navbar-dark .navbar-toggler-icon {
        cursor: pointer;
    }

    #menuPage .dropdown-item {
        box-sizing: border-box;
        color: white;
    }

    .mr-auto,
    .mx-auto {
        margin-right: 0 !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        background: #123852fc;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        color: #ffd15f;
        text-decoration: none;
        background-color: transparent;
    }

    .bg-dark {
        width: 100%;
        background-color: #123852fc !important;
    }

    // .navbar {
    //     height: 50px;
    //     width: 50%;
    // }

    .menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        padding: 10px 25px;
        box-sizing: border-box;

        ul.navItems {
            display: flex;
            padding: 20px 5px;
            margin-right: 5px;

            li.navItem {
                width: auto;
                list-style-type: none;
                margin: 5px 12px;
                font-size: 16px;

                a {
                    cursor: pointer;
                    color: white;
                    white-space: nowrap;
                    text-decoration: none;
                }
            }
        }

        .arrow {
            border: solid white;
            border-width: 0 2.8px 2.8px 0;
            display: inline-block;
            padding: 2px;
            cursor: pointer;
        }

        .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            margin-bottom: 3px;
            margin-left: 3px;
        }
    }

    .logoBox {
        display: flex;
        align-items: center;
        margin-top: -5px;
        cursor: pointer;

        img {
            height: 50px;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #menuPage {
        position: absolute;
        width: 100%;
        height: 50px;
        background-color: #123852fc;
        z-index: 1;

        .menu {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            // margin: 0 0 0 20px;

            .logoBox {
                margin-top: 3px;
                padding-left: 20px;

                img {
                    height: auto;
                    width: 120px;
                }
            }

            ul.navItems {
                display: none;
            }
        }
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        border: 0;
    }

    #menuPage .mr-auto {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}