#program {
    background-color: white;
    width: 100%;
    padding: 50px 0 30px;
    display: flex;
    flex-direction: column;

    .container {
        max-width: 1200px;
        width: 100%;
        background-color: white;
        align-items: center;

        .mainText {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 90vw;
            margin: auto;
            width: 700px;
            margin-bottom: 20px;

            h2 {
                font-size: 27px;
                margin: 0;
            }

            p {
                text-align: center;
                margin: 30px auto;
            }
        }

        .dayPlan {
            display: flex;
            width: 100%;
            justify-content: space-around;
            flex-wrap: wrap;
            height: auto;

            .oneBox {
                width: 210px;
                min-height: 300px;
                border: 1px solid rgba(128, 128, 128, 0.2);
                border-radius: 10px;
                height: 600px;
                margin-bottom: 20px;
                box-sizing: border-box;
                box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);

                .photoContainer {
                    width: 100%;
                    height: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px 10px 0 0;
                    }
                }

                .textBox {
                    text-align: left;
                    padding: 20px;
                    box-sizing: border-box;
                    height: 50%;

                    h4 {
                        margin: 0;
                        margin-bottom: 10px;
                        text-align: left;
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                        height: 100%;
                    }
                }
            }
        }
    }

    a {
        margin: auto;
        .primaryBtn {
            margin: auto;
            margin-top: 70px;
        }
    }
}

@media only screen and (max-width: 767px) {
    #program {
        // padding-top: 200px;

        .container {
            padding: 0;

            .mainText {
                h2 {}

                p {}
            }

            .dayPlan {
                height: auto;

                .oneBox {
                    width: 260px;
                    margin-bottom: 40px;
                    height: auto;

                    .photoContainer {
                        height: 150px;

                        img {}
                    }

                    .textBox {
                        h4 {}

                        p {}
                    }
                }
            }
        }
    }
}