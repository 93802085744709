$fColor: #666;
$textTestimonial: Poppins, sans-serif;
$bgTestimonials: #6d656514;
$fColor: #666;
$fFamily: Georgia, serif;
$borderImg: rgb(248, 248, 248);
$sColor: rgba(0, 0, 0, 0.32);

.oneTestimonial {
    width: 270px;
    height: 250px;
    text-align: center;
    position: relative;
    border-radius: 6px;
    background-color: white;
    margin: 15px;
    box-shadow: -4px 1px 30px -4px rgba(0, 0, 0, 0.07);

    &:before {
        content: "\201C";
        font-family: $fFamily;
        color: $fColor;
        font-size: 100px;
        position: absolute;
        left: 10px;
    }

    .testimonialImage {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 5px solid $borderImg;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 4px 11px 30px -4px $sColor;
        object-fit: cover;
    }

    .testimonialText {
        overflow: hidden;
        color: #666;
        font-size: 14px;
        text-align: left;
        padding: 70px 25px;
        box-sizing: border-box;
        padding-bottom: 0;
    }

    .testimonialName {
        font-size: 14px;
        color: #666;
        width: 100%;
        text-align: left;
        padding: 0 27px;
        font-family: "SpaceGrotesk-Medium";
        span {
            color: #666;
        }
    }
}

@media only screen and (max-width: 768px) {
    .oneTestimonial {
        margin-top: 20px;
    }
}
