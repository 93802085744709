#instagramFeedWrapper {
    background: #fff;
    padding: 50px 0;

    @media (max-width: 550px) {
        padding: 25px 0;
    }

    .instagramFeedTitle {
        padding: 0 15px;
        text-align: center;

        h2 {
            margin: 0 0 50px 0;
        }

        @media (max-width: 550px) {
            h2 {
                font-size: 26px;
                margin-bottom: 25px;
            }
        }
    }

    .buttonWrapper {
        margin-top: 75px;

        @media (max-width: 550px) {
            margin-top: 25px;
        }
    }

    .buttonInstagram {
        button {
            background: #f09433;
            background: -moz-linear-gradient(90deg, #f09433 0%, #e6683c 29%, #dc2743 49%, #cc2366 75%, #bc1888 100%);
            background: -webkit-linear-gradient(90deg, #f09433 0%, #e6683c 29%, #dc2743 49%, #cc2366 75%, #bc1888 100%);
            background: linear-gradient(90deg, #f09433 0%, #e6683c 29%, #dc2743 49%, #cc2366 75%, #bc1888 100%);
            color: #fff;
            border-radius: 7px;
            display: flex;
            margin: auto;
            align-items: center;
        }

        button:before {
            content: "";
            background: url("./img/instagram-logo.png") center no-repeat;
            background-size: 16px 16px;
            display: inline-block;
            height: 16px;
            width: 16px;
            margin-right: 10px;
        }
    }

    .slick-arrow {
        z-index: 1;
    }

    .slick-arrow:before {
        color: #000;
        font-size: 40px;
    }

    .slick-prev {
        left: 10px;
        width: 45px;
    }

    .slick-next {
        right: 10px;
        width: 45px;
    }
}

@media only screen and (max-width: 480px) {
    #instagramFeedWrapper {
        .slick-list,
        .slick-slider,
        .slick-track {
            height: auto;
        }

        .slick-slider {
            width: 100%;
        }
    }
}
