.OfferTripsSection {
  padding-left: 50px;
  padding-right: 50px;
  min-height: 800px;
  background-color: #f2f2f2;

  h2 {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.OfferTripsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.OfferTrip {
  margin: 0;
  background-color: #ffffff;
  width: 300px;
}

.OfferTripCover {
  width: 300px;
  height: 250px;
  // background-image: url("./images/tripcoversmall.jpg");
  background-repeat: no-repeat;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(142, 158, 173, 0.7) 0%, #000101a6 0%, rgba(57, 87, 100, 0.04) 96%);
    border-radius: 10px 10px 0 0;
  }
}

.OfferFlag {
  // border: 1px solid #4a4a4a;
  height: 12px;
}

.OfferTripCover img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  object-fit: cover;
}

.OfferTripCover:before {
  position: absolute;
  top: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #000 100%);
}

.OfferTripName {
  width: 90%;
  z-index: 99;
  position: absolute;
  top: 20px;
  margin: auto;
  text-align: center;
  display: flex;
  left: 50%;
  transform: translate(-50%);

  p {
    font-family: "SpaceGrotesk-Medium";
    color: #ffffff;
    text-align: center;
    margin: auto;
  }
}

.OfferTripContainer {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 80%;
  margin: auto;
  .textContainer {
    height: 50px;
  }

  .buttonContainer {
     margin-top: 30px;
     text-align: center;
  }
}

.OfferTripTitle {
  width: 90%;
  margin: auto;
  height: 70px;

  h3 {
    color: #000000;
    text-align: center;
    font-family: "SpaceGrotesk-Medium";
    font-size: 20px;
    font-size: 20px;
  }
}

.OfferTripsTag {
  margin-block-end: 0;
  margin-block-start: 0;
  font-family: "SpaceGrotesk-Regular";
  font-size: 14px;
}

@media only screen and (max-width: 484px) {
  .OfferTripsSection {
    padding-left: 10px;
    padding-right: 10px;

    .OfferTrip {
      margin-bottom: 30px;
    }
  }
}
