.popUpImageYachts {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000094;
    z-index: 999;

    .popupInner {
        position: absolute;
        left: 25%;
        right: 25%;
        top: 25%;
        margin: auto;
        border-radius: 20px;
        background: white;
        min-height: 250px;
        width: 600px;
        text-align: center;
        cursor: pointer;
        height: 400px;

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
            border-radius: 10px;
            cursor: pointer;

            &.planImage {
                transform: rotate(90deg);
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        h1 {
            font-size: 32px;
        }

        p {
            white-space: pre-wrap;
            padding: 20px;
            padding-top: 0;
        }

        .linkPassword {
            margin-top: 20px;
            font-weight: 600;
            cursor: pointer;

            i {
                .arrow {
                    border: solid black;
                    border-width: 0 2px 2px 0;
                    padding: 3px;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg);
                }
            }

            span {
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        .primaryBtn {
            font-weight: 600;
        }

        button.closeButton {
            font-size: 20px;
            border: none !important;
            background-color: transparent;
            position: absolute;
            right: 15px;
            top: 5px;
            font-weight: 800;
            padding: 5px 3px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .popUpImageYachts .popupInner {
        top: 50%;
        left: 50%;
        min-width: 250px;
        transform: translate(-50%, -50%);
        margin: auto;
        right: 50%;
        bottom: 50%;
        height: auto;
        max-width: 90%;
    }
}
