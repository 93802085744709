#participantDetails {
    margin-top: 50px;
    width: 100%;
    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 25px;
        padding-left: 30px;
        margin-top: 25px;
    }
    .name {
        padding: 25px 30px;
        width: 100%;
        background-color: #80808021;
        border-radius: 5px;
        box-sizing: border-box;

        p {
            font-size: 23px;
            margin: 0;
        }
    }

    .form {
        width: 350px;
        padding-left: 32px;
        i {
            font-size: 18px;
            cursor: pointer;
        }

        form {
            .passwordBox {
                position: relative;
                span {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            label,
            p {
                text-align: left;
                margin-bottom: 10px;
                margin-top: 10px;
                font-size: 14px;
            }

            p {
                margin-top: 15px;
            }

            input {
                width: 100%;
                margin: 0;
                height: 35px;
                border-radius: 5px;
                border: 1px solid grey;
                padding-right: 0;
                font-size: 17px;
                box-sizing: border-box;
                padding: 23px 15px;
            }

            input[name="lastName"] {
                margin-bottom: 10px;
            }

            input[type="text"]:focus,
            input[type="number"]:focus,
            input[type="email"]:focus {
                outline: #326bc8 auto 1px;
                box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
            }

            input::-webkit-input-placeholder {
                font-size: 16px;
                color: #808080a6;
                font-family: SpaceGrotesk;
            }

            .linkPassword {
                text-align: right;
                margin-top: 15px;
                span {
                    text-decoration: none;
                    font-size: 15px;
                    font-family: SpaceGrotesk-SemiBold;
                    cursor: pointer;

                    i.arrow {
                        border: solid black;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 2.6px;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        margin-left: 4px;
                    }
                }
            }

            .agree {
                text-align: left;
                margin-top: 25px;
                margin-bottom: 20px;
                color: #7a7a7a;
                p {
                    margin-top: 0;
                    margin-left: 5px;
                    letter-spacing: 0.4px;
                    line-height: 1.3;

                    a {
                        color: #7a7a7a;
                        text-decoration: underline;
                    }
                }
                div {
                    display: flex;
                    margin-bottom: 15px;
                }
            }

            .inputGender {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:hover input ~ .checkmark {
                        background-color: white;
                    }

                    &:checked ~ .checkmark {
                        background-color: white;

                        &:after {
                            display: block;
                        }
                    }
                }
                span.checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: white;
                    border: 1px solid black;
                    border-radius: 50%;
                    cursor: pointer;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        cursor: pointer;
                    }

                    &:after {
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: black;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .primaryBtn {
                margin-top: 40px;
            }
        }
        .btnBox2 {
            .secondaryBtn {
                margin-top: 20px;
            }
        }
    }

    div.komunikat {
        width: 100%;
        height: 6px;
        color: red;
        font-size: 13px;
        margin-top: 10px;
    }

    #dateContainer {
        display: flex;
    }

    form {
        .containerGender {
            display: flex;
            flex-direction: column;
            .inputGender {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 5px 0;
                label {
                    margin: 0 !important;
                    padding-left: 10px;
                }

                input {
                    border-radius: 50%;
                    appearance: auto;
                }
            }
        }
    }

    fieldset {
        border: none;
        background: #efefef;
        margin-bottom: 0.5rem;
    }

    legend {
        text-align: right;
    }

    input,
    select {
        font-family: "Roboto", sans-serif;
        border: solid 1px #89a6bb;
        padding: 0.5rem;
        border-radius: 3px;
        -webkit-appearance: none;
    }

    h2 {
        margin: 3rem 0 1rem;
    }

    .single-date-field {
        width: 120px;
    }

    .field-inline-block {
        display: flex;
        margin-right: 12px;
        flex-direction: column;

        .date-field {
            width: 70px;
            padding: 4px 10px;
            margin: 0;
            height: 35px;
            border-radius: 5px;
            border: 1px solid grey;
            padding-right: 0;
            font-size: 17px;
        }
    }

    .deleteAccount .btnBox {
        .primaryBtn {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}
@media only screen and (max-width: 480px) {
    #yourDetalis .container {
        .box.one {
            .greenBox {
                width: auto;
            }
        }

        .box.two {
            .form {
                width: 300px;
                form {
                    input[type="email"],
                    input[type="password"],
                    input[type="text"] {
                        width: 100%;
                    }
                }
            }
        }
    }

    #participantDetails {
        margin: auto;
        .form {
            margin: auto;
            text-align: center;
            form {
                margin: auto;
            }
        }

        .deleteAccount {
            width: 350px;
        }
    }
}
