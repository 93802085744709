#familyTrips {
    padding-top: 90px;
    min-height: 1000px;

    .infoBF {
        font-family: "SpaceGrotesk-Bold";
        margin-top: 0px;
        width: 400px;
        max-width: 90vw;
        margin: auto;
        margin-bottom: 20px;

    }

    h2, h1 {
        text-align: center;
        margin-top: 50px;
    }

    .accordion {
        border-radius: 0;
        border: none;
        margin: auto;
        width: 60%;
        padding: 20px 0 50px 0;

        &__heading {}

        &__panel {
            // animation: none;
            background-color: white;
            font-size: 14px;
            padding-left: 0;
            color: #4f4f4fc9;
            padding-bottom: 10px;
            line-height: 1.5;
            padding-top: 0;
        }

        &__button {
            width: auto;
            background-color: white;
            font-size: 14px;
            outline: none;
            color: black;
            padding: 18px 0;
            font-family: "SpaceGrotesk-Medium";

            &:hover {
                background-color: white;
            }

            &:after {
                content: "";
                height: 10px;
                width: 10px;
                margin-right: 12px;
                border-bottom: 2px solid currentColor;
                border-right: 2px solid currentColor;
                float: right;
                transform: rotate(-315deg);
            }

            &:before {
                display: none;
            }
        }
    }

    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        transform: rotate(225deg);
    }

    .accordion__item+.accordion__item {
        border-top: none;
    }

    .accordion__item {

        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 15px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }

    .rwt__tab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 20px !important;
        cursor: pointer;
        font-weight: 500;
        font-family: "SpaceGrotesk-Medium";
    }

    .rwt__tabs[data-rwt-vertical="true"] {
        display: flex;
        height: auto;
        margin: auto;
        justify-content: space-between;
        width: 70%;
    }

    .rwt__tablist[aria-orientation="vertical"] {
        width: 300px;
        margin-top: 40px;
        height: fit-content;
        border-right: none !important;
        background: white;
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-right: 30px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }

    #vertical-tab {

        &-one,
        &-two,
        &-three,
        &-four,
        &-five,
        &-six,
        &-seven {
            background-color: transparent;
            min-height: 700px;
            padding: 0;
            margin-top: 250px;
        }
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: none;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    i {
        margin-left: 10px;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
        right: 0 !important;
        border-right: none !important;
        background: #8080801f;
        width: 100%;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]>.arrow {
        display: inline-block;
        margin-left: auto;
        margin-top: 6px;
    }

    #program .container {
        width: 1200px;

        .dayPlan .oneBox {
            height: 570px;
            width: 19%;

            .photoContainer {
                height: 220px;
            }
        }
    }

    .howItWorksItem img {
        border-radius: 12px;
        width: 250px;
        height: 250px;
        object-fit: cover;
    }

    .generalInfo {
        width: 600px;
        max-width: 90vw;
        text-align: center;
        margin: auto;
    }

    p {
        span {
            font-family: "SpaceGrotesk-SemiBold";
        }
    }

    .firstSection:nth-child(1),
    .firstSection:nth-child(3),
    .firstSection:nth-child(5) {
        .imageSection {
            order: 2
        }

        .textSection {
            order: 1
        }
    }


    h3 {
        padding: 20px;
        text-align: center;
        font-family: "SpaceGrotesk-Medium";
        max-width: 800px;
        margin: auto;
        padding-top: 0;

    }

    .slider {
        width: 100%;
        height: 350px;
        position: relative;

        h2, h1 {
            color: white;
            filter: drop-shadow(2px 4px 6px black);
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            font-size: 35px;
            text-align: center;

            span {
                font-size: 20px;
                color: white;
                display: block;
                margin-top: 10px;

            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.8);
        }
    }

    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 50px;


        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 50px;

                .textSection {
                    width: 40%;

                    h2, h1 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }

                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                    }
                }

                .imageSection {
                    width: 40%;
                    height: 320px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;

                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }

                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }

                .textSection {
                    width: 40%;
                }
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 110px;

        .oneTag {
            justify-content: flex-start;
            padding: 0;

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-radius: 10px 10px 0 0;
            }

            p {
                margin-top: 0;
                color: #011625fc;
                font-family: "SpaceGrotesk";
                padding: 20px;
                text-align: left
            }
        }
    }


}

@media only screen and (max-width: 767px) {
   
    #familyTrips {
        padding-top: 70px;
      .accordion {
          width: 100%;
      }
        
        #program {
            padding: 0;
            padding-top: 20px;
            .container .dayPlan .oneBox {
                height: auto;
                width: 100%;
            }
        }
        .howItWorksItem {
            width: 100%;
        }


        .slider {
            h2, h1 {
                text-align: center;
            }

            img {
                filter: brightness(0.6);
            }
        }

        .info {
            padding-bottom: 0px;

            .container {
                box-sizing: border-box;
                padding-top: 40px;

                .firstSection,
                .secondSection {
                    padding-top: 0;

                    .textSection {
                        width: 100%;

                        h2, h1 {
                            margin-top: 30px;

                        }

                        P {}
                    }

                    .imageSection {
                        width: 100%;
                        order: 0;
                        margin-bottom: 20px;
                        margin-top: 20px;

                        img {}
                    }
                }

                .secondSection {
                    .imageSection {
                        img {
                            &.firstPhoto {}

                            &.secondPhoto {}
                        }
                    }

                    .textSection {}
                }
            }
        }

        .tags {
            width: auto;
            justify-content: center;
            padding-bottom: 0;

            .oneTag {
                margin-bottom: 30px;

                img {}

                p {}
            }
        }
    }
}