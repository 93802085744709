.mainOfferCoverWrapper {
  height: 600px;
  display: block;
  position: relative;
  z-index: 0;
  background-repeat: no-repeat;
  margin-top: 50px;
  .containerButton {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    padding: 50px;
    padding-left: 25px;
    width: auto;
  }

}

.facebookIcon {
  width: 30px;
  height: 30px;
}

.mainOfferCoverWrapper:before {
  content: "";
  display: block;
  width: 100%;
  height: 250px;
}

.mainOfferCoverWrapper:after {
  content: "";
  display: none;
  width: 100%;
  height: 250px;
}

.mainOfferCoverWrapper:before {
  position: absolute;
  top: 0;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #000000b5 100%);
}

.mainOfferCoverWrapper:after {
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000000b5 100%);
}

#mainOfferCoverImage {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media only screen and (max-width: 767px) {
  .mainOfferCoverWrapper {
    min-height: 500px;
    height: 670px;

  }

  #facebookEvent {
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0!important;
    width: 100%;
    button {
      margin: auto;
    }
  }

  .mainOfferCoverWrapper:before {}

  .mainOfferCoverWrapper:after {}

  .mainOfferCoverWrapper:before {}

  .mainOfferCoverWrapper:after {}

  #mainOfferCoverImage {
    filter: brightness(0.4);
  }
}