.scrollTop {
  position: fixed;
  width: 100px;
  bottom: 75px;
  right: -15px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
}

.scrollTop:hover{
  opacity: 0.5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}