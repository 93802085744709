#faqWrapper {
    padding-bottom: 70px;
    h3 {
        padding-top: 120px;
        text-align: center;
        margin-bottom: 20px;
    }
    .panelBox {
        width: 100%;
        min-height: 500px;
        margin-top: 40px;
        margin-left: 0;

        .faqContainer {
            background-color: white;
            width: auto;
            height: auto;
            padding: 25px;
            padding: 40px;
            border-radius: 5px;
            padding-top: 15px;
            box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
        }
    }

    .accordion {
        border-radius: 0;
        border: none;

        &__heading {
            border-bottom: 1px solid #80808038;
        }

        &__panel {
            // animation: none;
            background-color: white;
            font-size: 14px;
            padding-left: 0;
            color: #4f4f4fc9;
            padding-bottom: 10px;
            line-height: 1.5;
            padding-top: 20px;
        }

        &__button {
            width: auto;
            background-color: white;
            font-size: 14px;
            outline: none;
            color: black;
            padding: 18px 0;
            font-family: "SpaceGrotesk-Medium";
            &:hover {
                background-color: white;
            }

            &:after {
                content: "";
                height: 5px;
                width: 5px;
                margin-right: 12px;
                border-bottom: 2px solid currentColor;
                border-right: 2px solid currentColor;
                float: right;
                transform: rotate(-315deg);
            }

            &:before {
                display: none;
            }
        }
    }

    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        transform: rotate(225deg);
    }

    .accordion__item + .accordion__item {
        border-top: none;
    }

    .rwt__tab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 20px !important;
        cursor: pointer;
        font-weight: 500;
        font-family: "SpaceGrotesk-Medium";
    }

    .rwt__tabs[data-rwt-vertical="true"] {
        display: flex;
        height: auto;
        margin: auto;
        justify-content: space-between;
        width: 70%;
    }

    .rwt__tablist[aria-orientation="vertical"] {
        width: 300px;
        margin-top: 40px;
        height: fit-content;
        border-right: none !important;
        background: white;
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-right: 30px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }
    #vertical-tab {
        &-one,
        &-two,
        &-three,
        &-four,
        &-five,
        &-six,
        &-seven {
            background-color: transparent;
            min-height: 700px;
            padding: 0;
        }
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: none;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    i {
        margin-left: 10px;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
        right: 0 !important;
        border-right: none !important;
        background: #8080801f;
        width: 100%;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"] > .arrow {
        display: inline-block;
        margin-left: auto;
        margin-top: 6px;
    }
}

@media only screen and (max-width: 768px) {
    #faqWrapper {
        h3 {
            margin: auto;
            max-width: 90vw;
            margin-bottom: 25px;
        }

        .rwt__tabs[data-rwt-vertical="true"] {
            width: 100%;
            max-width: 90vw;
            flex-direction: column;

            .panelBox {
                margin-left: 0;
                width: auto;

                .faqContainer {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 20px;
                    .accordion {
                        &__button {
                            width: auto;

                            &:after {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .rwt__tabs[data-rwt-vertical="true"] {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    margin-left: 0;
                }

                .rwt__tablist[aria-orientation="vertical"] {
                    margin-right: 1rem;
                    width: 250px;
                    justify-content: center;
                    margin: 20px auto !important;
                }

                .right {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    border: transparent;
                }

                #vertical-tab-one,
                #vertical-tab-two,
                #vertical-tab-three,
                #vertical-tab-four,
                #vertical-tab-five,
                #vertical-tab-six,
                #vertical-tab-seven {
                    padding: 20px;
                    width: auto;
                }
            }
            .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"] > .arrow {
                display: none;
            }

            .accordion__button:after {
                display: inline-block;
                float: none;
                margin-left: 10px;
                margin-bottom: 2px;
            }
            .rwt__tablist[aria-orientation="vertical"] {
                width: auto;
                margin: 0;
                margin-top: 30px;
            }
        }
    }
}
