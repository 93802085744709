#registration {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 550px;
        box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.19);
        height: auto;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        background-color: white;
        margin-top: 150px;
        margin-bottom: 120px;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        max-width: 90vw;

        .box {
            width: 100%;
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-bottom: 40px;
            max-width: 90vw;
        }

        .box.two {
            border: none;
            padding: 20px;
            padding-top: 40px;

            .primaryBtn {
                font-weight: 500;
                background-color: white;

                color: #0a1e2c;
                border: 1px solid #0a1e2c;

                &:hover {
                    border: 1px solid white;
                    color: white;
                    background-color: #0a1e2c;
                }
            }

            .redBox {
                height: auto;
                margin-top: 30px;
                width: 400px;
                padding: 20px;
                border-radius: 10px;
                background-color: #fae5e5;

                p {
                    line-height: 1.5;
                    font-size: 15px;
                    margin-bottom: 0;

                    span {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        p.infoText {
            margin-top: 0;
        }

        h3 {
            text-align: center;
            margin-top: 10px;
        }

        .form {
            width: 380px;
            text-align: center;

            i {
                font-size: 18px;
                cursor: pointer;
            }

            form {
                .passwordBox {
                    position: relative;

                    span {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                p {
                    text-align: left;
                    margin-bottom: 5px;
                    margin-top: 10px;
                    font-size: 15px;
                }

                input[type="email"],
                input[type="password"],
                input[type="text"] {
                    width: 100%;
                    padding: 23px 15px;
                    margin: 0;
                    height: 35px;
                    border-radius: 5px;
                    border: 1px solid grey;
                    padding-right: 0;
                    font-size: 17px;
                    box-sizing: border-box;
                }

                input[type="email"]::-webkit-input-placeholder,
                input[type="password"]::-webkit-input-placeholder,
                input[type="text"]::-webkit-input-placeholder {
                    font-size: 16px;
                    color: #808080a6;
                }

                input[type="email"]:focus,
                input[type="password"]:focus,
                input[type="text"]:focus {
                    outline: grey auto 1px;
                }

                .agree {
                    text-align: left;
                    margin-top: 35px;
                    margin-bottom: 20px;
                    color: #7a7a7a;

                    input {
                        display: inline-table;
                    }

                    p {
                        margin-top: 0;
                        margin-left: 5px;
                        letter-spacing: 0.4px;
                        line-height: 1.3;
                        font-size: 14px;
                        font-family: SpaceGrotesk;

                        a {
                            color: #7a7a7a;
                            text-decoration: underline;
                        }
                    }

                    .containerInputCheckbox {

                        span.checkmark {

                            &:before {
                                display: none;
                            }
                        }
                    }
                }

            }
        }
    }

    .komunikat {
        width: 100%;
        height: auto;
        color: red;
        font-size: 12px;
        text-align: left;
        margin: 10px 0;
    }

    input[name="token"] {
        margin-top: 20px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 480px) {
    #registration .container {
        .form {
            width: 270px;
            margin: auto;

            form {

                input[type="email"],
                input[type="password"],
                input[type="text"] {
                    width: 100%;
                }
            }
        }

        .box.two .redBox {
            width: 270px;
        }
    }
}