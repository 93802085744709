#offerTransportsSection {
  padding: 50px 0;
  background: #f2f2f2;

  .transportContainer {
    display: block;
    max-width: 1400px;
    margin: 50px auto 0 auto;
  }

  .transportTitle {
    text-align: center;

    h2 {
      margin-top: 0;
    }
  }

  .listTabsMenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: left;
    line-height: 30px;
    width: 50%;
    background: #f2f2f2;

    li:hover {
      border-radius: 5px 5px 0 0;
      background: #00000033;
    }

    .activeTab:hover {
      border-radius: 5px 5px 0 0;
      background: #ffffff;
    }

    li {
      font-weight: bold;
      font-size: 1em;
      text-align: center;
      flex: 2 0 auto;
      height: auto;
      box-sizing: border-box;
      padding: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        cursor: pointer;
        margin: 0;

        span {
          color: green;
          font-family: "SpaceGrotesk-SemiBold";
          display: block;
        }
      }
    }

    .activeTab {
      margin-bottom: -1px;
      border-bottom: 1px solid #ffffff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: -1px -10px 20px -9px rgba(0, 0, 0, 0.15);
      background: #ffffff;
      max-width: 250px;
      box-sizing: border-box;
      padding: 10px 30px;
    }

    .tab {
      max-width: 250px;
      box-sizing: border-box;
      padding: 10px 30px;
    }
  }
  .boxFilter {
    display: none;
  }
}

.transportsTab {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding: 30px;
  box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .OfferTransportMapWrapper {
    width: 30%;
    position: relative;
  }

  .transportDescriptionWrapper {
    width: 70%;
    box-sizing: border-box;
    padding-right: 25px;

    &.transportFullWidth {
      width: 100%;
    }

    .transportTitleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }

      span {
        background: #12b342;
        color: #fff;
        padding: 10px 15px;
        border-radius: 30px;
      }
    }
  }

  .transportsTabWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .transportsImagesWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 30%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        padding: 5px;
        box-sizing: border-box;
        height: 200px;

        img {
          width: 100%;
          border-radius: 15px;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  #offerTransportsSection {
    .transportContainer {
      max-width: 95%;

      .transportsTab {
        .transportsImagesWrapper {
          li {
            height: 150px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #offerTransportsSection {
    .transportContainer {
      .transportsTab {
        .OfferTransportMapWrapper {
          width: 100%;
          height: 250px;
        }

        .transportDescriptionWrapper {
          width: 100%;
        }

        .transportsImagesWrapper {
          width: 100%;

          ul {
            li {
              width: 33%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #offerTransportsSection {
    padding: 20px;
    .transportContainer {
      display: none;
    }

    h3 {
      text-align: center;
      margin-bottom: 40px;
    }

    .listTabsMenu {
      width: 100%;

      li {
        width: 50%;
        padding: 10px;

        p {
          margin: 0;
        }
      }
    }

    .accordion__button:before {
      order: 2;
    }

    .accordion__button {
      justify-content: space-between;
      width: auto;
      border-radius: 5px;
      background-color: white;
      outline: none;
      display: flex;
      align-items: center;
    }

    .accordion__panel {
      background: white;
      padding-top: 1px;

      .OfferTransportMapWrapper {
        width: 100%;
        height: 250px;
        position: relative;
      }
    }

    .accordion {
      margin-top: 20px;
    }

    .header {
      h5 {
        margin: 0;
        font-size: 18px;
        span {
          color: green;
          font-family: "SpaceGrotesk-SemiBold";
        }
      }
    }
    .boxFilter {
      display: block;
    }
  }

  .transportsTab {
    padding: 20px;

  }
  .transportsImagesWrapper {
    display: flex;
    align-items: center;
    width: auto;
    overflow-x: auto;

    img {
      width: 200px;
      margin: 10px;
      border-radius: 5px;
      box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.15);
      height: 100px;
      object-fit: cover;
    }
  }
}
