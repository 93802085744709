.adventureItem {
    margin-top: 40px;
    text-align: center;
    width: 33%;

    p {
        margin: 15px auto 0;
        padding: 0 50px;
        max-width: 400px;
    }

    img {
        border-radius: 12px;
        max-width: 264px;
    }
}

@media only screen and (max-width: 1000px) {
    .adventureItem {
        width: 100%;
    }
}
