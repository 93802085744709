#yourDetails {
    margin-top: 50px;
    width: 100%;
    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    /* START FORM */
    .form {
        width: 320px;
        form {
            label,
            p {
                text-align: left;
                margin-bottom: 10px;
                margin-top: 15px;
                font-size: 14px;
            }

            input {
                border-radius: 5px;
                border: 1px solid grey;
                padding: 23px 15px;
            }

            input:focus {
                outline: #326bc8 auto 1px;
                box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
            }

            input::-webkit-input-placeholder {
                font-size: 16px;
                color: #808080a6;
                font-family: "SpaceGrotesk";
            }

            .containerInputRadio {
                height: auto;
                .inputRadio {
                    margin: 0;
                }
            }

            .secondaryBtn {
                margin-top: 20px;
            }
        }
    }

    /* END FORM */

    /* START DELETE SECTION */

    .deleteAccount .btnBox {
        .primaryBtn {
            margin-lefT: 0;
        }
        .secondaryBtn {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    /* END DELETE SECTION */
}

@media only screen and (max-width: 480px) {
    #yourDetails {
        margin: auto;
        width: auto;
        h2 {
            margin-bottom: 50px;
            margin-top: 40px;
        }

        .form {
            margin: auto;
            text-align: center;
            width: auto;
            form {
                margin: auto;
            }
        }

        .deleteAccount {
            width: auto;
        }
    }
}
