* {
  color: #011625fc;
  font-family: "SpaceGrotesk";
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 1.3;
  cursor: default;
  box-sizing: revert;
}

::before,
::after {
  box-sizing: revert;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* FONTS */

@font-face {
  font-family: "SpaceGrotesk";
  src: url(./fonts/SpaceGrotesk-Regular.otf);
}

@font-face {
  font-family: "SpaceGrotesk-Medium";
  src: url(./fonts/SpaceGrotesk-Medium.otf);
}

@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: url(./fonts/SpaceGrotesk-Bold.otf);
}

@font-face {
  font-family: "SpaceGrotesk-SemiBold";
  src: url(./fonts/SpaceGrotesk-SemiBold.otf);
}

@font-face {
  font-family: "SpaceGrotesk-Light";
  src: url(./fonts/SpaceGrotesk-Light.otf);
}

/* TEXT TAG */

a {
  cursor: pointer;
  color: inherit;

}

a:hover {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "SpaceGrotesk-Medium";
  margin-top: revert;
  margin-bottom: revert;
  color: #0a1e2c;
  line-height: 1.5;
  cursor: inherit;
  letter-spacing: 0.2px;
}

h1 {
  font-size: 35px;
  margin-top: revert;
  margin-bottom: revert;
  font-family: "SpaceGrotesk";
}

h2 {
  font-size: 28px;
  margin-top: revert;
  margin-bottom: revert;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 20px;
}

dl,
ol,
ul {
  margin-top: revert;
  margin-bottom: revert;
}

p {
  font-family: "SpaceGrotesk";
  letter-spacing: 0.2px;
  line-height: 1.5;
  margin-top: revert;
  margin-bottom: revert;
}

/* FORMS */

.form {
  width: 350px;
}

input,
textarea {
  width: 100%;
  margin: 0;
  height: 35px;
  border-radius: 5px;
  border: 1px solid grey;
  padding-right: 0;
  font-size: 17px;
  box-sizing: border-box;
  padding: 23px 15px;
  cursor: auto;
}

textarea {
  height: 150px;
  font-family: 'SpaceGrotesk';
  padding-top: 10px;
  padding-left: 10px;
}

textarea::placeholder {
  color: #0c0c0c6e;
  font-size: 13px;
}

input:focus,
textarea:focus {
  outline: #123852fc auto 1px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 16px;
  color: #808080a6;
  font-family: "SpaceGrotesk";
}

label {
  margin-bottom: revert;
}

.table thead th {
  border-bottom: inherit;
}

.row {
  flex-wrap: inherit;
  margin-right: 0;
  margin-left: 0;
}

/* MESSAGES */

p.confirmedInformation {
  color: #42ad12 !important;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

p.warningText {
  color: #de1212 !important;
  padding: 10px 0;
  font-size: 13px !important;
  width: 250px;
  line-height: 1.3
}

p.warningText span {
  font-family: "SpaceGrotesk-SemiBold";
  color: #de1212;
}

.errorMessage {
  color: #de1212 !important;
  padding: 10px 0;
  font-size: 12px;
  text-align: left;
  margin: 0;
  padding-bottom: 0;
  padding-top: 5px;
  margin-left: 2px;
  margin-bottom: 10px !important;
}

.trueMessage {
  color: green;
  font-size: 14px;
  padding: 0;
  padding-top: 5px;
}

.errorMessageInput {
  border: 2px solid #de1212 !important;
  border-radius: 5px,
}

.errorMessageInput:focus {
  outline: none !important;
}

.trueMessageInput {
  border: 2px solid green !important;
  border-radius: 5px,
}

.trueMessageInput:focus {
  outline: none !important;
}

.price.include {
  color: #42ad12;
  font-size: 20px !important;
}

/* INFO BOXES */

.redBox {
  height: auto;
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fae5e5;
  width: 350px;
  box-sizing: border-box;
}

.redBox p {
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 0;
}

.redBox p.infoTitle {
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 10px;
  color: #d50101;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-top: 0;
}

.greenBox {
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #e5f6ea;
  width: 350px;
  box-sizing: border-box;
  margin-bottom: 50px;
  width: 100%;
  margin-top: 50px;
}

.greenBox p {
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 0;
  max-width: 500px;
}

.greenBox p.infoTitle {
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 0;
  color: #1eb74c;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-top: 0;
  max-width: 500px;
}

/* BUTTONS */

button:focus {
  outline: none;
}

.primaryBtn {
  min-width: 180px;
  background-color: #ffd15f;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: "SpaceGrotesk-Medium";
  padding: 14px 20px;
}

.primaryBtn a {
  font-family: 'SpaceGrotesk-Medium';
}

.primaryBtn:hover {
  box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.42);
}

.secondaryBtn {
  min-width: 180px;
  border-radius: 30px;
  font-size: 16px;
  font-family: "SpaceGrotesk-Medium";
  padding: 13px 20px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: 1px solid #123852fc;
  color: #123852fc;
}

.secondaryBtn:hover {
  background-color: #123852fc;
  color: white;
  box-shadow: none;
  /* font-family: "SpaceGrotesk" */
}

/* style single box input radio with border after click */

.boxInputRadio {
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: space-between;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  padding: 15px;
  min-width: 240px;
  width: 240px;
  height: auto;
  border: 1.5px solid #80808075;
  border-radius: 6px;
  margin-right: 30px;
  box-sizing: border-box;
}

.boxInputRadio .boxAfterClick {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  margin: -1px;
  cursor: pointer;
}

.boxInputRadio p.titleText {
  margin-left: 30px;
  margin-top: 12px;
  font-size: 16px;
  cursor: pointer;
}

.boxInputRadio p.description {
  font-size: 15px;
  color: grey;
  cursor: pointer;
  margin-top: 0;
}

.boxInputRadio p.price {
  font-size: 22px;
  text-align: center;
  line-height: 1;
  margin: auto;
  padding: 40px 0;
}

.boxInputRadio p.price span {
  font-size: 14px;
}

.boxInputRadio a {
  font-size: 15px;
  color: grey;
  z-index: 2;
  text-decoration: underline;
}

.boxInputRadio a:hover {
  color: #060606;
  text-decoration: none;
}

.boxInputRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.boxInputRadio input:hover input~.checkmarkRadio {
  background-color: white;
}

.boxInputRadio input:checked~.checkmarkRadio {
  background-color: white;
  border: 2px solid black;
}

.boxInputRadio input:checked~.checkmarkRadio:after {
  display: block;
}

.boxInputRadio input:checked~.boxAfterClick  {
  border: 2px solid black;
  margin-left: -2px;
}

/* style container with few input radio */

.containerInputRadio {
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  min-width: 240px;
  height: auto;
  border-radius: 6px;
  margin-right: 30px;
  box-sizing: border-box;
}

.containerInputRadio .inputRadio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 5px 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  line-height: 2;
}

.containerInputRadio .inputRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containerInputRadio .inputRadio input:hover input~.checkmark {
  background-color: white;
}

.containerInputRadio .inputRadio input:checked~.checkmark {
  background-color: white;
}

.containerInputRadio .inputRadio input:checked~.checkmark:after {
  display: block;
}

.containerInputRadio .inputRadio input:checked~.checkmark:before {
  display: block;
}

.containerInputRadio .inputRadio span.checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  top: 3px;
}

.containerInputRadio .inputRadio span.checkmark:before {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
}

.containerInputRadio .inputRadio span.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
}

.containerInputRadio .inputRadio span.checkmark:before {
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  transform: translate(-50%, -50%);
}

.containerInputRadio .inputRadio span.checkmark:after {
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black
}

/* style container with few input checkbox */

.containerInputCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerInputCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containerInputCheckbox input:hover input~.checkmark {
  background-color: white;
}

.containerInputCheckbox input:checked~.checkmark {
  background-color: white;
}

.containerInputCheckbox input:checked~.checkmark:after {
  display: block;
}

.containerInputCheckbox input:checked~.checkmark:before {
  display: block;
}

.containerInputCheckbox span.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid grey;
  cursor: pointer;
}

.containerInputCheckbox span.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerInputCheckbox span.checkmark:before {
  content: "";
  position: absolute;
  display: none;
}

.containerInputCheckbox span.checkmark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.containerInputCheckbox span.checkmark:before {
  width: 100%;
  height: 100%;
  border: 2px solid #121111;
  border-radius: 7px;
  margin-left: -2px;
  margin-top: -2px;
}

/* OTHER */

p.textSmall {
  font-size: 15px;
  color: #858585;
}

p.infoText {
  color: #d50101;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.collapse:not(.show) {
  display: none;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(255 209 95);
}

/* ALL CHECKMARK INPUT RADIO */

span.checkmarkRadio {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 28px;
  left: 13px;
  height: 20px;
  width: 20px;
  border: 2px solid #80808075;
  cursor: pointer;
}

span.checkmarkRadio:after {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
}

span.checkmarkRadio:before {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
}

span.checkmarkRadio:after {
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #123852fc;
  transform: translate(-50%, -50%);
}

/* SELECT */

select {
  min-width: 300px;
  margin: auto;
  margin-top: 20px;
  width: 450px;
  border-radius: 5px;
  border: 1px solid grey;
  padding-right: 0;
  font-size: 17px;
  box-sizing: border-box;
  padding: 20px;
  float: left;
  -webkit-appearance: none;
  background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #dddddd08;
  background-position-x: 97%;
  cursor: pointer;
}

.table tbody+tbody {
  border-top: revert;
}

.table td,
.table th {
  vertical-align: top;
  border-top: revert;
}

.led-red,
.led-box {
  padding: 0 !important;
  margin-left: 7px;
  margin-top: 7px;
  min-width: 6px;
  padding-left: 10px;
}

.led-red,
.led-box {
  margin: 0 10px;
  width: 6px;
  height: 6px;
}

.led-red {
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
}

#disabledButton {
  background: grey;
  cursor: not-allowed;
  pointer-events: none;

}

#disabledButton:hover {
  box-shadow: none !important;
}

.orangeBox {
  background-color: #ffecb2;
  max-width: 90%;
  padding: 20px;
  width: fit-content;
  border-radius: 10px;
  margin-top: 10px;
  max-width: 600px;
}

.orangeBox p {
  color: orange;
  font-family: "SpaceGrotesk-Medium";
  margin: 0;
  font-size: 15px;
}

#infoReservationListInStep {
  position: absolute;
  top: 100%;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  #infoReservationListInStep {
    margin: 10px;
  }
}

/* LOADER */
.lds-roller {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -70px;
  z-index: 100;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ffd15f;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADER END */

.containerButtons {
  position: relative
}

#stepMask {
  position: absolute;
  width: 100%;
  cursor: default;
  height: 100%;
  top: 0;
  left: 0;
  background: #e4f4fba8;
  z-index: 100;
}

#disabled {
  cursor: not-allowed;
  pointer-events: none;
  z-index: 0
}

.pageContainer, .offerSiteContainer {
  overflow-x: hidden;
}