.OfferMapWrapper {
  height: 500px;
  display: flex;
  align-items: flex-start;

  div:first-child {
    height: 650px !important;
  }
}

.portActive {
  border: 5px solid #eefcf5;
}

@media only screen and (max-width: 999px) {
  .menuPage {
    flex-wrap: wrap;
    justify-content: center;

    a {
      .bookButtonWrapper {
        margin-right: 0;
      }
    }

    .menuWrapper {
      width: 100%;
      margin-left: 0;
    }
  }

  .OfferMapWrapper {
    div:first-child {
      position: relative !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
