@import "~video-react/styles/scss/video-react";

#sliderContainer {
    height: 630px;
    background-size: cover;
    background-position: center;
    position: relative;

    .stripeBox {
        width: 100%;
        height: 630px;
        background-color: transparent;
        position: absolute; /*add*/
        top: 0; /*add*/
        z-index: 9;
        background: #0000004d;

        .stripe {
            position: absolute;
            // left: 50px;
            top: 0;
            z-index: 0;
            width: 100%;
            height: 100%;

            .logoBox {
                width: 150px;
                position: absolute;
                margin: 10px 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .textBox {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;

            .text {
                color: white;
                max-width: 415px;
                width: 100%;
                padding: 40px;
                border-radius: 0 12px 12px 0;

                h1 {
                    font-weight: 400;
                    margin-bottom: 0;
                    color: white;
                    font-size: 32px;
                    margin: 0;
                }

                p {
                    color: #ffffffd1;
                    margin-bottom: 28px;
                    font-family: "SpaceGrotesk";
                    font-size: 16px;
                }

                .primaryBtn {
                    width: auto;
                    height: 50px;

                    &:hover {
                        font-weight: 500;
                        margin: 0;
                        background-color: white;
                        border: 1px solid white;
                        color: black;
                    }
                }
            }
        }
    }
}

.video-react {
    height: 650px !important;
    padding: 0 !important;
    pointer-events: none;
}

.video-react-video {
    object-fit: none;
}

.video-react-has-started .video-react-control-bar {
    display: none;
}

#content > header > div > button {
    display: none;
}

.video-react .video-react-video {
    position: relative;
    top: inherit;
    left: inherit;
    width: 100%;
    height: 100%;
}

.awssld {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: initial;
    z-index: 9;
}

.awssld__content div,
.awssld__content div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.awssld__wrapper {
    z-index: 9;
}

.awssld__timer--run {
    transform: translateX(0);
    /* display: none; */
    visibility: hidden;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
    content: " ";
    position: absolute;
    right: calc(20% - (0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after {
    right: auto;
    left: calc(20% - (0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
}

.awssld__bullets {
    visibility: hidden;
}

.awssld__controls button {
    z-index: 0;
}

@media only screen and (min-width: 1200px) {
    .video-react-video {
        object-fit: cover;
    }
}

@media only screen and (max-width: 1200px) {
    #sliderContainer {
        background-image: url("./img/3.png");
    }

    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
        display: none;
    }

    .awssld {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    #sliderContainer {
        padding-left: 0;

        .stripeBox {
            width: 100%;
            .stripe {
                left: 0;
                width: 100%;
                background: #00000094;

                .logoBox {
                    width: 100px;
                    padding: 7px 10px;
                    margin: 0;
                    margin-left: 20px;
                }

                .textBox .text {
                    text-align: center;
                    background: none;
                }
            }

            .textBox {
                justify-content: center;
            }
        }
    }
}
