.howItWorksItem {
    margin-top: 40px;
    text-align: center;
    width: 50%;
    box-sizing: border-box;
    padding: 0 15px;

    p {
        margin-bottom: 0;
        padding: 15px 50px 0;
        max-width: 400px;
        margin: auto;
    }

    img {
        border-radius: 12px;
        width: 320px;
        height: 220px;
        object-fit: cover;
        max-width: 90%;
    }
}

@media only screen and (max-width: 768px) {
    .howItWorksItem {
        width: 100%;
        p {
            padding: 15px 0 0;
        }

        img {
            width: 100%;
        }
    }
}
