.offerTagBoxWrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px;

  .orangeBox {
    margin-bottom: 0;
    margin-top: 0;
    background-color: rgba(255, 193, 7, 0.31);
    font-weight: 100;
    max-width: 100%;
    padding: 10px 15px;
    width: fit-content;
  }
}

.offerTagScaleWrapper {
  padding: 10px 0;

  .offerTagScaleItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    span {
      width: 20%;
      text-align: center;
    }

    .offerTagScaleBar {
      width: 60%;
      height: 0.4rem;
      position: relative;
      display: flex;

      .offerTagScaleBarItem {
        width: 20%;
        height: 100%;
        border: 1px solid #011625fc;
        border-radius: 0.25rem;
        margin: 0 8px;
      }

      .offerTagScaleBarItemActive {
        background: #011625fc;
      }
    }

    .offerTagScaleBar-2 {
      svg {
        left: 21%;
      }

      &:before {
        content: "";
        background: #0284fe;
        width: 25%;
        height: 100%;
        display: block;
        border-radius: 0.25rem 0 0 0.25rem;
      }
    }

    .offerTagScaleBar-3 {
      svg {
        left: 46%;
      }

      &:before {
        content: "";
        background: #0284fe;
        width: 50%;
        height: 100%;
        display: block;
        border-radius: 0.25rem 0 0 0.25rem;
      }
    }

    .popup-box {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      display: flex;

      .box {
        margin: auto;
        max-width: 500px;
        border-radius: 5px;
        position: relative;
        background: #fff;

        .close-icon {
          position: absolute;
          right: 12px;
          top: 4px;
          line-height: 1;
          font-size: 20px;
          font-weight: 700;
          cursor: pointer;
          color: #fff;
          text-align: right;
        }

        .popup-content-wrapper {
          padding: 15px;
        }

        .popup-img {
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px 3px 0 0;
          }
        }

        .popup-title {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }
}

.offerTagBox {
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #12b342;
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;

  svg {
    fill: #12b342;
    margin-right: 10px;
  }

  img {
    height: 15px;
    z-index: 100;
    margin-right: 10px;
    position: relative;

    .half:after {
      content: "";
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0px;
      background-color: #e4f4fb;
    }
  }
}

#appContainer
  > div
  > div
  > div.mainOfferCoverWrapper
  > div.offerTagBoxWrapper
  > div:nth-child(2)
  > div
  > img:nth-child(2) {
  height: auto;
}

@media only screen and (max-width: 767px) {
  .offerTagBoxWrapper {
    width: 100%;
    margin: 30px auto;
  }

  .offerTagScaleWrapper .offerTagScaleItem .popup-box {
    .box {
      width: 90%;
      .popup-img {
        height: 120px;
      }
      .popup-content-wrapper {
        padding: 15px;
        height: 200px;
        overflow: auto;
      }
    }
  }
}
