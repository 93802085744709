#oneTrip {
    width: 100%;
    min-height: 300px;
    border-radius: 5px;
    box-shadow: 2px 26px 68px -21px #b8b6b8;
    display: flex;
    margin-bottom: 50px;
    height: auto;
    .photoContainer {
        width: 30%;
        position: relative;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            cursor: pointer;
        }
        h4 {
            position: absolute;
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
            color: white;
            width: 100%;
            font-weight: 500;
        }
    }

    .photoContainer.reservation {
        width: 30%;
    }
    .tripInfo {
        cursor: pointer;
        h3 {
            margin-top: 3px;
            color: white;
            cursor: pointer;
        }

        .infoTripBox {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          
            .far {
                font-size: 20px;
                margin-right: 12px;
            }

            i {
                margin-left: 2px;
            }

            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }
            }
            .dataBox {
                display: flex;
                .confirmedTrip {
                    color: #42ad12;
                    font-size: 15px;
                    font-weight: 600;
                    margin-left: 10px;
                    span {
                        margin-right: 5px;
                        color: #42ad12;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }

        .optionsBox {
            margin-top: 30px;
            margin-bottom: 30px;
            p {
                margin-top: 20px;
                font-size: 15px;
                font-family: "SpaceGrotesk-SemiBold";
            }
        }

        .additionalOptions {
            color: grey;
            p,
            span {
                font-size: 14px;
                color: grey;
                margin: 0;
            }
        }
    }

    .tripInfo.reservation {
        width: auto;
    }
    button {
        margin-top: 30px;
        margin-bottom: 0;
        height: auto;
        padding: 10px 20px;
        width: auto;
        color: #000000;
        font-size: 16px;
        border: none;
        font-family: "SpaceGrotesk-Medium";
    }
}

@media only screen and (max-width: 767px) {
    #oneTrip {
        display: flex;
        flex-direction: column;

        .photoContainer {
            width: 100%;
            height: 30%;
            img {
                border-radius: 10px 10px 0 0;
            }
        }

        .tripInfo h3 {
            margin-top: -10px;
        }

        .tripInfo .infoTripBox .dataBox {
            flex-direction: column;
            .confirmedTrip {
                margin-left: 5px;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
}
