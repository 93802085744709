#transportInfo {
    background-color: #eefdf6;
    width: 100%;
    padding: 50px 0 70px;
    .container {
        width: 1000px;
        margin: auto;
        max-width: 90vw;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        h2 {
            font-size: 28px;
            margin-bottom: 15px;
            margin-top: 0;
        }
        p {
            width: 650px;
            text-align: center;
            max-width: 90vw;
        }

        .boxes {
            width: 1100px;
            max-width: 90vw;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            align-items: center;
            margin-top: 50px;

            .oneBox {
                width: 350px;
                box-shadow: 2px 26px 68px -21px #b8b6b8;
                height: 350px;
                border-radius: 5px;
                background-color: white;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: column;
                min-height: 300px;
                padding: 10px 30px;
                box-sizing: border-box;
                p {
                    width: auto;
                    text-align: center;
                    margin: 0;
                    color: rgba(43, 51, 63, 0.86);
                    font-size: 15px;
                }

                h4 {
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .transportBox {
        width: 100%;
        margin-top: 10px;
        .rwt__tablist {
            display: flex;
            width: 600px;
            max-width: 90vw;
        }

        .rwt__tab {
            padding: 20px 25px !important;
            cursor: pointer;

            &[aria-selected="true"] {
                border-radius: 5px 5px 0 0;
                background: white;
                font-family: "SpaceGrotesk-Medium";
                &:after {
                    border-bottom: none;
                }
            }
        }

        #basic-tab-one,
        #basic-tab-two,
        #basic-tab-three,
        #basic-tab-four,
        #basic-tab-five,
        #basic-tab-six,
        #basic-tab-seven {
            background: white;
            height: auto;
            text-align: left;
            min-height: 200px;
            padding-right: 40px;
            box-shadow: 2px 26px 68px -21px #b8b6b8;
            border-radius: 5px;
        }

        .rwt__tablist:not([aria-orientation="vertical"]) {
            border-bottom: none;
           
        }

        #oneTransport {
            display: flex;
            justify-content: flex-start;
            padding: 20px 20px 20px 5px;
            align-items: center;
            h3 {
                font-size: 22px;
                margin-bottom: 0;
            }

            .image {
                width: 250px;
                height: 120px;
                padding: 0 40px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .text {
                p {
                    text-align: left;
                    color: #696b69;
                    width: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #transportInfo {
        .container {
            padding-left: 0;
            padding-right: 0;
            h2 {
            }
            p {
            }

            .boxes {
                flex-direction: column;
                .oneBox {
                    padding: 0 30px;
                    margin-bottom: 20px;
                    p {
                    }

                    h4 {
                    }
                }
            }
        }

        .transportBox {
            .rwt__tablist {
            }

            .rwt__tab {
                font-size: 15px;
                &[aria-selected="true"] {
                    &:after {
                    }
                }
            }

            #basic-tab-one,
            #basic-tab-two,
            #basic-tab-three,
            #basic-tab-four,
            #basic-tab-five,
            #basic-tab-six,
            #basic-tab-seven {
                padding: 30px;
            }

            .rwt__tablist:not([aria-orientation="vertical"]) {
                overflow: auto;
            }

            #oneTransport {
                padding: 0;
                justify-content: center;
                flex-wrap: wrap;
                .image {
                    img {
                    }
                }
                .text {
                    p {
                    }
                }
            }
        }
    }
}
