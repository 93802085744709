#covid {
    padding-top: 90px;
    min-height: 1000px;
    overflow-x: hidden;
    b {
        margin-bottom: 20px;
    }

    ol {
        li {
            line-height: 1.5;
        }
    }

    ul {
        li {
            text-align: left;
        }
    }

    .slider {
        width: 100%;
        height: 350px;
        position: relative;

        h2 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            font-size: 35px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }

    .mainText {
        width: 100%;
        margin: auto;
        text-align: left;
        padding-top: 10px;
        max-width: 90vw;
        span {
            font-family: "SpaceGrotesk-SemiBold";
        }
    }

    .stateInformation {
        font-family: "SpaceGrotesk-Bold";
    }

    .articlePar {
        span {
            font-family: "SpaceGrotesk-Bold";
        }
    }

    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 100px;

        .container {
            width: 85%;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .imageArticle {
                margin-top: 40px;

                .textSection {
                    width: 100%;
                    margin-top: 30px;

                    h2 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }

                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;

                        span {
                            font-family: "SpaceGrotesk-SemiBold";
                        }
                    }
                }

                .imageSection {
                    width: 100%;
                    height: 300px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;

                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }

                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }

                .textSection {
                    width: 45%;
                }
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        flex-wrap: wrap;

        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;

            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: left;
            }
        }
    }

    .asInfoContainer {
        width: 900px;
        padding: 50px;
        padding-bottom: 0;
        margin: auto;
        max-width: 90vw;

        ul {
            li {
                line-height: 1.7;
                margin-bottom: 15px;

                span {
                    font-family: "SpaceGrotesk-SemiBold";
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #covid {
        padding-top: 70px;

        .info {
            .container {
                width: 100%;
            }
        }

        .mainText {
            text-align: left;
        }

        .asInfoContainer {
            padding-left: 20px;
        }

        .tripInfo {
            h3 {
                text-align: left;
            }
        }

        .info {
            .container {
                box-sizing: border-box;
                .imageArticle .imageSection {
                    height: 250px;
                }

                .firstSection,
                .secondSection {
                    padding-top: 0;

                    .textSection {
                        width: 100%;

                        h2 {
                            margin-top: 30px;
                        }
                    }

                    .imageSection {
                        width: 100%;
                        height: 250px;
                    }
                }

                .secondSection {
                    .imageSection {
                        img {
                            &.firstPhoto {
                                margin-bottom: 20px;
                            }

                            &.secondPhoto {
                                margin-bottom: 20px;
                            }
                        }
                    }

                    .textSection {
                        h2 {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        .tags {
            width: auto;
            justify-content: center;

            .oneTag {
                margin-bottom: 30px;
            }
        }
    }
}
