.blogContainer {
    background-color: #fff;

    .content {
        padding: 100px 0;
        flex-direction: column;
        display: flex;
        margin: auto;
        width: 1100px;
        max-width: 90vw;
        margin-top: 50px;

        h1 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .blogDate {
            font-size: 12px;
        }

        #blogList {
            text-decoration: underline;
            margin-bottom: 10px;
            color: #123852fc;

            &::before {
                content: '\2190';
            }
        }
    }
}