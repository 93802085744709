#tripFilter {
    margin-top: 145px;
    margin-left: 40px;

    .charterBox {
        width: auto;
        height: auto;
        min-height: 200px;
        border-radius: 10px;
        padding: 20px;
        background: white;
        box-sizing: border-box;
        max-width: fit-content;
        text-align: center;
       
    }

    .filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;

        h4 {
            font-size: 19px;
            cursor: pointer;
        }

        p {
            cursor: pointer;
        }
    }

    .container {
        background-color: white;
        position: relative;
        border-radius: 10px;
        width: 300px;
        padding: 15px 25px;
        box-sizing: border-box;
        margin: auto;
        margin-bottom: 70px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // position: sticky;
        // top: 30px;

        .boxFilter {
            position: relative;

            p.clear {
                position: absolute;
                right: 20px;
                top: 0;
                font-size: 14px;
                color: gray;
                cursor: pointer;
                margin: 0;
            }

            .accordion {
                border: none;
                cursor: pointer;

                .accordion__button {
                    padding: 15px 10px;
                    box-sizing: border-box;
                    background: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    outline: none;
                    padding-right: 35px;
                    cursor: pointer;

                    &:after {
                        display: flex;
                        box-sizing: border-box;
                        content: "";
                        height: 10px;
                        width: 10px;
                        margin-right: 0;
                        border-bottom: 2px solid currentColor;
                        border-right: 2px solid currentColor;
                        transform: rotate(45deg);
                        margin-right: -20px;
                    }

                    &:before {
                        content: none;
                    }
                }

                .accordion__panel {
                    padding: 0;
                    // display: none;
                }
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0;
                margin-bottom: 0;
                cursor: pointer;

                h4 {
                    font-size: 19px;
                    cursor: pointer;
                    margin: 0;

                    i {
                        font-size: 15px;
                        color: #81c8e8;
                        margin-left: 5px;
                    }
                }

                p {
                    cursor: pointer;
                }
            }

            .accordion__button[aria-expanded="true"]::after,
            #faqWrapper .accordion__button[aria-selected="true"]::after {
                transform: rotate(225deg);
            }


            #typeCruiseButton {
                padding: 15px 10px;
                box-sizing: border-box;
                background: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                outline: none;
                padding-right: 35px;
                cursor: pointer;

                &:after {
                    display: flex;
                    box-sizing: border-box;
                    content: "";
                    height: 5px;
                    width: 5px;
                    margin-right: 0;
                    border-bottom: 2px solid currentColor;
                    border-right: 2px solid currentColor;
                    transform: rotate(45deg);
                    margin-right: -20px;
                }

                &:before {
                    content: none;
                }
            }

            .tripProgram,
            .tripCountry,
            .tripTerm,
            .tripAddOptions {
                position: relative;
                width: auto;

                .containerInput {
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    user-select: none;
                    padding: 10px;
                    width: 100%;
                    border: 1.5px solid #e4f4fb;
                    border-radius: 6px;
                    align-items: center;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;

                    p.titleText {
                        font-family: "SpaceGrotesk-Medium";
                        font-size: 15px;
                        cursor: pointer;
                        margin: 0;
                        margin-left: 30px;

                        span {
                            color: #808080b0;
                            margin-left: 5px;
                            font-size: 15px;
                        }
                    }

                    img {
                        width: 32px;
                        height: 32px;
                        opacity: 0.7;
                    }

                    input {
                        position: absolute;
                        opacity: 0;
                        height: 0;
                        width: 0;

                        &:hover input~.checkmark {
                            background-color: white;
                        }

                        &:checked~.checkmark {
                            background-color: white;
                            border: 1.9px solid black;

                            &:after {
                                display: block;
                            }
                        }

                        &:checked~.oneBox {
                            border: 1.9px solid black;
                        }

                        &:checked~img {
                            opacity: 1;
                        }
                    }

                    span.checkmark {
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        height: 20px;
                        width: 20px;
                        border-radius: 5px;
                        border: 2px solid #80808075;
                        cursor: pointer;
                        transform: translate(-50%, -50%);

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        &:after {
                            left: 7px;
                            top: 3px;
                            width: 3px;
                            height: 10px;
                            border: solid #081d2b;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }
                    }

                    .oneBox {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        border-radius: 5px;
                        margin: -2px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .tripCountry {
                .containerInput img {
                    opacity: 1;
                }
            }
        }



        [hidden] {
            // display: revert!important;
        }

    }




    .header.type {
        p {
            cursor: pointer;
            font-size: 15px;
            margin: 0;
            padding: 0;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
        cursor: pointer;

        h4 {
            font-size: 19px;
            cursor: pointer;
            margin: 0;

            i {
                font-size: 15px;
                color: #81c8e8;
                margin-left: 5px;
            }
        }

        p {
            cursor: pointer;
        }
    }
}



@media only screen and (max-width: 767px) {
    .isVisible {
        transform: translate(0, 0) !important;
    }

    #tripFilter {
        max-width: 100%;
        width: 349px;
        height: 100%;
        //position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        //flex-direction: row-reverse;
        align-items: flex-start;
        transform: translate(-270px, 0);
        transition-duration: 0.5s;
        margin-left: 0;
        margin-top: 100px;

        &.filteredTripsFixed {
            position: fixed;
            flex-direction: row-reverse;
        }

        .filtersContainer {
            background: white;
            overflow-y: auto;
            height: 100%;
        }
        
        .charterBox {
            justify-content: flex-start;
            background: white;
            width: 100%;
            max-width: 282px;
            padding: 16px;
            border-radius: 0;
            border: 1px solid #e4f4fb;
            overflow: overlay;
            height: 100%;           
        }

        .filters {
            background: #e4f4fb;
            padding: 4px 16px;
            border-radius: 0 12px 12px 0;
            border: 1px solid #e4f4fb;           
        }

        .container {           
            justify-content: flex-start;
            background: white;
            width: 100%;
            max-width: 282px;
            padding: 16px;
            border-radius: 0;
            border: 1px solid #e4f4fb;
            overflow: overlay;
            margin-bottom: 0;

            .boxFilter {
                p.clear {
                    margin-top: 18px;
                }

                .accordion {
                    display: block;
                    border: none;



                    .accordion__panel {
                        padding: 0;

                    }
                }

                .header {


                    h4 {
                        i {}
                    }

                    p {
                        margin-left: 10px;
                    }
                }

                .tripProgram,
                .tripCountry,
                .tripTerm,
                .tripAddOptions {
                    .containerInput {
                        margin-left: 12px;

                        p.titleText {
                            span {}
                        }

                        img {}

                        input {
                            &:hover input~.checkmark {}

                            &:checked~.checkmark {
                                &:after {}
                            }

                            &:checked~.oneBox {}

                            &:checked~img {}
                        }

                        span.checkmark {
                            &:after {}

                            &:after {}
                        }

                        .oneBox {
                            &:last-child {}
                        }
                    }
                }

                .tripCountry {
                    .containerInput img {}
                }
            }

            [hidden] {
                display: none !important;
            }
        }
    }
}