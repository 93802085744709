#sectionShortInfoWrapper {
  padding: 70px 15px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  padding-bottom: 0;

  p.infoText2 {
    color: white;
    background: orange;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 0;
    width: fit-content;
    margin-top: 0;
  }

  h2 {
    margin: 0;
    font-size: 24px;
  }

  span {
    font-family: "SpaceGrotesk-SemiBold";
  }

  #ShortInfoContainer {
    display: flex;
    justify-content: space-evenly;
    max-width: 1200px;
    width: 100%;
    margin: auto;

    #ShortInfoWrapper {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: left;

      .offerTagBoxWrapper {
        position: static;
        margin: 25px 0 0 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 400px;

        .offerTagBox {
          margin: 0 10px 10px 0;
          height: 30px;
          padding: 0 10px;

          span {
            font-size: 14px;
            color: #12b342;
          }
        }
      }
    }

    #shortInfoImagesWrapper {
      width: 60%;
      display: flex;
      justify-content: space-between;
      height: auto;
      padding-left: 75px;
      box-sizing: border-box;

      .shortInfoImagesLeft {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: auto;

        img {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
          box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.42);
        }
      }

      .shortInfoImagesRight {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 50%;
        height: 100%;
        margin: auto;
        padding-left: 25px;
        box-sizing: border-box;

        img {
          max-width: 100%;
          height: 48%;
          border-radius: 5px;
          box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.42);
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (max-width: 999px) {
  #sectionShortInfoWrapper {
    h2 {
      margin: 20px 0 0 0;
      text-align: center;
    }

    .shortInfoDesciption {
      p {
        text-align: center;
      }
    }

    #ShortInfoContainer {
      flex-wrap: wrap;
      justify-content: center;

      #ShortInfoWrapper {
        width: 100%;

        .offerTagBoxWrapper {
          flex-wrap: wrap;
          justify-content: center;
          max-width: 100%;

          .offerTagBox {
            margin: 0 10px 10px 0;
          }
        }
      }

      #shortInfoImagesWrapper {
        padding-left: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #sectionShortInfoWrapper {
    #ShortInfoContainer {
      flex-direction: column;
      max-width: 90vw;

      #ShortInfoWrapper {
        width: 100%;
      }

      #shortInfoImagesWrapper {
        width: 100%;
        height: 200px;
        justify-content: space-between;

        .shortInfoImagesLeft {
          width: 45%;
          height: auto;
          max-height: 200px;

          img {
            margin-bottom: 0;
            height: 200px;
          }
        }

        .shortInfoImagesRight {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 200px;

          img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  #sectionShortInfoWrapper {
    padding: 20px 0 20px;
  }

  #ShortInfoContainer #shortInfoImagesWrapper .shortInfoImagesRight {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
  }
}
