#offerFaqWrapper {
    background: #fff;
    padding: 50px 15px;

    .accordion {
        max-width: 1150px;
        margin: 0 auto 25px auto;

        &__button {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:after {
                content: "";
                height: 5px;
                width: 5px;
                margin-right: 12px;
                border-bottom: 2px solid currentColor;
                border-right: 2px solid currentColor;
                float: right;
                transform: rotate(-315deg);
            }

            &:before {
                display: none;
            }
        }
    }

    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        transform: rotate(225deg);
    }

    .titleWrapper {
        text-align: center;
        margin-bottom: 50px;

        h2 {
            margin-top: 0;
        }
    }

    .buttonWrapper {
        max-width: 1150px;
        margin: 50px auto 0 auto;
        text-align: center;

        p {
            max-width: 500px;
            width: 100%;
            margin: 25px auto 0 auto;
        }
    }
}
