.containerCruiseCarousel {
    padding: 50px;
    width: 100%;
    overflow-x: hidden;
    padding-top: 100px;
  
  
  .slick-slide img {
    margin: auto;
  }
  
  .slick-dots {
    bottom: 30px;
    display: flex !important;
    justify-content: center;
    li {
      width: 50px;
      button {
        width: auto;
  
        &:before {
          font-size: 30px;
          color: white;
        }
      }
    }
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white;
  }
  
  .slick-dots li button:before {
    position: relative;
  }
  
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
  }
  
  .slick-next,
  .slick-prev {
    width: 45px;
    right: -30px;
  }
  
  .slick-prev {
    left: -30px;
  }
  
  .slick-next {
    right: -30px;
  }
  
  .slick-slide.slick-active div {
    outline: none;
  }
  
  .slick-list {
    padding-bottom: 20px;
  }
}
  

  @media only screen and (max-width: 768px) {
    .containerCruiseCarousel {
      padding: 0 35px;
      width: 100%;
      overflow: hidden;
  
    .slick-list,
    .slick-slider,
    .slick-track {
      height: 500px;
      padding-top: 10px;
    }
  }}