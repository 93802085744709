$fColor: #666;
$textTestimonial: Poppins, sans-serif;
$bgTestimonials: #6d656514;
$fColor: #666;
$fFamily: Georgia, serif;
$borderImg: rgb(248, 248, 248);
$sColor: rgba(0, 0, 0, 0.32);

#testimonialsContainer {
  padding: 40px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("./img/testimonials_bg.jpg") center no-repeat;
  background-size: cover;

  .testimonialsTitle {
    text-align: center;
    padding: 0 15px;

    h2 {
      margin: 0;
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .testimonialsContentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 15px;

    .testimonialsSocialRate {
      .testimonialsSocialItem {
        text-align: center;

        img {
          width: 200px;
        }

        p {
          color: #fff;
        }
      }
    }

    .testimonialsContent {
      position: relative;
      padding-top: 20px;

      .prev,
      .next {
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        z-index: 9;
      }

      .next {
        top: 50%;
        right: -60px;
        transform: translateY(-50%);
        box-shadow: -4px 1px 30px -4px rgba(0, 0, 0, 0.07);
      }

      .prev {
        top: 50%;
        left: -60px;
        transform: translateY(-50%);
        box-shadow: -4px 1px 30px -4px rgba(0, 0, 0, 0.07);
      }

      .testimonialsWrapper {
        display: -webkit-inline-box;
        overflow-x: auto;
        overflow-y: hidden;
        width: 600px;
        align-items: center;
        padding-top: 40px;
      }

      .testimonialsWrapper::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #testimonialsContainer {
    .testimonialsContentWrapper {
      .testimonialsContent {
        width: 300px;
        margin: auto;

        .testimonialsWrapper {
          width: 300px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #testimonialsContainer {
    padding-bottom: 55px;
    flex-direction: column;

    .testimonialsContentWrapper {
      .testimonialsTitle {
        margin: auto;
        align-items: center;

        h1,
        p {
          text-align: center;
        }
      }

      .testimonialsContent {
        padding-bottom: 60px;
        .next {
          bottom: 0;
          right: 30%;
          top: auto;
          transform: none;
        }

        .prev {
          transform: none;
          top: auto;
          bottom: 0;
          left: 30%;
        }

        .testimonialsWrapper {
          width: 300px;
          margin: auto;
          display: -webkit-box;
        }
      }
    }
  }
}

@media only screen and (max-width: 582px) {
  #testimonialsContainer {
    .testimonialsContentWrapper {
      .testimonialsSocialRate {
        margin: 25px 0;
      }
    }
  }
}
