#changePassword {
    margin-top: 50px;
    width: 100%;
    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    .form {
        width: 320px;
        i {
            font-size: 18px;
            cursor: pointer;
        }

        form {
            position: relative;
            .passwordBox {
                position: relative;
                span {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    right: 0;
                }
            }
            p {
                text-align: left;
                margin-bottom: 10px;
                margin-top: 10px;
                font-size: 15px;
            }

            input {
                width: 100%;
                padding: 23px 15px;
                margin: 0;
                height: 35px;
                border-radius: 5px;
                border: 1px solid grey;
                padding-right: 0;
                font-size: 17px;
                box-sizing: border-box;
            }

            input::-webkit-input-placeholder {
                font-size: 16px;
                color: #808080a6;
                font-family: SpaceGrotesk;
            }

            input:focus {
                outline: grey auto 1px;
            }

           
        }
        .primaryBtn {
            margin-top: 50px;
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    #changePassword {
        width: auto;
        h2 {
            margin-bottom: 50px;
            margin-top: 40px;
        }
        margin: auto;
    
        .form {
            margin: auto;
            text-align: left;
            width: auto;
            .primaryBtn {
                margin-left: 0;
            }
            form {
                margin: auto;
            }
        }
    }
}
