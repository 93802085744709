#highlightsContainer {
  text-align: center;
  padding-bottom: 70px;
  background-color: white;

  .textBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 90vw;

    h2 {
      letter-spacing: 0.6px;
      margin: 50px 0 40px 0;
    }

    p {
      margin-top: 0;
      text-align: center;
      width: 600px;
      max-width: 90vw;
    }
  }

  .textBox .title {
    width: auto;
    min-width: 150px;
    margin-bottom: 0;
  }

  .main {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .wrapper {
      position: relative;
      height: auto;

      .prev,
      .next {
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        z-index: 9999;
      }

      .blockLeft {
        display: block;
        height: 100%;
        width: 50px;
        position: absolute;
        left: 0;
        z-index: 999;
        background: linear-gradient(90deg, white 20%, rgba(255, 255, 255, 0.75) 30%, rgba(255, 255, 255, 0) 100%);
      }

      .prev {
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
      }

      .imageContainer {
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        height: 450px;
        align-items: center;

        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
      }

      .next {
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
      }

      .blockRight {
        display: block;
        height: 100%;
        width: 50px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.75) 90%, white 100%);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #highlightsContainer {
    padding-bottom: 50px;

    .textBox {
      margin: auto;
    }
  }
}
