.OfferScheduleContainer {
  padding-right: 200px;
  padding-left: 200px;
  padding-bottom: 30px;
  background-color: #ffffff;

  img {
    width: 500px;
    margin: auto;
    display: flex;
    max-width: 90vw;
  }

  h3 {
    font-family: "SpaceGrotesk-Medium";
    padding: 0;
    margin: 30px 0;
    margin-bottom: 70px;
  }

  ul {
    padding-left: 8px;
  }

  li {
    text-align: left;
    line-height: 1.8;
    font-size: 16px;
  }

  p.schedule {
    text-align: center;
    width: 800px;
    margin: auto;
    margin-bottom: 50px;
    max-width: 90vw;

    &.description {
      width: 800px;
      margin: auto;
      margin-bottom: 50px;
      max-width: 85vw;

      p {
        text-align: center;
      }
    }
  }
}

.ScheduleRow {
  display: flex;
  justify-content: center;
}

.ScheduleColumnLeft {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40%;
  margin-right: 30px;
}
#academy > div > p > h3:nth-child(1) {
  margin-top: 0;
}

.ScheduleColumnMiddle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
}

.ScheduleColumnRight {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40%;
  margin-left: 30px;
}

.ScheduleItem {
  height: auto;
  width: 80%;

  border-radius: 5px;
  padding: 25px;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
  font-size: 0.75em;

  p {
    font-size: 15px;
    line-height: 1.7;
    span {
      font-size: 16px;
      font-family: "SpaceGrotesk-SemiBold";
      display: block;
      margin-bottom: 10px;
    }
  }

  h4 {
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: "SpaceGrotesk-Medium";
  }
}

.OfferCircleBox {
  border-radius: 50%;
  background-color: #ffd15f;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  span {
    padding: 10px;
    text-align: center;
    font-size: 17px;
    z-index: 10;
    font-weight: bold;
  }
}

.itemLine:before {
  content: "";
  height: 200px;
  border: solid 1px #ffd15f;
  position: absolute;
  left: calc(50% - 1px);
  top: -200px;
  background-color: #ffd15f;
}

#academy {
  padding-top: 0;

  .academyBox {
    width: 700px;
    margin: auto;
    min-width: fit-content;

    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);

    p {
      text-align: left;
      font-size: 16px;

      &:first-child {
        text-align: center;
        margin: 0;
        font-size: 18px;
      }

      ol {
        margin-top: 20px;
        padding-left: 10px;

        li {
          margin: 10px;
          text-align: left;
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .OfferScheduleContainer {
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
    p {
      text-align: center;
      font-size: 15px;
      box-sizing: border-box;
      height: 100%;
    }
  }

  .ScheduleRow {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .ScheduleColumnLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0;
    margin-right: 0;
  }

  .ScheduleColumnMiddle {
    order: 1;
    margin-bottom: 30px;
  }

  .ScheduleColumnRight {
    width: 100%;
    margin-left: 0;
  }

  .ScheduleItem {
    height: auto;
  }

  .itemLine:before {
    border: none;
  }

  #academy {
    padding-top: 5px;
    .academyBox {
      width: 700px;
      margin: auto;
      min-width: auto;
      max-width: 90vw;
      padding: 20px;

      p:first-child {
        width: 100%;
        padding: 0;
      }

      ol {
        padding-left: 10px;
      }
    }
  }
}
