.offerAccommodationSection {
  padding: 90px;
  background: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 30px;

  .offerAccommodationTitleWrapper {
    text-align: center;
    padding: 0 15px;
  }

  h1 {
    margin-top: 0;
  }
}

#offerAccommodationGeneralWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  margin: auto;

  .descriptionAcademy {
    width: 45%;
  }

  img {
    width: 45%;
    border-radius: 5px;
  }

  #offerAccommodationImagesWrapper {
    width: 450px;
    display: flex;
    justify-content: space-between;
    height: 300px;

    .offerAccommodationImagesLeft {
      display: flex;
      flex-direction: column;
      width: 350px;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.42);
      }
    }

    .offerAccommodationImagesRight {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
      width: 350px;
      height: 300px;
      margin: auto;

      img {
        max-width: 90%;
        width: 100%;
        height: 48%;
        border-radius: 5px;
        box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.42);
        object-fit: cover;
      }
    }
  }

  #offerAccommodationImagesWrapper.thai {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .offerAccommodationInfoWrapper {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
}

#offerAccommodationResidencesWrapper {
  padding-top: 50px;
}

@media only screen and (max-width: 999px) {
  .offerAccommodationSection {
    padding: 90px 15px;
  }
}

@media only screen and (max-width: 767px) {
  #offerAccommodationResidencesWrapper {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .offerAccommodationSection {
    flex-direction: column;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;

    h1 {
      text-align: center;
      font-size: 32px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  #offerAccommodationGeneralWrapper {
    flex-direction: column;
    width: 100%;
    max-width: 90vw;

    img {
      width: 100%;
      max-width: 100%;
      margin: 0 !important;
    }

    p {
      margin: 0;
      margin-top: 10px;
      width: 100%;
    }
  }

  .residencesTab {
    margin-top: 0px;
    padding: 20px;
    box-sizing: border-box;

    #offerAccommodationGeneralWrapper {
      flex-direction: column-reverse;
      width: 100%;

      img {
        width: 100%;
        max-width: 100%;
        height: 250px;
      }

      p {
        margin: 0;
        margin-top: 10px;
      }
    }
  }

  #offerAccommodationGeneralWrapper #offerAccommodationImagesWrapper .offerAccommodationImagesLeft {
    height: 200px;
    width: 45%;
  }

  #offerAccommodationGeneralWrapper {
    #offerAccommodationImagesWrapper {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }

    .offerAccommodationInfoWrapper {
      width: 100%;
    }
  }

  #offerAccommodationGeneralWrapper #offerAccommodationImagesWrapper .offerAccommodationImagesRight {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 200px;
  }
}
