#offerPriceSection {
  background-color: white;
  width: 100%;
  min-height: 800px;

  ul {
    li {
      span {
        font-family: "SpaceGrotesk-SemiBold";
      }
    }
  }

  .secondaryBtn {
    margin: auto;
    margin-top: 50px;
    display: flex;
  }

  .container {
    height: 100%;
    padding-top: 50px;
    margin: auto;
    max-width: 90vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h2 {
      font-size: 28px;
      margin-bottom: 15px;
      margin-top: 0;
    }

    p {
      width: 700px;
      text-align: center;
      max-width: 90vw;
    }

    .boxes {
      width: 1100px;
      max-width: 90vw;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      flex-wrap: wrap;

      .oneBox {
        width: 350px;
        box-shadow: 2px 26px 68px -21px #b8b6b8;
        border-radius: 5px;
        background-color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        min-height: 300px;
        box-sizing: border-box;
        padding: 30px;
        border: 1px solid #80808021;

        .offerPrice-TimetableWrapper {
          .timetableItem {
            margin-top: 2em;

            .timetableItem-head {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;

              p {
                font-weight: 600;
                display: flex;
                flex-direction: column;

                span {
                  font-size: 12px;
                }
              }
            }

            .timetableItem-bar {
              background: #e0e4eb;
              height: 0.5rem;
              border-radius: 0.25rem;
              display: flex;

              &:after {
                content: "";
                background: #0284fe;
                width: 30%;
                height: 100%;
                display: block;
                border-radius: 0.25rem 0 0 0.25rem;
              }
            }

            .tItem-bar2 {
              &:after {
                width: 20%;
                border-radius: 0;
              }

              &:before {
                content: "";
                background: #44db5e;
                width: 30%;
                height: 100%;
                display: block;
                border-radius: 0.25rem 0 0 0.25rem;
              }
            }

            .tItem-bar2w {
              &:after {
                width: 50%;
                border-radius: 0 0.25rem 0.25rem 0;
              }

              &:before {
                content: "";
                background: #44db5e;
                width: 50%;
                height: 100%;
                display: block;
                border-radius: 0.25rem 0 0 0.25rem;
              }
            }
          }
        }

        ul {
          padding: 0;
        }

        p {
          width: auto;
          text-align: left;
          margin: 0;
          color: rgb(43, 51, 63);
          font-size: 15px;
        }

        .titleContainer {
          width: 100px;
          height: 50px;
          padding-bottom: 20px;
          margin: auto;
          display: flex;

          img {
            width: 100px;
            height: 50px;
            padding-bottom: 20px;
            margin: auto;
            display: flex;
          }

          h4 {
            margin: auto;
            font-size: 22px;
          }
        }
      }
    }
  }

  #otherPaymentsInfo {
    padding-bottom: 50px;

    .container {
      width: 1100px;
      margin: auto;
      height: 100%;
      min-height: 800px;
      max-width: 95vw;

      .firstSection,
      .secondSection {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: auto;
        flex-wrap: wrap;
        padding-top: 60px;

        .textSection {
          width: 40%;

          h2 {
            font-size: 25px;
            margin-bottom: 0;
            margin-top: 10px;
          }

          P,
          li {
            font-size: 16px;
            font-family: "SpaceGrotesk";
            line-height: 1.6;
            margin-bottom: 25px;
            width: auto;
            text-align: left;

            span {
              font-family: "SpaceGrotesk-SemiBold";
            }
          }
        }

        .imageSection {
          width: 45%;
          height: 320px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
            box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
          }
        }
      }

      .secondSection {
        flex-direction: row-reverse;
        padding-top: 0;

        .imageSection {
          width: 40%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          height: auto;
          max-height: auto;

          img {
            &.firstPhoto {
              width: 90%;
              height: 200px;
              border-radius: 8px;
              object-fit: cover;
              height: 250px;
            }

            &.secondPhoto {
              width: 80%;
              height: 150px;
              border-radius: 8px;
              object-fit: cover;
              height: 200px;
              margin-top: 20px;
            }
          }
        }

        .textSection {
          width: 45%;
        }
      }
    }
  }

  .container.price {
    background-color: #f2f2f2;
    width: auto;
    padding-bottom: 30px;
    max-width: none;

    h2 {
      margin-bottom: 0;
    }

    .boxes {
      width: 1150px;

      .oneBox {
        width: 49%;
        align-items: stretch;
        padding: 40px;
        border-radius: 10px;

        .titleContainer {
          width: auto;
          height: auto;
        }

        &.priceInclude {
          border: 2px solid green;
        }

        &.priceNotInclude {
          h4 {
            font-size: 18px;
          }

          .elements {
            p {
              font-size: 14px;
            }
          }
        }

        .elements {
          li {
            padding: 10px 0;
            border-bottom: 1px solid #8080805c;
            font-size: 16px;
            list-style: none;

            a {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
            }
          }

          li.refItem {
            font-size: 12px;
            font-style: italic;
          }
        }

        .infoBox {
          border-radius: 5px;
          background: #ccefd6;
          padding: 20px 40px;
          margin-top: 20px;

          p {
            font-family: "SpaceGrotesk-SemiBold";
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #offerPriceSection {
    .container.price {
      h2 {
        margin-bottom: 35px;
      }
    }

    .container.price {
      .boxes {
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        margin-left: 0;

        .oneBox {
          width: 100%;
          padding: 30px 20px;
          border-radius: 10px;
          margin: 15px 0;

          .infoBox {
            padding: 20px;
          }

          &.priceNotInclude {
            .elements {
              ul {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}