.blog {
    padding-top: 100px;
    background-color: #fff;

    #blogPost {
        width: 100%;
        min-height: 300px;
        border-radius: 5px;
        box-shadow: 2px 26px 68px -21px #b8b6b8;
        display: flex;
        align-items: center;
        margin: 40px 0;
        cursor: pointer;
        box-sizing: border-box;
        border: 0.5px solid transparent;

        .photoContainer {
            height: 100%;
            min-height: 300px;
            width: 35%;
            flex-shrink: 0;

            .logoContainer {
                box-sizing: border-box;
                width: 100%;
                height: auto;
                background-color: #123852fc;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px 0 0 10px;
                padding: 20px;
                min-height: 300px;

                img {
                    min-height: auto;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px 0 0 10px;
                min-height: 300px;
            }
        }

        &:hover {
            border: 0.5px solid #b8b6b8;
            transition: all 0.5s ease-out;
        }
    }

    .blogInfo {
        width: 100%;
        padding: 40px 50px 40px 50px;
        cursor: pointer;

        h5 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .blogDate {
            font-size: 12px;
        }
    }


    .content {
        flex-direction: column;
        display: flex;
        margin: auto;
        width: 1100px;
        max-width: 90vw;
        margin-top: 50px;

        .mainText {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 90vw;
            margin: auto;
        }
    }

    .secondaryBtn {
        width: 50px;
        margin: 30px auto;
    }

}


@media only screen and (max-width: 767px) {
    .blog {
        #blogPost {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: auto;
            max-width: 90vw;
            margin-bottom: 50px;

            .blogInfo {
                padding: 20px;
                box-sizing: border-box;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .photoContainer {
                width: 100%;
                height: 200px;
                max-width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px 10px 0 0;
                }

                .logoContainer {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    background-color: #123852fc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px 10px 0 0;
                    padding: 20px;
                    min-height: 300px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 10px 10px 0 0;
                    }
                }
            }

        }

        .content {
            margin-top: 20px;
            padding-bottom: 0;

            .mainText {
                text-align: center;
            }
        }
    }

}