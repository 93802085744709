#stepFour {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px 90px;

    .lds-roller {
        top: 100%;
    }

    .errorMessage {
        font-size: 15px;
    font-family: 'SpaceGrotesk-Medium';
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;
        .greenBox {
            margin-top: 0;
            margin-left: -30px;
            max-width: 600px;
            width: fit-content;
            p {
                margin-bottom: 20px;
            }
        }
        .onePerson {
            margin-bottom: 50px;

            .name {
                padding: 0 30px;
                background-color: #80808021;
                border-radius: 5px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: -30px;
                height: 85px;
                .containerAddName {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                p {
                    font-size: 25px;
                    margin: 0;
                    font-family: "SpaceGrotesk-Bold";
                }
            }

            p.warningText {
                padding-top: 0;
                width: 95%;
            }

            #training.selectRadio h4 {
                margin-bottom: 25px;
            }
            select {
                margin-top: 10px;
                margin-bottom: 20px;
                width: 100%;
                padding: 12px;
                background-size: 11px;
                float: none;
                margin-top: 10px;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
            }

            input {
                width: 200px;
                margin-left: 20px;
            }

            .secondaryBtn {
                min-width: 120px;
                margin-left: 20px;
            }
        }

        #training.selectRadio,
        #trainingLevel.selectRadio,
        #clothes.selectRadio {
            h3 {
                border-bottom: 1px solid #80808066;
                padding-bottom: 25px;
                font-size: 24px;
                margin-bottom: 40px;
            }
            .boxes {
                justify-content: flex-start;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                .boxInputRadio {
                    min-height: 200px;
                    width: 250px;
                    padding: 20px;
                    padding-bottom: 0;
                    padding-top: 0;
                    margin-right: 10px;
                    p.price {
                        font-size: 25px;
                        text-align: center;
                        line-height: 1;
                        margin: auto;
                        padding: 30px 0;
                        font-family: "SpaceGrotesk-Medium";
                    }

                    p.titleText {
                        margin-top: 19px;
                        margin-bottom: 15px;
                        font-size: 17px;
                        font-family: "SpaceGrotesk-Medium";
                        width: 150px;
                    }

                    span.checkmarkRadio {
                        top: 20px;
                    }

                    .imageTraining {
                        width: auto;
                        margin-bottom: 15px;
                        margin-top: 5px;
                        height: 140px;

                        img {
                            width: 100%;
                            border-radius: 5px;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .containerSelect {
            width: 550px;
            margin-bottom: 30px;
            margin-top: 20px;
            h4 {
                font-size: 17px;
                font-family: "SpaceGrotesk-SemiBold";
                margin-bottom: 10px;
            }
            p {
                font-size: 15px;
                color: #3c3a3a;
                cursor: pointer;
                margin-top: 0;
            }
            select {
                width: 100%;
                padding: 12px;
                background-size: 11px;
                float: none;
                margin-top: 10px;

                &[name="town"] {
                    margin-top: 20px;
                }
            }

            .containerInputCheckbox {
                margin-bottom: 25px;
                p {
                    font-family: "SpaceGrotesk-Medium";
                    color: black;
                    font-size: 18px;
                    margin-top: 0;
                    margin-bottom: 0;
                    span {
                        font-size: 15px;
                        color: #3c3a3a;
                        display: block;
                        margin-top: 5px;
                    }
                }

                span.checkmark {
                    top: 3px;
                }
                input:checked ~ .checkmark {
                    background-color: white;
                    top: 3px;
                }
            }
        }
    }
    .secondaryBtn {
        margin-right: 20px;
    }

    .greenBox .secondaryBtn {
        border: 1px solid #4caf50;
        color: #4caf50;

        &:hover {
            background-color: #4caf50;
            color: white;
        }
    }

    #notInterested {
        height: fit-content;
    }
}

@media only screen and (max-width: 767px) {
    #stepFour {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;
            margin-top: 50px;
            .greenBox {
                margin: 0;
                margin-top: 50px;
                p {
                }
            }
            .onePerson {
                .name {
                    width: 100%;
                    margin: 0;
                    margin-top: 50px;
                    .containerAddName {
                    }

                    p {
                    }
                }

                p.warningText {
                }

                #training.selectRadio h4 {
                }
                select {
                }
                p {
                }

                input {
                }

                .secondaryBtn {
                }
            }

            #training.selectRadio,
            #trainingLevel.selectRadio,
            #clothes.selectRadio {
                h3 {
                }
                .boxes {
                    overflow: auto;
                    flex-wrap: nowrap;
                    .boxInputRadio {
                        p.price {
                        }

                        p.titleText {
                        }

                        span.checkmarkRadio {
                        }

                        .imageTraining {
                            img {
                            }
                        }
                    }
                }
            }

            .containerSelect {
                width: auto;
                h4 {
                }
                p {
                }
                select {
                    width: 100%;
                    min-width: auto;
                   
                }

                .containerInputCheckbox {
                    p {
                        span {
                        }
                    }

                    span.checkmark {
                    }
                }
            }
        }
        .containerButtons {
            display: flex;
            justify-content: center;
           
            .secondaryBtn {
                min-width: 130px;
                margin: auto;
                
            }
            .primaryBtn {
                min-width: 130px;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    #stepFour {
        .container {
            .greenBox {
                p {
                }
            }
            .onePerson {
                .name {
                    .containerAddName {
                    }

                    p {
                    }
                }

                p.warningText {
                }

                #training.selectRadio h4 {
                }
                select {
                }
                p {
                }

                input {
                }

                .secondaryBtn {
                }
            }

            #training.selectRadio,
            #trainingLevel.selectRadio,
            #clothes.transportSelect {
                h3 {
                }
                .boxes {
                    .boxInputRadio {
                        p.price {
                        }

                        p.titleText {
                        }

                        span.checkmarkRadio {
                        }

                        .imageTraining {
                            img {
                            }
                        }
                    }
                }
            }

            .containerSelect {
                h4 {
                }
                p {
                }
                select {
                    &[name="town"] {
                    }
                }

                .containerInputCheckbox {
                    p {
                        span {
                        }
                    }

                    span.checkmark {
                    }
                }
            }
        }
        .secondaryBtn {
        }
    }
}
