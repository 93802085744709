.deleteAccountPopup {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000db;
  z-index: 999;
  cursor: pointer;

  .popupInner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    margin: auto;
    border-radius: 20px;
    background: white;
    min-height: 250px;
    width: 400px;
    text-align: center;
    padding: 30px 40px;

    h1 {
      font-size: 32px;
    }

    p {
      white-space: pre-wrap;
      padding: 20px;
      padding-top: 0;
      margin-top: 0;
    }

   button.primaryBtn {
      margin: 0!important;
      font-family: "SpaceGrotesk-Medium";
      font-weight: 400;
    }


    .linkPassword {
      margin-top: 20px;
      font-weight: 600;
      cursor: pointer;
      i {
      .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
      
        
      }

     
      }

      span {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        a:hover {
          text-decoration: underline;
        }
      }

    }

    .primaryBtn {
      font-weight: 600;
    }

    button.closeButton {
      font-size: 20px;
      border: none !important;
      background-color: transparent;
      position: absolute;
      right: 15px;
      top: 5px;
      font-weight: 800;
      padding: 5px 3px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .deleteAccountPopup .popupInner {

    left: 50%;
    right: 50%;
    width: 250px;
    transform: translate(-50%);
  }
}