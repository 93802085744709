#companyTrips {
    padding-top: 75px;
    min-height: 1000px;

    p {
        span {
            font-family: "SpaceGrotesk-SemiBold";
        }
    }
    .firstSection:nth-child(1),
    .firstSection:nth-child(3),
    .firstSection:nth-child(5) {
        .imageSection {
            order: 2;
        }

        .textSection {
            order: 1;
        }
    }

    h3 {
        padding: 20px;
        text-align: center;
        font-family: "SpaceGrotesk-Medium";
        max-width: 800px;
        margin: auto;
        padding-top: 50px;
    }

    .slider {
        width: 100%;
        height: 450px;
        position: relative;

        h2,
        h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 52%;
            left: 50%;
            margin: 0;
            font-size: 35px;
            filter: drop-shadow(2px 4px 6px black);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.8);
        }
    }

    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 50px;

        h2 {
            margin-top: 0;
            padding: 35px 80px 0;
            text-align: center;
            margin-bottom: 0;
        }

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                height: auto;
                flex-wrap: wrap;
                padding-top: 50px;

                .textSection {
                    width: 40%;

                    h2,
                    h1 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                        text-align: left;
                        padding: 0;
                    }

                    P {
                        font-size: 16px;
                        line-height: 1.6;
                    }
                }

                .imageSection {
                    width: 40%;
                    height: auto;
                    max-height: 300px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;

                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }

                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }

                .textSection {
                    width: 40%;
                }
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        flex-wrap: wrap;

        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;

            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: center;
            }
        }
    }

    // .tagsBox {
    //     width: 100%;
    //     padding-right: 20px;
    //     height: auto;
    //     display: flex;
    //     justify-content: flex-start;
    //     margin-bottom: 10px;
    //     flex-wrap: wrap;
    //     margin-top: 10px;

    //     .oneTag {
    //         border: 2px solid #ffd15f;
    //         font-family: "-Medium";
    //         border-radius: 5px;
    //         margin-bottom: 8px;
    //         padding: 6px;
    //         height: auto;
    //         width: auto;
    //         margin-right: 8px;
    //         font-size: 14px;
    //         display: flex;
    //         align-items: center;

    //         img {
    //             width: 25px;
    //             height: 25px;
    //             margin-right: 7px;
    //         }
    //     }
    // }
}

@media only screen and (max-width: 767px) {
    #companyTrips {
        padding-top: 70px;

        .slider {
            h2,
            h1 {
                text-align: center;
            }

            img {
                filter: brightness(0.6);
            }
        }

        .info {
            padding-bottom: 0px;

            h2 {
                margin-bottom: 0;
                padding: 30px 7px 5px;
                font-size: 28px;
            }

            .container {
                box-sizing: border-box;
                padding-top: 40px;

                .firstSection,
                .secondSection {
                    padding-top: 0;

                    .textSection {
                        width: 100%;

                        h2,
                        h1 {
                            margin-top: 30px;
                        }
                    }

                    .imageSection {
                        width: 100%;
                        order: 0;
                    }
                }
            }
        }

        .tags {
            width: auto;
            justify-content: center;
            padding-bottom: 0;

            .oneTag {
                margin-bottom: 30px;
            }
        }
    }
}
