#location {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: transparent;

    .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        max-width: 100vw;
        margin-top: 0;

        .wrapperInfo {
            width: 45%;
            display: flex;
            justify-content: center;
            box-sizing: border-box;

            .box {
                min-width: 320px;

                h1 {
                    font-size: 30px;
                    font-weight: 500;
                    margin-top: 0;
                    margin-bottom: 30px;
                }

                p {
                    margin: 0;
                }
            }
        }

        .wrapperForm {
            background-color: #123852fc;
            width: 55%;
            padding: 40px;
            padding-left: 70px;
            height: auto;
            box-sizing: border-box;
            padding-top: 20px;
    
                .oneContactBox {
                    line-height: 1.2;
    
                    h4 {
                        color: #ffe4a4;
                    }
    
                    p {
                        color: white;
                        font-size: 15px;
                        letter-spacing: 0.4px;
                        margin-top: 5px;
                        margin-bottom: 0;
                        font-family: SpaceGrotesk;
                    }
                }
            }
        
    }
 
}

@media only screen and (max-width: 768px) {
    #location {
        justify-content: center;
        text-align: center;

        .wrapper {
            margin: 0;
            justify-content: center;
            flex-direction: column;

            .wrapperForm, .wrapperInfo {
                width: 100%;
            }

            .wrapperForm {
                padding-left: 0;
                padding-right: 0;
            }

            .box {
                .btn {
                    margin: 30px auto;
                }
            }
            .form {
                max-width: 100%;
                justify-content: center;
                form {
                    max-width: 100%;
                    button {
                        margin: 20px auto 0;
                    }
                }
            }
        }
    }
}
