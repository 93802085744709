#offerAttractionsSection {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 0;

  .disclaimer {
    font-size: 15px;
    color: #808080de;
    font-family: "SpaceGrotesk";
    margin: 0 auto;
    padding-bottom: 50px;
    width: 1050px;
    max-width: 95vw;
  }

  .slick-arrow,
  .slick-prev {
    font-size: 0;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 50px;
    height: 0;
    width: 0;
  }

  .slick-next,
  .slick-prev {
    height: auto;
    width: auto;
  }

  .slick-next {
    right: -55px;
  }

  .slick-prev {
    left: -54px;
  }

  h2 {
    text-align: center;
  }

  .descriptionAttr {
    width: 700px;
    margin: 0 auto 3em auto;
    max-width: 90vw;
    text-align: left;
    padding: 0;

    p {
      text-align: center;
      padding: 0;
      margin: 10px 0;
    }
  }

  p {
    text-align: left;
    padding: 0 15px;
    font-size: 15px;
  }

  .offerAttractionsListWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 1100px;
    margin: auto;
    padding-bottom: 50px;

    .offerSingleAttraction {
      width: 32%;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      position: relative;
      border-radius: 15px;
      background-color: #f2f2f2bd;
      box-shadow: -2px 20px 40px -10px rgba(0, 0, 0, 0.04);
      overflow: hidden;
      margin-bottom: 0px;

      .popup-box {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        display: flex;

        .box {
          margin: auto;
          max-width: 500px;
          border-radius: 5px;
          position: relative;
          background: #fff;

          .close-icon {
            position: absolute;
            right: 12px;
            top: 4px;
            line-height: 1;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
            color: #fff;
          }

          .popup-content-wrapper {
            padding: 15px;
          }

          .popup-img {
            height: 250px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 3px 3px 0 0;
            }
          }

          .popup-title {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
          }
        }
      }

      .attractionBox {
        position: relative;
        height: 200px;

        &:before {
          content: "i";
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          border: 1px solid #fff;
          padding: 2px 10px;
          border-radius: 50%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin: 0;
          padding: 0;
          cursor: pointer;
          transition: all 0.4s ease-in-out;

          &:hover {
            transform: scale(1.4);
          }
        }

        .attractionTitle {
          position: absolute;
          bottom: 0;
          padding: 5px;
          margin: 0;
          font-size: 15px;
          color: #fff;
          width: 100%;
          line-height: 1.7;
          min-height: 60px;
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #0000005e 100%);
          box-sizing: border-box;
          border-radius: 0 0 15px 15px;
          cursor: pointer;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          margin: 20px;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  #offerAttractionsSection {
    .offerAttractionsListWrapper {
      width: 95%;

      .offerSingleAttraction {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #offerAttractionsSection {
    padding: 15px;

    .disclaimer {
      margin-top: 40px;
      font-size: 13px;
    }

    .slick-list {
      height: 100%;
    }

    .offerAttractionsListWrapper {
      padding-bottom: 0;
      width: 100%;

      .offerSingleAttraction {
        width: 45%;

        .popup-box {
          .box {
            max-width: 70%;
            height: auto;

            .popup-img {
              height: 200px;
            }

            .popup-content-wrapper {
              padding: 15px;
              height: 150px;
              overflow-x: scroll;
            }
          }
        }
      }
    }
  }
}
