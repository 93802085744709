#stepThree {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px 90px;
    .lds-roller {
        top: 100%;
    }

    .priceBoxInsurance {
        margin: auto;
    min-height: 70px;
    text-align: center;
    align-items: center;
    display: flex;
    } 

    h3 {
        font-size: 25px;
        border-bottom: 1px solid #8080804a;
        padding-bottom: 30px;
        font-family: "SpaceGrotesk-SemiBold";
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;

        .name {
            padding: 0 30px;
            background-color: #80808021;
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: -30px;
            height: 85px;

            .containerAddName {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            p {
                font-size: 25px;
                margin: 0;
                font-family: "SpaceGrotesk-Bold";
            }
        }

        #secondPerson,
        #thirdPerson {
            margin-bottom: 60px;
            margin-top: 30px;
        }

        .secondaryBtn {
            max-height: 50px;
            margin-right: 20px;
        }

        .redBox {
            height: 100%;

            a {
                text-decoration: underline;
            }
        }

        .selectRadio {
            padding-top: 5px;

            .container {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;

                .textBox {
                    width: 550px;
                    margin-bottom: 30px;

                    p {
                        margin-top: 0;
                        margin-right: 50px;
                        font-size: 15px;
                        color: #4c4f56cf;
                        margin-bottom: 0;

                        span {
                            text-decoration: underline;
                        }

                        a {
                            text-decoration: underline;
                        }
                    }

                    form {
                        .containerInputRadio {
                            height: auto;
                            width: 450px;
                            margin-top: 30px;

                            p {
                                margin: 0;

                                &:first-child {
                                    font-family: "SpaceGrotesk-Medium";
                                    font-size: 18px;
                                    color: inherit;
                                }

                                &:nth-child(2) {
                                    margin-top: 5px;
                                    color: #4c4f56cf;
                                }
                            }
                        }
                    }
                }

                .greenBox {
                    height: fit-content;
                    margin-top: 0;
                    width: 350px;

                    p {
                        line-height: 1.5;
                    }
                }
            }

            .containerInputCheckbox {
                a {
                    text-decoration: underline;
                    font-family: "SpaceGrotesk-Medium";
                }
            }
        }

        p.warningText {
            width: 600px;
        }

        .containerSelect {
            width: 550px;
            margin-bottom: 30px;

            h4 {
                font-size: 17px;
                font-family: "SpaceGrotesk-SemiBold";
                margin-bottom: 10px;
            }

            p {
                font-size: 15px;
                color: #3c3a3a;
                cursor: pointer;
                margin-top: 0;
            }

            select {
                width: 100%;
                padding: 12px;
                background-size: 11px;
                float: none;
                margin-top: 10px;

                &[name="town"] {
                    margin-top: 20px;
                }
            }

            .containerInputCheckbox {
                margin-bottom: 30px;
                margin-top: 30px;

                p {
                    font-family: "SpaceGrotesk-Medium";
                    color: #123852fc;
                    font-size: 18px;
                    margin-top: 0;
                    margin-bottom: 0;

                    span {
                        font-size: 15px;
                        color: #3c3a3a;
                        display: block;
                        margin-top: 5px;
                    }
                }

                span.checkmark {
                    top: 3px;
                }

                input:checked~.checkmark {
                    background-color: white;
                    top: 3px;
                }
            }
        }
    }

    #insurance.selectBoxes {
        h3 {
            border-bottom: 1px solid #80808066;
            padding-bottom: 25px;
            font-size: 24px;
            margin-bottom: 40px;
        }

        .boxes {
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0;
            display: flex;

            .boxInputRadio {
                min-height: 200px;
                width: 260px;
                margin-bottom: 50px;
                padding: 0;

                .descriptionBox {
                    padding: 20px;
                    padding-top: 0;

                    p.description {
                        margin-bottom: 0;
                    }

                    ul {
                        margin-left: 0;
                        padding-left: 20px;

                        li {
                            color: grey;
                            margin-left: 0;
                        }
                    }
                }

                p.price {
                    font-size: 25px;
                    text-align: center;
                    line-height: 1;
                    margin: auto;
                    padding: 0;
                    font-family: "SpaceGrotesk-Medium";
                }

                p.price.include {
                    // height: 50px;
                    // margin: auto;
                    // margin-bottom: 30px;
                    text-align: left;
                }

                .smallText {
                    font-size: 14px;
                    text-align: center;
                    margin: 0;
                    display: block;
                }

                .postscript {
                    margin: 0;
                    font-size: 12px;
                    font-family: "SpaceGrotesk-SemiBold";
                    text-align: center;
                }

                p.titleText {
                    margin-top: 19px;
                    margin-bottom: 15px;
                    font-size: 17px;
                    font-family: "SpaceGrotesk-Medium";
                }

                .recomended {
                    background-color: #ffd15f;
                    padding: 20px;
                    padding-bottom: 10px;
                    margin-left: -1px;

                    p.price {
                        font-size: 25px;
                        text-align: center;
                        line-height: 1;
                        margin: auto;
                        padding: 0;
                        font-family: "SpaceGrotesk-Medium";
                    }

                    .smallText {
                        font-size: 14px;
                        text-align: center;
                        margin: 0;
                        margin-bottom: 5px;
                    }
                }

                .normal {
                    padding-bottom: 30px;

                    p.price {
                        font-size: 25px;
                        text-align: center;
                        line-height: 1;
                        margin: auto;
                        padding: 0;
                        font-family: "SpaceGrotesk-Medium";
                    }

                    .smallText {
                        font-size: 14px;
                        text-align: center;
                        margin: 0;
                        margin-bottom: 5px;
                    }
                }

                span.checkmarkRadio {
                    top: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #stepThree {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;

        .priceBoxInsurance {
            margin: 0;
            min-height: fit-content;
        }

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;
            margin-top: 50px;

            .name {
                width: 100%;
                margin: 0;
                margin-top: 50px;

                .containerAddName {}

                p {}
            }

            #secondPerson,
            #thirdPerson {}

            .secondaryBtn {}

            .redBox {
                margin-bottom: 40px;
                a {}
            }

            .selectRadio {
                h3 {
                    flex-direction: column;
                    text-align: left;
                    align-items: flex-start;
                    justify-content: start;

                    button {
                        margin-top: 20px;
                        text-align: center;
                    }
                }

                .container {
                    flex-wrap: wrap;

                    .textBox {
                        width: 100%;

                        p {
                            margin-right: 0;

                            span {}

                            a {}
                        }

                        form {
                            .containerInputRadio {
                                p {
                                    &:first-child {}

                                    &:nth-child(2) {}
                                }
                            }
                        }
                    }

                    .greenBox {
                        p {}
                    }
                }

                .containerInputCheckbox {
                    a {}
                }
            }

            p.warningText {}

            .containerSelect {
                h4 {}

                p {}

                select {
                    &[name="town"] {}
                }

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}
                }
            }
        }

        #insurance.selectBoxes {
            h3 {}

            .boxes {
                flex-wrap: wrap;
                overflow: auto;
                margin-top: 50px;

                .boxInputRadio {
                    width: 100%;
                    min-height: fit-content;
                    margin-bottom: 25px;

                    p.description {
                        margin-bottom: 7px;
                    }

                    .descriptionBox {

                        padding: 0;
                        padding-left: 50px;

                        ul {
                            li {}
                        }
                    }

                    p.price {}

                    p.price.include {
                        margin-left: 50px;
                        margin-bottom: 15px;
                    }

                    .smallText {}

                    .postscript {
                        text-align: left;
                    }

                    p.titleText {
                        width: min-content;
                        margin-bottom: 5px;
                        margin-top: 10px;
                        margin-left: 0;
                    }

                    .recomended {
                        padding-left: 50px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        p.price {
                            text-align: left;
                        }

                        .smallText {
                            margin-left: 5px;
                            display: inline;
                        }
                    }

                    .normal {
                        p.price {}

                        .smallText {}
                    }

                    span.checkmarkRadio {}
                }
            }

            p.warningText {
                width: auto;
            }
        }

        .containerButtons {
            display: flex;
            justify-content: center;

            .secondaryBtn {
                min-width: 130px;
                margin: auto;

            }

            .primaryBtn {
                min-width: 130px;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    #stepThree {
        overflow-x: hidden;

        h3 {}

        .container {
            .name {
                .containerAddName {}

                p {}
            }

            #secondPerson,
            #thirdPerson {}

            .secondaryBtn {}

            .redBox {
                a {}
            }

            .selectRadio {
                .container {
                    .textBox {
                        p {
                            span {}

                            a {}
                        }

                        form {
                            .containerInputRadio {
                                p {
                                    &:first-child {}

                                    &:nth-child(2) {}
                                }
                            }
                        }
                    }

                    .greenBox {
                        p {}
                    }
                }

                .containerInputCheckbox {
                    a {}
                }
            }

            p.warningText {}

            .containerSelect {
                h4 {}

                p {}

                select {
                    &[name="town"] {}
                }

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}
                }
            }
        }

        #insurance.selectBoxes {
            h3 {}

            .boxes {

                .boxInputRadio {
                    .descriptionBox {
                        ul {
                            li {}
                        }
                    }

                    p.price {}

                    p.price.include {}

                    .smallText {}

                    .postscript {
                        text-align: center;
                    }

                    p.titleText {}

                    .recomended {
                            padding: 10px;
                        p.price {
                            text-align: center;
                        }

                        .smallText {}
                    }

                    .normal {
                        p.price {}

                        .smallText {}
                    }

                    span.checkmarkRadio {}
                }
            }
        }
    }
}