#howItWorksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 40px 0;

    .howItWorksTitle {
        padding: 0 15px;
        text-align: center;

        h2 {
            margin: 0;
        }
    }

    .howItWorksWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1000px;
        margin: auto;
    }

    .buttonMore {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 768px) {
    #howItWorksWrapper {
        .howItWorksWrapper {
            max-width: 100%;
            padding: 0 15px;
        }
    }
}
