#messages {
    margin-top: 50px;
    width: 100%;

    .attachmentWrapper {
        display: flex;
        margin: 20px 0;
        justify-content: space-between;
        button {
            margin: 0;
            justify-content: center;
            padding: 10px;
        }
    }

    .mobileArray {
        display: none
    }

    .desktopArray {
        display: block
    }

    tbody {
        width: 100%;
        display: block;
    }

    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    /* START Messages table */

    .messagesTable {
        width: 100%;
        height: 100%;
        border-spacing: 0;

        .tableHead {
            background-color: #e4f4fb;
            border-radius: 8px 8px 0 0;
            border-bottom: 0;
            max-height: 80px;
        }

        /* NAGŁÓWKI */

        th {
            padding: 30px 30px 30px 30px;
            color: #1d313e;
            text-align: left;
            font-weight: 600;

            span {
                font-family: "SpaceGrotesk-Semibold";
            }

            &.led {
                width: 0%;
                padding: 0;
                padding-left: 10px;
            }

            &.subject {
                width: 80%;
            }

            &.sender {
                width: 10%;
                min-width: 10%;
            }

            &.date {
                width: 20%;
            }
        }

        /* KOMÓRKI */
        td {
            padding: 20px 30px 20px 30px;
            cursor: pointer;

            &.led {
                width: 0%;
                padding: 0;
                padding-left: 10px;
            }

            &.subject {
                width: 80%;
            }

            &.sender {
                width: 10%;
                min-width: 10%;
                display: flex;

                div.senderBox {
                    display: flex;
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: -20px;
                }
            }

            &.date {
                width: 20%;
            }

            span {
                font-family: "SpaceGrotesk-Medium";
            }

            input {
                height: 30px;
                border-radius: 7px;
                font-size: 16px;
                font-family: "SpaceGrotesk";
                box-sizing: border-box;
                padding: 23px 15px;

                &:focus {
                    outline: #326bc8 auto 1px;
                    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
                }
            }
        }

        /* WIERSZE */
        tr {
            // border-bottom: 1px solid #80808012;
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14.5px;
        }

        /*IKONA POWIADOMIENIA */

        .led-red,
        .led-box {
            padding: 0 !important;
            margin-left: 7px;
            margin-top: 7px;
            min-width: 6px;
            padding-left: 10px;
        }


        /*NAZWA WIADOMOŚCI*/
        .titleMessages {
            padding: 5px;
            background-color: #d3d3d359;
            border-radius: 5px;
            width: max-content;
            margin-top: 5px;
            font-size: 13px;
        }
    }

    h4 {
        margin-left: 10px;
    }

    .accordion {
        border-radius: 0;
        border: none;

        &__panel {
            background-color: white;
            font-size: 14px;
            padding-left: 0;
            color: #4f4f4fc9;
            padding-bottom: 10px;
            line-height: 1.5;
            padding-top: 0;
            padding-left: 32px;
            padding-bottom: 20px;
            white-space: break-spaces;
        }

        &__button {
            width: auto;
            background-color: white;
            font-size: 14px;
            outline: none;
            color: black;
            padding: 18px 0;
            font-family: "SpaceGrotesk-Medium";
            padding: 0;
            // margin-top: -65px;

            &:hover {
                background-color: white;
            }

            &:after {
                content: "";
                height: 5px;
                width: 5px;
                margin-right: 12px;
                border-bottom: 2px solid currentColor;
                border-right: 2px solid currentColor;
                float: right;
                transform: rotate(-315deg);
                margin-top: -65px;
            }

            &:before {
                display: none;
            }
        }
    }

    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        transform: rotate(225deg);
    }

    .accordion__item+.accordion__item {
        border-top: none;
        position: relative
    }

    .rwt__tab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 20px !important;
        cursor: pointer;
        font-weight: 500;
        font-family: "SpaceGrotesk-Medium";
    }

    .rwt__tabs[data-rwt-vertical="true"] {
        display: flex;
        height: auto;
        margin: auto;
        justify-content: space-between;
        width: 70%;
    }

    .rwt__tablist[aria-orientation="vertical"] {
        width: 300px;
        margin-top: 40px;
        height: fit-content;
        border-right: none !important;
        background: white;
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-right: 30px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }

    #vertical-tab {

        &-one,
        &-two,
        &-three,
        &-four,
        &-five,
        &-six,
        &-seven {
            background-color: transparent;
            min-height: 700px;
            padding: 0;
            margin-top: 250px;
        }
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: none;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    i {
        margin-left: 10px;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
        right: 0 !important;
        border-right: none !important;
        background: #8080801f;
        width: 100%;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]>.arrow {
        display: inline-block;
        margin-left: auto;
        margin-top: 6px;
    }
}

@media only screen and (max-width: 996px) {
    #messages {
        margin-top: 50px;
        width: 100%;
        margin: auto;

        h2 {
            margin-bottom: 60px;
            margin-top: 40px;
        }

        button {
            display: flex;
            margin: auto;
            margin-top: 47px;
        }
    }
}

@media (max-width: 30em) {
    #messages {
        .attachmentWrapper {
            flex-direction: column;
        }
.senderBox {
    padding-left: 5px;
}

.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
        .accordion__panel {
            border-radius: 0 0 10px 10px;
            padding: 10px;
            box-sizing: border-box;
        }

        .accordion__button {
            border-radius: 10px;

            &:after {
                margin-top: -30px;
            }
        }


        .accordion__item {
            border-top: none;
            position: relative;
            box-shadow: 3px 3px 26px 2px #adadad7d;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .mobileArray {
            display: block
        }

        .desktopArray {
            display: none
        }

        // .accordion__panel {
        //     position: absolute;
        //     background: white;
        //     top: 0;
        //     z-index: 1000;
        //     width: 100%;
        //     height: 100%;
        //     border: 1px solid grey;
        //     padding: 10px;
        //     box-sizing: border-box;
        // }
        .messagesTable {
            .tableHead {
                position: absolute;
                top: -9999em;
                left: -9999em;
            }

            td {
                padding-left: 50%;
                display: block;
                width: auto;
                border: none;

                &:before {
                    content: attr(data-label);
                    display: inline-block;
                    font-weight: bold;
                    line-height: 1.5;
                    margin-left: -100%;
                    width: auto;
                }
            }

            td,
            th {

                &.led,
                &.subject,
                &.sender,
                &.date {
                    width: auto !important;
                    box-sizing: border-box;
                    display: inline-flex;
                }
            }

            td.subject div.subjectBox {
                display: contents;
            }

            tr {
                display: block;
                width: auto;
                border-radius: 10px;
                // margin-bottom: 20px;
                border-bottom: 0;
                // box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 33%);
                padding-left: 0;
            }

            .titleMessages {
                max-width: 150px;
            }
        }
    }
}

@media (max-width: 25em) {
    #messages {



        .messagesTable {
            td {
                padding-left: 0.75em;

                &.subject {
                    width: 100% !important;
                }

                &.sender {
                    display: block;
                }

                &:before {
                    display: block;
                    margin-bottom: 0.75em;
                    margin-left: 0;
                }
            }
        }
    }
}