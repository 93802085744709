#programTypes {
    padding-top: 85px;
    min-height: 1000px;
    padding-bottom: 100px;
    background-color: white;
    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2, h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            min-width: 250px;
            text-align: center;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }

    #menuPage {
        background-color: #123852fc;
        height: auto;
        width: 100%;
        position: sticky;
        top: 0;
        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
        z-index: 2;
        padding: 10px;

        .container {
            display: flex;
            justify-content: space-between;
            width: 700px;
            margin: auto;
            padding: 10px 0;

            a {
                list-style-type: none;
                color: white;
                font-size: 16px;
                cursor: pointer;
                padding: 10px;

                &.active {
                    color: #123852fc;
                    background: #eefdf6;
                    outline: none;
                    border-radius: 10px;
                    font-family: "SpaceGrotesk-SemiBold";
                }
            }

         
        }
       
    }
}

@media only screen and (max-width: 767px) {
    #programTypes {
        padding-top: 70px;
        .slider {
            h2, h1 {
            }

            img {
            }
        }

        #menuPage {
            width: auto;
            .container {
                flex-direction: column;
                padding: 0;
                max-width: 90vw;
                margin-left: 0;
                margin-right: 0;
                margin: auto;
                a {
                    &.active {
                    }
                }
            }
        }
    }
}
