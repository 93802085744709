#stepFive {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px 90px;
    .lds-roller {
        top: 100%;
    }

    a {
        cursor: pointer;
        strong {
            cursor: pointer;
        }
    }


    select {
        margin-top: 0;
        width: 100%;
        padding: 13px;
        margin-bottom: 20px;
    }

    .passwordBox {
        position: relative;

        input::placeholder {
            // font-size: 13px;
        }

        span {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
            i {
                cursor: pointer;
            }

        }
    }

    .colorInputRed {
        border: 2px solid #b30c0c;
        outline: none;
    }


    #colorRed {
        color: #b30c0c
    }

    #colorInputRed {
        border: 2px solid #b30c0c;
        outline: none;
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;

        .onePerson {
            margin-bottom: 50px;

            .name {
                padding: 0 30px;
                background-color: #80808021;
                border-radius: 5px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: -30px;
                height: 85px;

                p {
                    font-size: 25px;
                    margin: 0;
                    font-family: "SpaceGrotesk-Bold";
                }
            }

            .containerSelect {
                width: 550px;
                margin-bottom: 30px;

                h4 {
                    font-size: 17px;
                    font-family: "SpaceGrotesk-SemiBold";
                    margin-bottom: 10px;
                }

                p {
                    font-size: 15px;
                    color: #3c3a3a;
                    cursor: pointer;
                    margin-top: 0;
                }

                select {
                    width: 100%;
                    padding: 12px;
                    background-size: 11px;
                    float: none;
                    margin-top: 10px;

                    &[name="town"] {
                        margin-top: 20px;
                    }
                }

                .containerInputCheckbox {
                    margin-bottom: 30px;
                    margin-top: 30px;

                    p {
                        font-family: "SpaceGrotesk-Medium";
                        color: black;
                        font-size: 18px;
                        margin-top: 0;
                        margin-bottom: 0;

                        span {
                            font-size: 15px;
                            color: #3c3a3a;
                            display: block;
                            margin-top: 5px;
                        }
                    }

                    span.checkmark {
                        top: 3px;
                    }

                    input:checked~.checkmark {
                        background-color: white;
                        top: 3px;
                    }
                }
            }
        }

        .participantDetails {
            width: 80%;

            h3 {
                border-bottom: 1px solid #80808066;
                padding-bottom: 25px;
                font-size: 26px;
            }

            /* START FORM */

            .containerInputRadio .inputRadio {
                font-size: 16px;

                span.checkmark {
                    top: 6px;
                }
            }
        }

        .greenBox {
            width: 450px;

            p.infoTitle {}

            .benefits {
                display: flex;
                align-items: center;
                margin: 20px 0;
                p {
                    margin-bottom: 0;
                }

                .iconTick {
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: white;

                    p {
                        margin: 0;
                        text-align: center;
                        line-height: 2;
                        color: #00800099;
                        font-weight: 600;
                    }
                }

                p {
                    margin-top: 0;
                    margin-left: 10px;
                    font-family: "SpaceGrotesk-Medium";
                    letter-spacing: 0.3px;
                }
            }

            p.addText {
                font-size: 14px;
                color: #171515db;
                line-height: 1.7;
            }
        }

        .agreements {
            width: 550px;
            margin-bottom: 50px;

            .containerInputCheckbox {
                margin-bottom: 20px;
                display: flex;

                &.allAgreements {
                    margin-bottom: 40px;
                }

                &.mail {
                    margin-left: 30px;
                }

                &.phone {
                    margin-left: 30px;
                }
            }

            div:nth-child(5),
            div:nth-child(6) {
                margin-left: 50px;
            }

            p.textSmall {
                margin: 0;
            }
        }
    }

    form {
        width: 450px;

        label,
        p {
            text-align: left;
            margin-bottom: 10px;
            margin-top: 15px;
            font-family: "SpaceGrotesk-Bold";
            font-size: 15px;
        }

        .containerInputRadio {
            height: auto;

            .inputRadio {
                margin: 0;

                input {}

                span.checkmark {
                    &:after {}

                    &:after {}
                }
            }
        }

        .secondaryBtn {
            margin-top: 40px;
        }
    }

    .secondaryBtn {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 767px) {
    #stepFive {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;
            margin-top: 50px;

            .onePerson {
                .name {
                    width: 100%;
                    margin: 0;
                    margin-top: 50px;

                    p {}
                }

                .containerSelect {
                    h4 {}

                    p {}

                    select {
                        &[name="town"] {}
                    }

                    .containerInputCheckbox {
                        p {
                            span {}
                        }

                        span.checkmark {}

                        input:checked~.checkmark {}
                    }
                }
            }

            .participantDetails {
                h3 {}

                /* START FORM */

                .containerInputRadio .inputRadio {
                    span.checkmark {}
                }
            }

            .greenBox {
                p.infoTitle {}

                .benefits {
                    .iconTick {
                        p {}
                    }

                    p {}
                }

                p.addText {}
            }

            .agreements {
                .containerInputCheckbox {
                    &.allAgreements {}

                    &.mail {}

                    &.phone {}
                }

                p.textSmall {}
            }
        }

        form {
            width: auto;

            label,
            p {}

            .containerInputRadio {
                .inputRadio {
                    input {}

                    span.checkmark {
                        &:after {}

                        &:after {}
                    }
                }
            }

            .secondaryBtn {}
        }

        .containerButtons {
            display: flex;
            justify-content: center;
           
            .secondaryBtn {
                min-width: 130px;
                margin: auto;
                
            }
            .primaryBtn {
                min-width: 130px;
                margin: auto;
            }
        }

        .passwordBox {
           

            input::placeholder {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    #stepFive {
        .container {
            .onePerson {
                .name {
                    p {}
                }

                .containerSelect {
                    h4 {}

                    p {}

                    select {
                        &[name="town"] {}
                    }

                    .containerInputCheckbox {
                        p {
                            span {}
                        }

                        span.checkmark {}

                        input:checked~.checkmark {}
                    }
                }
            }

            .participantDetails {
                width: auto;

                h3 {}

                /* START FORM */

                .containerInputRadio .inputRadio {
                    span.checkmark {}
                }
            }

            .greenBox {
                width: auto;

                p.infoTitle {}

                .benefits {
                    .iconTick {
                        p {}
                    }

                    p {}
                }

                p.addText {}
            }

            .agreements {
                width: auto;

                .containerInputCheckbox {
                    &.allAgreements {}

                    &.mail {}

                    &.phone {}
                }

                p.textSmall {}
            }
        }

        form {

            label,
            p {}

            .containerInputRadio {
                .inputRadio {
                    input {}

                    span.checkmark {
                        &:after {}

                        &:after {}
                    }
                }
            }

            .secondaryBtn {}
        }

        .secondaryBtn {}
    }
}