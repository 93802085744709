#reset {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 550px;
        box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.19);
        height: auto;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        background-color: white;
        margin-top: 150px;
        margin-bottom: 100px;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        max-width: 90vw;

        .firstPart {
            width: 100%;
            display: flex;
            justify-content: center;
            height: auto;
            border-bottom: 1px solid #eaeaea;

            .box {
                width: 400px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-bottom: 10px;
                max-width: 90vw;

                h3 {
                    text-align: center;
                    margin: 10px 0 15px;
                }

                p {
                    text-align: center;
                    max-width: 370px;
                }

                .form {
                    width: 380px;
                    text-align: center;

                    i {
                        font-size: 18px;
                        cursor: pointer;
                    }

                    form {
                        .passwordBox {
                            position: relative;

                            span {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        p {
                            text-align: left;
                            margin-bottom: 5px;
                            margin-top: 10px;
                            font-size: 15px;
                        }

                        input[type="email"] {
                            width: 100%;
                            padding: 23px 15px;
                            margin: 0;
                            height: 35px;
                            border-radius: 5px;
                            border: 1px solid #eaeaea;
                            padding-right: 0;
                            font-size: 17px;
                            box-sizing: border-box;
                        }

                        input[type="email"]::-webkit-input-placeholder {
                            font-size: 16px;
                            color: #808080a6;
                        }

                        input[type="email"]:focus {
                            outline: grey auto 1px;
                        }

                        .linkPassword {
                            text-align: left;
                            margin: 30px 0;
                            text-align: center;

                            span {
                                text-decoration: none;
                                font-size: 15px;
                                font-family: SpaceGrotesk-SemiBold;
                                color: inherit;
                                cursor: pointer;

                                i.arrow {
                                    border: solid black;
                                    border-width: 0 3px 3px 0;
                                    display: inline-block;
                                    padding: 2.6px;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    margin-left: 4px;
                                }
                            }
                        }
                    }

                    .primaryBtn {
                        margin-top: 40px;
                    }
                }
            }
        }

        .btnBox2 {
            margin-top: 40px;
            text-align: center;

            .secondaryBtn {
                width: 200px;
            }
        }
    }

    input[name="token"],
    input[name="newPassword"] {
        margin-top: 20px;
        max-width: 85vw;
    }

    .primaryBtn.send {
        margin-top: 30px;
    }

    .trueMessage {
        margin-top: 20px;
        text-align: center;
    }

    .errorMessage {
        margin-top: -20px;
        font-size: 13px;
    }
}

@media only screen and (max-width: 480px) {
    #reset .container .firstPart .box {
        p {
            padding: 0 15px;
        }

        .form {
            width: 270px;
            margin: auto;

            form {
                input[type="email"] {
                    width: 100%;
                }
            }
        }
    }
}
