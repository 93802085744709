#contactPage {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 500px;
    padding-top: 70px;
    background-color: transparent;

    .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        max-width: 100vw;
        margin-top: 0;
        flex-wrap: wrap;

        .wrapperInfo {
            width: 45%;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;
            padding-top: 70px;
            padding-left: 70px;
            box-sizing: border-box;

            .box {
                min-width: 320px;

                h1 {
                    font-size: 30px;
                    font-weight: 500;
                    margin-top: 0;
                    margin-bottom: 30px;
                }

                p {
                    margin: 0;
                }

                .adress,
                .openingHours {
                    margin-top: 25px;
                }

                .secondaryBtn {
                    margin: 30px 0;
                }
            }
            .partnersInfo {
                p {
                    margin-top: 10px;
                    a {
                        text-decoration: none;
                        font-size: 14px;
                        font-family: "SpaceGrotesk-Medium";

                        i.arrow {
                            border: solid #000000e8;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 2.5px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            margin-left: 6px;
                        }
                    }
                }
            }
        }

        .wrapperForm {
            padding-top: 70px;
            background-color: white;
            width: 55%;
            padding-left: 70px;
            box-sizing: border-box;

            h1 {
                font-size: 30px;
                font-weight: 500;
                margin-top: 0;
                margin-bottom: 20px;
            }
            .form {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                width: auto;

                form {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    ::placeholder {
                        color: lightgray;
                    }

                    label {
                        margin-bottom: 7px;
                        font-size: 15px;
                        margin-top: 10px;
                    }
                    input,
                    textarea {
                        margin-bottom: 10px;
                        border-radius: 5px;
                        border: 1px solid lightgrey;
                        font-size: 15px;
                        padding: 20px 15px;

                        ::placeholder {
                            color: lightgrey;
                        }
                    }

                    textarea {
                        padding: 12px 15px;
                    }

                    input:focus,
                    textarea:focus {
                        border: 1px solid lightgrey;
                        outline: 1px;
                        box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
                    }

                    button {
                        width: 200px;
                        margin-top: 20px;
                    }

                    button:hover {
                        box-shadow: 2px 3px 33px 3px rgba(184, 178, 184, 0.31);
                        background-color: #ffd15f;
                        border: none;
                    }
                }
            }
        }
    }
    .disclaimer {
        font-size: 12px;
        color: #808080bd;
        font-family: "SpaceGrotesk";
        margin: 15px 0;
    }
}

@media only screen and (max-width: 768px) {
    #contactPage {
        justify-content: center;
        text-align: center;
        margin-bottom: 0;
        .wrapper {
            margin: 0;
            justify-content: center;
            flex-direction: column;
            margin-top: 50px;

            .wrapperInfo {
                box-sizing: border-box;
                padding: 0 0 30px;
                width: 100%;
            }

            .wrapperForm {
                padding: 40px 25px;
                box-sizing: border-box;
                width: 100%;
                .form {
                    max-width: 100%;
                    justify-content: center;
                    form {
                        max-width: 100%;
                        button {
                            margin: 30px auto 0;
                        }
                    }
                }
            }
        }
    }
}
