#footerContainer {
    height: 100%;
    color: white;
    background-color: #123852fc;

    .footerMenu {
        height: 320px;
        width: 1000px;
        margin: auto;
        max-width: 90vw;

        .top {
            height: 50px;
            display: flex;
            justify-content: space-between;
            margin: auto;
            padding-top: 30px;
            align-items: center;

            .imageLogo {
                width: 120px;

                img {
                    width: 100%;
                }
            }

            .socialMediaContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 282px;

                p {
                    color: #dcdbd8;
                    white-space: nowrap;
                }

                .icons {
                    display: flex;
                    justify-content: space-around;
                    width: 100px;

                    i {
                        color: white;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .wrapper {
            display: flex;
            justify-content: flex-start;
            .oneFooterBox {
                width: auto;
                .contactLink {
                    margin-bottom: 0;
                }
                .contactNumber {
                    margin-top: 0;
                }
                &#whatWeDoColumn {
                    width: 19%;
                }
                &#aboutUsColumn {
                    width: 32%;
                }
                &#howItWorksColumn {
                    width: 21%;
                }
                h5 {
                    color: #dcdbd8;
                    font-size: 15px;
                }

                p,
                a {
                    color: white;
                    font-family: sans-serif;
                    letter-spacing: 0.4px;
                    margin-bottom: 14px;
                    text-decoration: none;
                    font-size: 14px;
                }
            }
        }
    }

    .footerContact {
        margin-top: 20px;

        .container {
            width: 1000px;
            margin: auto;
            border-bottom: 1px solid grey;
            max-width: 90vw;
        }

        .pfrContainer {
            text-align: center;
            img {
                max-width: 90%;
            }
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            height: auto;
            padding-top: 20px;
            padding-bottom: 20px;

            .oneContactBox {
                width: 20%;
                line-height: 1.2;

                h5 {
                    color: #dcdbd8;
                    font-size: 15px;
                }

                p {
                    color: white;
                    font-size: 14px;
                    letter-spacing: 0.4px;
                    margin-top: 5px;
                    margin-bottom: 0;
                }
            }
        }

        .footerBottom {
            display: flex;
            justify-content: space-between;
            width: 1000px;
            margin: auto;
            max-width: 90vw;

            .text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 60%;

                p {
                    color: white;
                    margin-right: 10px;
                    font-size: 13px;
                }
            }

            .logo {
                max-width: 90vw;
                width: 300px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .copyRight2 {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #footerContainer {
        .footerMenu {
            height: 120px;
            display: flex;

            .wrapper {
                display: none;
            }

            .top {
                flex-direction: column;
                height: 60px;

                .socialMediaContainer {
                    width: auto;

                    p {
                        display: none;
                    }
                }
            }
        }

        .footerContact {
            .container {
                max-width: 90vw;
            }

            .container > div > div:nth-child(1) > h5 {
                margin-top: 0;
            }

            .wrapper {
                display: flex;
                justify-content: center;
                width: 100%;
                height: auto;
                padding: 30px 0;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .footerBottom {
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 10px;

                .text {
                    width: 63%;
                }

                .logo img {
                    width: 100%;
                }

                .copyRight {
                    display: none;
                }

                .copyRight2 {
                    display: block;
                    color: white;
                    font-size: 13px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    #footerContainer .footerContact .wrapper .oneContactBox {
        width: 100%;
        margin-bottom: 10px;
    }
}

.is-active {
    color: #ffe4a4 !important;
}
