#formalities {
    padding-top: 70px;
    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2, h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            margin: 0;
            font-size: 35px;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }
    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        padding-bottom: 100px;

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .firstSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 100px;
                .textSection {
                    width: 45%;
                    h2, h1 {
                        font-size: 25px;
                        margin-bottom: 25px;
                        margin-top: 0px;
                    }
                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                        a {
                            text-decoration: none;
                            font-family: "SpaceGrotesk-Medium";
                        }
                        i.arrow {
                            border: solid black;
                            border-width: 0 2.3px 2.3px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                            margin-left: 6px;
                            margin-bottom: 2px;
                        }
                    }
                }
                .imageSection {
                    width: 45%;
                    height: 400px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #formalities {
        padding:0;
        .slider {
            h2, h1 {
                top: 60%;
               
            }

            img {
            }
        }
        .info {
            .container {
                box-sizing: border-box;
                .firstSection {
                    padding-top: 50px;
                    .textSection {
                        margin-top: 30px;
                        width: 100%;
                        h2, h1 {
                        }
                        P {
                            a {
                            }
                            i.arrow {
                            }
                        }
                    }
                    .imageSection {
                        width: 100%;
                        height: auto;
                        img {
                        }
                    }
                }
            }
        }
    }
}
