#currentOneTrip {
    width: 100%;
    // min-height: 350px;
    border-radius: 5px;
    box-shadow: 2px 26px 68px -21px #b8b6b8;
    display: flex;
    margin-bottom: 50px;
    height: auto;

    .reservationIdInfo {
        margin-top: 20px;
        background: green;
        width: fit-content;
        border-radius: 5px;
        padding: 1px 6px;
        margin-left: -3px;
        margin-top: 20px !important;
    }

    .photoContainer {
        width: 100%;
        position: relative;
        cursor: pointer;
        min-height: 250px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            cursor: pointer;
            // filter: brightness(0.4);
        }

        h4 {
            position: absolute;
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
            color: white;
            width: 100%;
            font-weight: 500;
        }
    }

    .photoContainer.reservation {
        width: 30%;
    }

    .tripInfo {
        cursor: pointer;

        h3 {
            margin-top: 3px;
            cursor: pointer;
            max-width: 500px;
            color: white;
        }

        .infoTripBox {
            cursor: pointer;

            .reservationStatus {
                border-radius: 5px;
                padding: 4px;
                width: fit-content;
                margin-left: -4px;
                background: #e4f4fb;
                padding: 5px 12px;

                p {
                    margin: 0;
                    font-family: "SpaceGrotesk-SemiBold";
                    color: #011625fc;
                    font-size: 16px;
                }
            }

            .far {
                font-size: 20px;
                margin-right: 12px;
            }

            i {
                margin-left: 2px;
            }

            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }
            }

            .dataBox {
                display: flex;

                .confirmedTrip {
                    color: #42ad12;
                    font-size: 15px;
                    font-weight: 600;
                    margin-left: 10px;

                    span {
                        margin-right: 5px;
                        color: #42ad12;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }

        .optionsBox {
            margin-top: 30px;
            margin-bottom: 30px;

            p {
                margin-top: 20px;
                font-size: 15px;
                font-family: "SpaceGrotesk-SemiBold";
            }
        }

        .additionalOptions {
            color: grey;

            p,
            span {
                font-size: 14px;
                color: grey;
                margin: 0;
            }
        }
    }

    .tripInfo.reservation {
        width: auto;
    }

    button {
        margin-top: 30px;
        margin-bottom: 0;
        height: auto;
        padding: 10px 20px;
        width: auto;
        color: #000000;
        font-size: 16px;
        border: none;
        font-family: "SpaceGrotesk-Medium";
    }
}

#currentOneTrip {
    border: 1px solid #80808038;
    border-radius: 10px;
    position: relative;
    height: 270px;

    .hidden {
        display: none;
    }

    .photoContainer {
        width: 100%;
        position: relative;
        cursor: pointer;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100px;
            /* background: linear-gradient(180deg, #000000b3, transparent); */
            border-radius: 10px;
            cursor: pointer;
            background: linear-gradient(180deg, rgba(142, 158, 173, 0.7) 0%, #00010194 0%, rgba(57, 87, 100, 0.04) 96%);
            width: 100%;
            height: 100%;
        }
    }

    .tripInfo {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0%, 0%);
        color: white;
        cursor: pointer;
        height: 100%;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;

        .infoTripBox {
            p {
                font-size: 16px;
                color: white;
                font-family: "SpaceGrotesk-Medium";
                display: flex;
                align-items: center;
                cursor: pointer;

                .confirmedTrip {
                    color: #42ad12;
                    font-size: 15px;
                    font-weight: 600;
                }

                img {
                    height: 18px;
                    filter: none;

                    &:first-child {
                        height: 20px;
                        filter: initial;
                        border-radius: 0;
                    }
                }
            }
        }

        .shortInfo {
            width: auto;
            padding-right: 50px;
            line-height: 1.4;
        }
    }

    .priceSection {
        width: auto;
        cursor: pointer;

        .priceBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .discount {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                cursor: pointer;

                .newPrice,
                .text {
                    color: #dc2b2f;
                    font-size: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-family: "SpaceGrotesk-Medium";
                    margin: 0;
                    filter: drop-shadow(2px 4px 6px black);
                }

                .text {
                    font-size: 15px;
                    font-family: "SpaceGrotesk";
                    cursor: pointer;
                }
            }

            .price {
                color: white;
                filter: drop-shadow(2px 4px 6px black);
                font-size: 27px;
                margin: 0;
                cursor: pointer;
            }

            .text {
                color: white;
                filter: drop-shadow(2px 4px 6px black);
                margin: 0;
                font-size: 13px;
                cursor: pointer;
            }

            .regularPrice {
                text-align: center;

                .price {
                    font-size: 30px;
                    text-decoration: none;
                    font-family: "SpaceGrotesk-Medium";
                    margin: 0;
                    color: #1c1423;
                    cursor: pointer;
                }

                .text {
                    color: #615555;
                    filter: drop-shadow(2px 4px 6px black);
                    margin: 0;
                    font-size: 13px;
                    cursor: pointer;
                }
            }
        }
    }

    .detailsBox {
        text-align: center;
        cursor: pointer;
        height: 140px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .numberReservation {
            background: #e4f4fb;
            border-radius: 5px;
            padding: 5px 10px;
            margin-top: 10px;

            p {
                margin: 0;
                font-family: "SpaceGrotesk-SemiBold";
            }
        }

        .amountOfPeople {
            margin-bottom: 10px;
            color: white;
            filter: drop-shadow(2px 4px 6px black);
        }

        .availability {
            font-family: "SpaceGrotesk-SemiBold";
            margin-top: 5px;
            color: white;
            filter: drop-shadow(2px 4px 6px black);

            &.red {
                color: #dc2b2f;
            }
        }

        .primaryBtn {
            max-height: 50px;
            // min-width: 100px;
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
            width: max-content;
        }
    }

    .tagsBox {
        position: absolute;
        width: auto;
        bottom: 15px;
        left: 5%;

        .oneTag {
            font-family: "SpaceGrotesk-Medium";
            border-radius: 5px;
            padding: 10px;
            height: auto;
            width: auto;
            font-size: 14px;
            align-items: center;
            background-color: white;
            display: flex;

            img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    #currentOneTrip {
        height: auto;
        max-width: 400px;
        margin-right: 0;
        margin: auto;
        margin-bottom: 50px;
        flex-wrap: wrap;

        .photoContainer {
            width: 100% !important;
            cursor: pointer;

            .overlay {
                border-radius: 10px;
                cursor: pointer;
            }

            img {
                border-radius: 10px;
                cursor: pointer;
            }
        }

        .tripInfo {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;

            .infoTripBox {
                p {
                    margin: auto;

                    .confirmedTrip {
                        display: block;
                        margin-top: 10px;
                        margin-left: 0;
                    }
                }
            }

            .tagsBox {
                padding-right: 0;

                .oneTag {
                    margin-right: 0;
                }
            }

            .shortInfo {
                padding-right: 0;
            }
        }

        .priceSection {
            width: 100%;
            top: 50%;
            height: auto;
            right: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    #currentOneTrip {
        display: flex;
        flex-direction: column;

        .detailsBox {
            height: 100px;
            align-items: center;
        }

        .photoContainer {
            width: 100%;
            height: 30%;

            img {
                border-radius: 10px;
            }
        }

        .tripInfo .infoTripBox .dataBox {
            flex-direction: column;

            .confirmedTrip {
                margin-left: 5px;

                span {
                    margin-right: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #currentOneTrip {
        height: 500px;
        min-height: 400px;

        .tagsBox {
            position: absolute;
            width: auto;
            bottom: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 150px;
        }

        .photoContainer {
            width: 100% !important;
            height: 200px;
            height: 100%;

            .overlay {
                border-radius: 10px 10px 0 0;
            }

            img {
                border-radius: 10px;
            }
        }

        .tripInfo {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                text-align: center;
                width: 100%;
                margin: auto;
            }

            .infoTripBox {
                div {
                    margin: auto !important;
                    margin-top: 20px !important;

                    p {
                        width: 100%;
                    }
                }

                p {
                    .confirmedTrip {
                        display: block;
                        margin-top: 10px;
                        margin-left: 0;
                    }
                }
            }

            .tagsBox {
                padding-right: 0;

                .oneTag {
                    margin-right: 0;
                }
            }

            .shortInfo {
                padding-right: 0;
            }
        }

        .priceSection {
            width: 100%;
            top: 55%;

            .priceBox {
                padding-bottom: 10px;
            }
        }
    }
}
