#questions {
    width: 100%;
    background-color: #eefdf6;
    padding: 10px 0;

    h2 {
        margin: 30px 0;
        text-align: center;
        font-size: 25px;
    }

    .panelBox {
        width: 650px;
        margin: 40px auto 10px;
        max-width: 90vw;
        height: auto;
        background-color: white;
        padding: 25px;
        border-radius: 5px;
        padding-top: 15px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }

    .accordion {
        border-radius: 0;
        border: none;

        &__heading {
            border-bottom: 1px solid #80808038;
        }

        &__panel {
            // animation: none;
            background-color: white;
            font-size: 14px;
            padding-left: 0;
            color: #4f4f4fc9;
            padding-bottom: 10px;
            line-height: 1.5;
            padding-top: 20px;
        }

        &__button {
            width: auto;
            background-color: white;
            font-size: 14px;
            outline: none;
            color: black;
            padding: 18px 0;
            font-family: "SpaceGrotesk-Medium";
            &:hover {
                background-color: white;
            }

            &:after {
                content: "";
                height: 5px;
                width: 5px;
                margin-right: 12px;
                border-bottom: 2px solid currentColor;
                border-right: 2px solid currentColor;
                float: right;
                transform: rotate(-315deg);
            }

            &:before {
                display: none;
            }
        }
    }

    .accordion__button[aria-expanded="true"]::after,
    .accordion__button[aria-selected="true"]::after {
        transform: rotate(225deg);
    }

    .accordion__item + .accordion__item {
        border-top: none;
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: none;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    i {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    #questions {
        padding-bottom: 40px;
        .panelBox {
            width: 100%;
            box-sizing: border-box;
            width: auto;
            .accordion {
                &__button {
                    width: auto;

                    &:after {
                        margin-right: 0;
                    }
                }
            }

            .right {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                border: transparent;
            }
        }

        .accordion__button:after {
            display: inline-block;
            float: none;
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
}
