.discounts {
    background-color: white;
    width: 100%;
    min-height: 800px;
    padding: 100px 0;

    h1 {
        font-size: 25px;
    }

    #promotions {
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;

        .content {
            flex-direction: column;
            display: flex;
            margin: auto;
            max-width: 90vw;
            margin-top: 50px;
            margin-bottom: -70px;

            .mainText {
                align-items: center;
                display: flex;
                flex-direction: column;
                max-width: 90vw;
                margin: auto;

                h2 {
                    margin: 0;
                }

                p {
                    text-align: center;
                    margin: 30px auto;
                }

                .mainText {
                    margin-top: 20px;
                    text-align: center;
                    margin-bottom: 0;
                    width: 600px;
                    max-width: 90vw;
                }
            }

            .wrapper {
                display: flex;
                justify-content: space-evenly;
                width: 1100px;
                margin: auto;
                max-width: 90vw;
                margin-top: 50px;
                flex-wrap: wrap;

                .oneDiscountBox {
                    width: 350px;
                    height: 350px;
                    background: transparent;
                    border-radius: 12px;
                    border: 1px solid lightgrey;
                    // box-shadow: 10px 10px 72px 23px rgba(0, 0, 0, 0.2);
                    display: flex;
                    position: relative;
                    h3 {
                        font-size: 20px;
                    }
                    .image {
                        background-color: #0000006e;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        border-radius: 12px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 12px;
                        }
                    }

                    .overlay {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        border-radius: 10px;

                        &:first-child {
                            background-color: #9804048f;
                        }
                    }

                    h3.name {
                        position: absolute;
                        color: white;
                        font-weight: 500;
                        padding: 30px 0 0 37px;
                        margin: 0;
                        font-size: 23px;
                    }

                    .numberBox {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        position: absolute;
                        background-color: white;
                        right: 0;
                        top: 0;
                        margin: 22px;

                        span.number {
                            position: absolute;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }
                    .primaryBtn {
                        padding: 0;
                        a {
                            display: block;
                            height: 100%;
                            padding: 13px 20px;
                        }
                    }
                }
            }

            .text {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 300px;
                padding: 0 20px;
            }

            .primaryBtn {
                position: absolute;
                bottom: 40px;
                left: 50%;
                transform: translateX(-50%);
            }

            #btnMore {
                position: relative;
                bottom: 0;
                left: 0;
                transform: none;
            }
        }
    }

    #discountsDescription,
    #promotionDescription,
    #partnersDescription {
        width: 1100px;
        background-color: white;
        display: flex;
        align-items: center;
        margin: auto;
        max-width: 90vw;
        margin-bottom: -100px;

        .content {
            flex-direction: column;
            display: flex;
            margin: auto;
            padding-top: 150px;
            max-width: 90vw;

            .mainText {
                align-items: center;
                display: flex;
                flex-direction: column;
                max-width: 90vw;
                margin: auto;

                h2,
                h1 {
                    font-size: 27px;
                    margin: 0;
                }

                p {
                    text-align: center;
                    margin: 30px auto;

                    &.mainText {
                        margin-top: 20px;
                        text-align: center;
                        margin-bottom: 0;
                        width: 600px;
                        max-width: 90vw;
                    }
                }
            }
        }
    }
}

.scrollTotop {
    width: 75px;
    height: 75px;
    position: fixed;
    bottom: 25px;
    left: 20px;
    display: none;
}
.scrollToTop i {
    display: inline-block;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .discounts #discountsDescription .content,
    .discounts #promotionDescription .content,
    .discounts #partnersDescription .content {
        padding-top: 20px;
        .mainText h2 {
            margin: 80px 0 30px;
            }
    }
    .discounts {
        #promotions {
            .content {
                margin-top: 20px;
                padding-bottom: 0;

                .mainText {
                    text-align: center;
                }
               
                .wrapper {
                    .oneDiscountBox {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}
