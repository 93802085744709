#details {
    margin-top: 30px;
    width: 80%;
    padding-left: 30px;

    .trueMessage {
        margin-left: 30px;
    }

    .infoReservation {
        display: flex;
        justify-content: space-between;

        .titleInfo {
            h2 {
                margin: 0;
                padding: 0;
            }
        }
    }

    #liderBox {
        .formContainer {
            display: flex;
            justify-content: space-between;
            width: 400px;

            input {
                width: 200px;
            }
        }
    }

    h3 {
        border-bottom: 1px solid #dddddd94;
        padding-bottom: 20px;
        margin-bottom: 10px;
        font-size: 24px;
        margin-top: 50px;
    }

    .detailsBox,
    .documentsBox {
        width: 600px;
        margin-bottom: 30px;

        td {
            padding: 12px 0;

            &.firstColumn {
                width: 50%;
                font-family: "SpaceGrotesk-SemiBold";
            }

            &.secondColumn {
                width: auto;
                float: right;

                span {
                    cursor: pointer;
                    &.link {
                        color: #8a8a8a;

                        i.arrow {
                            border: solid #8a8a8a;
                            border-width: 0 2px 2px 0;
                        }
                    }

                    i.arrow {
                        border: solid black;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 2.5px;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        margin-left: 4px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .buttons {
        display: flex;

        .primaryBtn {
            width: auto;
        }

        .secondaryBtn {
            margin-left: 20px;
        }
    }
}

.messagesContainer {
    margin-left: 30px;
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    #details {
        width: 100%;
        padding-left: 0;
        .detailsBox,
        .documentsBox {
            display: inline-table;
            width: 100%;

            td {
                &.firstColumn {
                    width: 45%;
                }

                &.secondColumn {
                    width: 100%;
                    text-align: right;
                }
            }
        }

        #liderBox {
            .formContainer {
                display: flex;
                flex-direction: column;
                width: max-content;
                input {
                    margin-bottom: 20px;
                    width: auto;
                }
                button {
                    width: fit-content;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .primaryBtn {
                width: max-content;
            }

            .secondaryBtn {
                width: max-content;
                margin: 20px 0;
            }
        }
    }
}
