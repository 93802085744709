.offerHeaderWrapper {
  background: transparent;
  position: absolute;
  top: 1px;
  padding-top: 50px;
  display: flex;
  width: 100%;
}

.offerHeader {
  color: #ffffff;
  margin-bottom: 10px;
  margin-left: 35px;
}

.offerHeaderFlag {
  height: 30px;
  width: auto;
  margin-right: 5px;
}

.offerHeaderWrapperInfo {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 45px;

  .boxInfoOffer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
}

.offerHeaderCalendar {
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.offerCountryResort {
  color: #ffffff;
  margin: 5px;
}

.offerHeaderDate {
  color: #ffffff;
  margin: 5px;
}

@media only screen and (max-width: 768px) {
  .offerHeaderWrapper {
    width: 100%;
    display: block;
  }

  .offerHeader {
    text-align: center;
    font-size: 35px;
    max-width: 90vw;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .offerHeaderWrapperInfo {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  .offerHeaderCalendar {
    margin-right: 10px;
    margin-left: 5px;
  }

  .offerCountryResort {
    text-align: left;
  }
}
