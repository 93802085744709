#yachtTableSection,
#katamaranTableSection {
  text-align: center;
  margin: auto;
  max-width: 90vw;
  width: 1100px;
  padding-bottom: 30px;
  margin-top: 70px;
  h2 {
    margin-top: 70px;
    font-size: 30px;
  }
  p {
    width: 600px;
    max-width: 90vw;
    margin: auto;
    // color: #123852fc;
    letter-spacing: 0.5px;
    line-height: 1.4;
    font-family: "SpaceGrotesk";
  }
  .column {
    float: left;
    width: 30%;
    padding: 10px;
    height: auto;
    padding: 45px;
    // box-sizing: border-box;
    padding-bottom: 30px;
    padding-top: 30px;
    &:nth-child(2) {
      border-left: 1.5px solid #8080804a;
      border-right: 1.5px solid #8080804a;
    }
    .sectionClass {
      p {
        width: auto;
        font-size: 14.5px;
        font-family: "SpaceGrotesk";
        // color: #3a3333e0;
        letter-spacing: 0.4px;
        line-height: 1.4;
        min-height: 70px;
      }
      h4 {
        text-align: center;
        font-size: 17px;
      }

      .image {
        height: 150px;

        width: auto;
        margin: 25px 0;
        img {
          object-fit: cover;
          box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
          border-radius: 7px;
          width: 100%;
          height: 100%;
        }
      }
    }

  .sectionGeneralInfo {
      p {
        width: auto;
        font-size: 14.5px;
        font-family: "SpaceGrotesk";
        color: #3a3333e0;
        margin: 20px 0;
        letter-spacing: 0.4px;
        line-height: 1.4;
        text-align: left;
min-height: 170px;
      }

      .image {
        height: 150px;

        width: auto;
        margin: 25px 0;
        img {
          object-fit: cover;
          box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
          border-radius: 7px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .sectionSittingRoom {
      h5 {
        text-align: left;
        font-family: "SpaceGrotesk-Medium";
        font-size: 16px;
      }
      p {
        width: auto;
        font-size: 14.5px;
        font-family: "SpaceGrotesk";
        color: #3a3333e0;
        margin: 20px 0;
        letter-spacing: 0.4px;
        line-height: 1.4;
        text-align: left;
      }

      .image {
        height: 150px;
        width: auto;
        margin: 25px 0;
        img {
          object-fit: cover;
          border-radius: 7px;
          width: 100%;
          box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
          height: 100%;
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .primaryBtn {
    margin-top: 60px;
  }
}

#katamaranTableSection .column .sectionGeneralInfo p {
    min-height: 250px;
  }


@media only screen and (max-width: 767px) {
  #yachtTableSection,
  #katamaranTableSection {
 width: auto;

    h2 {
      text-align: left;
    }
    p {
      text-align: left;
      width: 100%!important;
    }
    .column {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
      .sectionClass {
        p {
        }
        h4 {
        }

        .image {
          img {
          }
        }
      }

      .sectionGeneralInfo {
        p {
        }

        .image {
          img {
          }
        }
      }

      .sectionSittingRoom {
        h5 {
        }
        p {
        }

        .image {
          img {
          }
        }
      }
    }

    .row {
      flex-wrap: wrap;
      padding-top: 0;
      &:after {
      }
    }

    .primaryBtn {
      margin-top: 0
    }
  }
}
