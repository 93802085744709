.bookButtonWrapper {
  min-width: 566px;
  background-color: #00000036;
  overflow: hidden;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: "SpaceGrotesk-Medium";
  padding-left: 13px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  box-shadow: inset 2px 2px 10px 0px #000000ad;
  transition-duration: 0.4s;
  border-bottom: 1px solid #4b4b4b91;

  p {
    display: flex;
    justify-content: center;
    margin-right: 13px;
    color: white;
    line-height: 1.2;
    margin: auto;
    cursor: pointer;

    span {
      text-decoration: line-through;
      color: #dc2b2f;
    }
  }

  button {
    margin: 0px;
    transition-duration: 0.3s;

    &:hover {
      background: #f2b00b;
    }
  }
}

.bookButtonWrapper:hover {
  background-color: #ffffff08;

  .primaryBtn {
    background: #f2b00b;
  }
}

@media only screen and (max-width: 768px) {
  .bookButtonWrapper {
    background-color: transparent;
    justify-content: center;
    width: 100%;
    border-bottom: 0;
    box-shadow: none;

    button {
      &:hover {
        box-shadow: none;
      }
    }

    p {
      display: none;
    }
  }
}
