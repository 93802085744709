#offerLiveOnYachWrapper {
    background: #fff;
    padding: 50px 0;

    .offerLiveOnYachTitle {
        text-align: center;
        padding: 0 15px;
        margin-bottom: 50px;

        h2 {
            margin: 0;
        }
    }

    .slick-slider {
        max-width: 1600px;
        width: 100%;
        margin: auto;

        .slick-prev {
            left: -2px;
        }

        .slick-next {
            right: -3px;
        }

        .slick-next,
        .slick-prev {
            width: 45px;
            height: 34px;
            z-index: 1;

            &:before {
                color: #000;
                font-size: 40px;
            }
        }

        .slick-slide {
            .liveOnYachtItem {
                img {
                    border-radius: 12px;
                    margin: auto;
                    max-width: 400px;
                    max-height: 300px;
                    width: 100%;
                }

                p {
                    text-align: center;
                    padding: 15px 15px 0;
                    font-size: 18px;
                    font-weight: 600;
                    max-width: 260px;
                    margin: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #offerLiveOnYachWrapper {
        .slick-slider {
            .slick-dots {
                bottom: -35px;
            }
        }
    }
}
