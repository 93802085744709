#familyTrips .accLp .rwt__tab {
  padding: 30px !important;
  text-transform: uppercase;
}

.responsiveLP {
  .offerResidenceRoom .RoomLeft .image:before {
    content: "i";
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    border: 1px solid white;
    padding: 2px 10px;
    border-radius: 50%;
    // cursor: pointer;
    img {
      opacity: 1 !important;
    }

    .offerResidenceRoom .RoomLeft .image:hover {
      opacity: 1;
    }
  }
}

.accLp {
  margin-top: 70px;

  .popUpImageYachts .popupInner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    cursor: pointer;
  }
  .popup-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;

    .popupImgGallery {
      .image {
        img {
          width: 100%;
        }
      }
    }

    .box {
      margin: auto;
      max-width: 1000px;
      padding: 25px;
      border-radius: 5px;
      position: relative;
      background: #fff;
      display: flex;

      .popupContent {
        width: 40%;
        padding-right: 20px;
        box-sizing: border-box;
      }

      .popupImgGallery {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .ColageImages {
          display: flex;
          flex-wrap: wrap;
          width: 60%;

          .image {
            width: 50%;
            box-sizing: border-box;
            padding: 10px;
            height: 200px;

            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }

            img {
              border-radius: 5px;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }

        .PlanImage {
          width: 40%;
          margin: auto;

          img {
            cursor: pointer;
            max-height: 400px;
            object-fit: contain;
            width: 100%;
          }

          &:hover {
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }

      .close-icon {
        position: absolute;
        right: 12px;
        top: 4px;
        line-height: 1;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
      }

      .popup-title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0;
      }
    }
  }

  .rwt__tablist {
    display: flex;
    width: 600px;
    max-width: 90vw;
  }

  .rwt__tab {
    padding: 20px 25px !important;
    cursor: pointer;

    &[aria-selected="true"] {
      border-radius: 5px 5px 0 0;
      background: white;
      font-family: "SpaceGrotesk-Medium";

      &:after {
        border-bottom: none !important;
      }
    }
  }

  #basic-tab-one,
  #basic-tab-two,
  #basic-tab-three {
    background: white;
    height: auto;
    text-align: left;
    min-height: 200px;
    padding-right: 40px;
    box-shadow: 2px 26px 68px -21px #b8b6b8;
    border-radius: 5px;
  }

  .rwt__tablist:not([aria-orientation="vertical"]) {
    border-bottom: none !important;
  }
}
