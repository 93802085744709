#adventureWrapper {
    background: #fff;
    padding: 10px 0 50px;

    .adventureTitle {
        padding: 0 15px;
        text-align: center;
        max-width: 1000px;
        margin: auto;

        h2 {
            margin: 25px 0;
        }
    }

    .adventureWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1300px;
        margin: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .adventureItem {
        width: 100%;
    }
}
