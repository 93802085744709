#reservationButtonWrapper {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: #123852fc;
  padding: 14px 0;
  color: rgb(10, 30, 44);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#reservationButtonStyled {
  z-index: 10;
  display: block;
  color: rgb(10, 30, 44);
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  background: rgb(255, 209, 95);
  border: 1px solid rgb(255, 209, 95);
  border-radius: 30px;
  padding: 5px 10px;
}
