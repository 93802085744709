#destinationContainer {
    min-height: 700px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;

    .content {
        width: 1100px;
        flex-direction: column;
        display: flex;
        margin: 50px auto 40px;
        padding-bottom: 50px;
        max-width: 90vw;

        .mainText {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 90vw;
            margin: auto;

            h2 {
                margin: 0;
            }

            p {
                text-align: center;
                margin: 30px auto;
            }

            .mainText {
                margin-top: 40px;
                text-align: center;
                margin-bottom: 0;
                width: 780px;
                max-width: 90vw;
            }
        }

        .destinationWrapper {
            display: flex;
            justify-content: space-between;
            max-width: 90vw;
            margin-top: 50px;
            flex-wrap: wrap;

            .oneDestination {
                width: 350px;
                height: 350px;
                background: transparent;
                border-radius: 12px;
                border: 1px solid #d3d3d369;
                box-shadow: -1px 14px 20px 3px rgba(0, 0, 0, 0.07);
                position: relative;
                margin-bottom: 20px;

                button {
                    width: max-content;
                }

                h3 {
                    font-size: 20px;
                }

                .image {
                    background-color: #0000006e;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 12px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                    }
                }

                h3.name {
                    position: absolute;
                    color: white;
                    font-weight: 500;
                    padding: 30px 0 0 37px;
                    margin: 0;
                    font-size: 23px;
                }

                .numberBox {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    position: absolute;
                    background-color: white;
                    right: 0;
                    top: 0;
                    margin: 22px;

                    span.number {
                        position: absolute;
                        text-align: center;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-weight: 500;
                        font-size: 20px;
                    }
                }
            }
        }

        .text {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 300px;
            padding: 0 20px;
            h3 {
                padding-top: 50px;
            }
        }

        .button {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
        }
    }
}

@media only screen and (max-width: 1024px) {
    #destinationContainer .content .destinationWrapper .oneDestination {
        width: 300px;
        height: 300px;

        .text {
            h3 {
                color: #0a1e2c;
                margin-bottom: 0;
                margin-top: 0;
                padding-top: 0
            }

            p {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 996px) {
    #destinationContainer {
        .content {
            align-items: center;

            .mainText h2{
                text-align: center;
            }

            .text .mainText {
                width: auto;
            }

            .destinationWrapper {
                flex-wrap: wrap;
                max-width: 300px;

                .oneDestination {
                    margin-bottom: 20px;
                    width: 100%;

                }
            }
        }
    }
}