.menuPage {
  background-color: #123852fc;
  height: auto;
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
  z-index: 100;
  padding: 17px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .menuWrapper {
    display: flex;
    justify-content: space-between;
    width: 700px;
    margin: 0;
    margin-left: 30px;
    padding: 10px 0;

    li {
      list-style-type: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
      padding: 10px;

      &:hover {
        color: #0a1e2c;
        background: #eefdf6;
        outline: none;
        border-radius: 10px;
        font-family: "SpaceGrotesk-SemiBold";
      }

      &.active {
        color: #0a1e2c;
        background: #eefdf6;
        outline: none;
        border-radius: 10px;
        font-family: "SpaceGrotesk-SemiBold";
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .menuPage {
    display: block;
    width: auto;
    position: relative;

    .bookButtonWrapper {
      display: none;
    }

    .menuWrapper {
      flex-direction: column;
      max-width: 90vw;
      margin: auto;

      li {
        padding: 5px 10px;
      }
    }
  }
}
