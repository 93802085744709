.sectionMovieWrapper {
  background-color: #ffffff;
  padding: 50px;
  padding-bottom: 0;
  margin-bottom: -120px;
  padding-top: 100px;
}

.movieWrapper {
  margin: auto;
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  div {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .sectionMovieWrapper {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}
