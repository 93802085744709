#country {
    padding-top: 85px;
    min-height: 850px;
    background-color: white;

    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        .textContainer {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            text-align: center;
            h1 {
                color: white;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }

    .menuPage {
        background-color: #123852fc;
        height: auto;
        width: 100%;
        position: sticky;
        top: 0;
        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
        z-index: 0;
        padding: 10px;

        .container {
            display: flex;
            justify-content: space-between;
            width: auto;
            margin: 0;
            margin-left: 30px;
            padding: 10px 0;

            a {
                list-style-type: none;
                color: white;
                font-size: 16px;
                cursor: pointer;
                padding: 10px;

                &.active {
                    color: #123852fc;
                    background: #eefdf6;
                    outline: none;
                    border-radius: 10px;
                    font-family: "SpaceGrotesk-SemiBold";
                }
            }
        }
    }

    form {
        width: auto;
        max-width: 90vw;
        box-sizing: border-box;
        margin: auto;

        label {
            text-align: center;
            margin: auto;
            display: flex;
            padding: 50px 0 0;

            select {
                min-width: 300px;
                margin: auto;
                margin-top: 20px;
                width: 450px;
                border-radius: 5px;
                border: 1px solid grey;
                padding-right: 0;
                font-size: 17px;
                box-sizing: border-box;
                padding: 20px;
                float: left;
                -webkit-appearance: none;
                background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
                    no-repeat right #dddddd08;
                background-position-x: 97%;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #country {
        padding-top: 70px;
        padding-bottom: 30px;
        .slider {
            button {
                width: max-content;
            }
        }

        .menuPage {
            width: auto;
            z-index: 10;
            .container {
                flex-direction: column;
                padding: 0;
                max-width: 90vw;
                margin-left: 0;
                margin-right: 0;
                margin: auto;
            }
        }

        form {
            select {
                width: 100%;
            }
        }
    }
}
