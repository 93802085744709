#stepTwo {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px;

   .lds-roller {
       top: 100%;
   }

    .boxInputRadio input:checked~.boxAfterClick {
        z-index: 2;
        height: 99.5%;
    }

    .infoAboutPlace {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background: orange;
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        margin-bottom: 0;
        top: 95.5%;
        border-radius: 0 0 3px 3px;
        z-index: 0;
        margin-left: -0.2px;

        p {
            color: white
        }
    }

    .overlayOffer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        z-index: 0;

        img {

            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            object-fit: cover;
            border-radius: 5px;
        }


        // p {
        //     position: absolute;
        //     text-align: center;
        //     text-transform: uppercase;
        //     top: 50%;
        //     left: 50%;
        //     color: #0b0c0e;
        //     transform: translate(-50%, -50%);
        //     margin: 0px;
        //     padding: 0px;
        //     font-size: 40px;
        //     font-family: "SpaceGrotesk-SemiBold";

        // }
    }

    #classSelect.selectBox.responsive {
        display: none;
    }

    .slick-slide img {
        margin: auto;
    }

    .slick-dots {
        // bottom: 30px;
        display: flex !important;
        justify-content: center;

        li {
            width: 50px;

            button {
                width: auto;

                &:before {
                    font-size: 30px;
                    color: black;
                }
            }
        }
    }

    .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: black;
    }

    .slick-dots li button:before {
        position: relative;
    }

    .slick-next:before,
    .slick-prev:before {
        font-size: 40px;
        color: black;
    }

    .slick-next,
    .slick-prev {
        width: auto;
    }

    .slick-prev {
        margin-left: -30px;
    }

    .slick-next {
        right: -40px;
    }

    .slick-slide {
        max-width: 270px;
    }

    .slick-slide.slick-active div {
        // outline: none;
        justify-content: center;
        display: flex;
    }

    .slick-list {
        padding-bottom: 20px;
    }


    .disabled {
         cursor: not-allowed;
        pointer-events: none;

        &:hover {
            box-shadow: none;
        }
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;

        .onePerson {
            margin-bottom: 50px;

            .name {
                padding: 0 30px;
                background-color: #80808021;
                border-radius: 5px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: -30px;
                height: 100px;

                .containerAddName {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .price {
                    text-align: right;

                    &.edit {
                        font-size: 16px;
                        color: grey;
                        text-decoration: underline;
                        font-family: "SpaceGrotesk";
                        cursor: pointer;

                        img {
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                        }
                    }
                }

                p {
                    font-size: 25px;
                    margin: 0;
                    font-family: "SpaceGrotesk-Bold";
                }
            }

            p.warningText {
                padding-top: 0;
                width: 95%;
            }

            #accomodationSelect.selectBox h4 {
                margin-bottom: 25px;
            }

            select {
                margin-top: 10px;
                margin-bottom: 20px;
                width: 100%;
                padding: 12px;
                background-size: 11px;
                float: none;
                margin-top: 10px;
            }

            p {
                margin-top: 0;
                margin-bottom: 0;
            }

            input {
                width: 200px;
                margin-left: 20px;
            }

            .secondaryBtn {
                min-width: 120px;
                margin-left: 20px;
            }
        }

        #accomodationSelect.selectBox,
        #classSelect.selectBox,
        #transportSelect.selectBox {
            h3 {
                border-bottom: 1px solid #80808066;
                padding-bottom: 25px;
                font-size: 24px;
                margin-bottom: 40px;
                margin-top: 50px;
            }

            .boxes {
                justify-content: flex-start;
                margin: 0;
                // display: flex;
                align-items: flex-start;
                flex-wrap: wrap;

                .slick-slider {
                    max-width: 810px;
                }

                &#transportBoxes {
                    display: flex;
                    .boxInputRadio {
                        min-height: 200px;}
                }

                .boxCont {
                    display: flex;
                    flex-direction: column;
                    max-width: 300px;

                    .boxInputRadio {
                        min-height: 200px;
                        width: 250px;
                        padding: 20px;
                        padding-bottom: 20px;
                        padding-top: 0;
                        margin-right: 15px;
                        position: relative;

                        p.price {
                            font-size: 25px;
                            text-align: center;
                            line-height: 1;
                            margin: auto;
                            padding: 30px 0;
                            font-family: "SpaceGrotesk-Medium";
                        }

                        p.cabinPrice {

                            margin-top: 0px;
                            margin-bottom: 25px;
                            text-align: center;
                            font-family: "SpaceGrotesk-Medium";
                            font-size: 15px
                        }

                        p.titleText {
                            margin-top: 19px;
                            margin-bottom: 19px;
                            font-size: 17px;
                            font-family: "SpaceGrotesk-Medium";
                        }

                        span.checkmarkRadio {
                            top: 24px;
                            left: 14px;
                        }

                        .imageTraining {
                            width: auto;
                            margin-bottom: 15px;
                            margin-top: 5px;
                            height: 140px;

                            img {
                                width: 100%;
                                border-radius: 5px;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        #transportSelect .boxes .boxInputRadio p.price {
            padding: 20px 0;
        }

        .containerSelect {
            width: 550px;
            margin-bottom: 30px;
            max-width: 100%;

            h4 {
                font-size: 17px;
                font-family: "SpaceGrotesk-SemiBold";
                margin-bottom: 10px;
            }

            p {
                font-size: 15px;
                color: #3c3a3a;
                cursor: pointer;
                margin-top: 0;
            }

            select {
                width: 100%;
                padding: 12px;
                background-size: 11px;
                float: none;
                margin-top: 10px;
                max-width: 90vw;

                &[name="town"] {
                    margin-top: 20px;
                }
            }

            .containerInputCheckbox {
                margin-bottom: 25px;

                p {
                    font-family: "SpaceGrotesk-Medium";
                    color: #193e57;
                    ;
                    font-size: 18px;
                    margin-top: 0;
                    margin-bottom: 0;

                    span {
                        font-size: 15px;
                        color: #3c3a3a;
                        display: block;
                        margin-top: 5px;
                    }
                }

                span.checkmark {
                    top: 3px;
                }

                input:checked~.checkmark {
                    background-color: white;
                    top: 3px;
                }
            }
        }

        .addOption {
            h4 {
                font-size: 17px;
                font-family: "SpaceGrotesk-SemiBold";
                margin-bottom: 22px;
                border: none;
                padding-bottom: 0;
            }

            .containerInputCheckbox {
                margin-bottom: 25px;

                p {
                    font-family: "SpaceGrotesk-Medium";
                    color: #193e57;
                    font-size: 18px;
                    margin-top: 0;
                    margin-bottom: 0;

                    span {
                        font-size: 15px;
                        color: #3c3a3a;
                        display: block;
                        margin-top: 5px;
                    }
                }

                span.checkmark {
                    top: 3px;
                }

                input:checked~.checkmark {
                    background-color: white;
                    top: 3px;
                }
            }
        }
    }

    .secondaryBtn {
        margin-right: 20px;
    }

}




@media only screen and (max-width: 767px) {

    #stepTwo .overlayOffer {
        margin-left: 0;
        margin-top: -20px;
 

        p {
            font-size: 30px;
        }
    }

    #stepTwo #classSelect.selectBox.responsive {
        display: block
    }

    #stepTwo #classSelect.selectBox {
        display: none;
    }

    #stepTwo .container #accomodationSelect.selectBox .boxes .boxCont .boxInputRadio,
    #stepTwo .container #classSelect.selectBox .boxes .boxCont .boxInputRadio,
    #stepTwo .container #transportSelect.selectBox .boxes .boxCont .boxInputRadio {
        min-height: fit-content;
        width: 100%;
        padding: 20px;
        padding-bottom: 20px;
        padding-top: 0;
        margin-right: 15px;
        margin: auto;
        padding: 0;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0;
        margin-bottom: 20px;
    }

    #stepTwo {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;


        .slick-next {
            right: 0;
        }

        .slick-prev {
            left: 0;
        }

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;

            .onePerson {
                .name {
                    width: 100%;
                    margin: 0;
                    margin-top: 50px;

                    .containerAddName {}

                    .price {
                        &.edit {
                            img {}
                        }
                    }

                    p {}
                }

                p.warningText {}

                #accomodationSelect.selectBox h4 {}

                select {}

                p {}

                input {}

                .secondaryBtn {}
            }

            #accomodationSelect.selectBox,
            #classSelect.selectBox,
            #transportSelect.selectBox {
                h3 {}

                .boxes {
                    overflow: auto;

                    .boxCont {
                        flex-wrap: wrap;
                        max-width: inherit;


                        .boxInputRadio {
                            // height: 120px;

                            p.price {
                                margin: 0;
                                font-size: 22px;
                                width: min-content;
                                margin-right: 20px;
                                padding: 0;
                                color: green;
                                line-height: 1.2;
                                text-align: right;
                                padding-bottom: 0 !important;
                            }

                            p.cabinPrice {
                                // display: none
                                margin: auto;

                            }

                            .description {
                                display: none;
                            }

                            p.price.include {}

                            p.titleText {}

                            span.checkmarkRadio {
                                top: 50% !important;
                                transform: translate(-50%, -50%);
                                left: 20px;
                            }

                            .imageTraining {
                                display: none;

                                img {}
                            }
                        }
                    }
                }
            }

            #transportSelect.selectBox {
                h3 {}

                .boxes {
                    overflow: auto;

                    .boxCont {
                        flex-wrap: wrap;
                    }

                    .boxInputRadio {
                        min-height: fit-content !important;
                        width: 100%;
                        flex-direction: row;

                        p.price.include {
                            display: none;
                        }

                        p.titleText {
                            margin-bottom: inherit;


                        }

                        span.checkmarkRadio {
                            background-color: white;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 23px;
                            height: 20px;
                            width: 20px;
                            border: 2px solid #80808075;
                            cursor: pointer;
                            transform: translate(-50%, -50%);
                        }

                        .imageTraining {
                            img {}
                        }
                    }
                }
            }


            #transportSelect .boxes .boxInputRadio p.description {
                display: none;
            }

            #transportSelect .boxes .boxInputRadio p.price {
                margin: 0;
                padding: 0;
                margin-top: 0 !important;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: green;
                font-family: 'SpaceGrotesk-SemiBold';
                
            text-align: right;

            }

            .containerSelect {
                h4 {}

                p {}

                select {
                    &[name="town"] {}
                }

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}

                    input:checked~.checkmark {}
                }
            }

            .addOption {
                h4 {}

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}

                    input:checked~.checkmark {}
                }
            }
        }

        .containerButtons {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .secondaryBtn {
                margin-bottom: 10px;
                margin: auto;

            }

            .primaryBtn {

                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    #stepTwo {
        .containerButtons {
            display: flex;
            justify-content: center;

            .secondaryBtn {
                min-width: 130px;
                margin: auto;

            }



            .primaryBtn {
                min-width: 130px;
                margin: auto;
            }
        }



        .container {
            .onePerson {
                .name {
                    .containerAddName {}

                    .price {
                        &.edit {
                            img {}
                        }
                    }

                    p {}
                }

                p.warningText {}

                #accomodationSelect.selectBox h4 {}

                select {}

                p {}

                input {}


            }

            #accomodationSelect.selectBox,
            #classSelect.selectBox,
            #transportSelect.selectBox {
                h3 {}

                .boxes {
                    .boxInputRadio {
                        flex-direction: row;
                        min-height: fit-content;


                        p.price {}

                        p.titleText {
                            margin-top: 0 !important;
                            width: min-content;
                            margin-bottom: 15px;
                            font-size: 17px;
                            font-family: "SpaceGrotesk-Medium";
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: left;
                            margin: 0;
                            padding: 0;
                            margin-left: 50px;
                            margin-bottom: 0 !important;
                            text-align: left;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                        }

                        span.checkmarkRadio {}

                        .imageTraining {
                            img {}
                        }
                    }
                }
            }

            #transportSelect .boxes .boxInputRadio p.price {}

            .containerSelect {
                h4 {}

                p {}

                select {
                    &[name="town"] {}
                }

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}

                    input:checked~.checkmark {}
                }
            }

            .addOption {
                h4 {}

                .containerInputCheckbox {
                    p {
                        span {}
                    }

                    span.checkmark {}

                    input:checked~.checkmark {}
                }
            }
        }

        .secondaryBtn {}
    }
}