#contactContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 500px;
    background-color: white;

    .wrapper {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        max-width: 90vw;
        align-items: center;

        .box {
            min-width: 320px;

            h1 {
                font-size: 30px;
                font-weight: 500;
                margin-top: 30px;
                margin-bottom: 35px;
            }

            p {
                margin: 0;
            }

            .adress,
            .openingHours {
                margin-top: 25px;
            }

            .secondaryBtn {
                margin: 30px 0;
            }
        }

        .form {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: auto;

            form {
                display: flex;
                flex-direction: column;
                height: 100%;

                ::placeholder {
                    color: lightgray;
                }

                label {
                    margin-bottom: 10px;
                    font-size: 15px;
                }
                input,
                textarea {
                    margin-bottom: 10px;
                    border-radius: 5px;
                    border: 1px solid lightgrey;
                    font-size: 15px;
                    padding: 20px 15px;

                    ::placeholder {
                        color: lightgrey;
                    }
                }

                textarea {
                    padding: 12px 15px;
                }

                input:focus,
                textarea:focus {
                    border: 1px solid lightgrey;
                    outline: 1px;
                    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
                }

                button {
                    width: 200px;
                    margin-top: 20px;
                }

                button:hover {
                    box-shadow: 2px 3px 33px 3px rgba(184, 178, 184, 0.31);
                    background-color: #ffd15f;
                    border: 1px solid transparent;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #contactContainer {
        justify-content: center;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 0;

        .wrapper {
            margin: 0;
            justify-content: center;
            flex-direction: column;

            .box {
                .primaryBtn {
                    margin: 30px auto;
                }
            }
            .form {
                max-width: 100%;
                justify-content: center;
                form {
                    max-width: 100%;
                    button {
                        margin: 30px auto 20px;
                    }
                }
            }
        }
    }
}
