#stepSix {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px 90px;

    #connectionReservation.selectRadio {
        padding-top: 30px;

        .container {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;

            .textBox {
                width: 460px;
                margin-bottom: 30px;
                .boxButton {
                    display: block;
                    margin-top: 20px;

                    .secondaryBtn {
                        
                    }
                }

                p {
                    margin-top: 0;
                    margin-right: 50px;
                    font-size: 15px;
                    color: #4c4f56cf;
                    margin-bottom: 30px;
                }
                form {
                    .containerInputRadio {
                        height: auto;
                        width: 450px;
                        p {
                            margin: 0;

                            &:first-child {
                                font-family: "SpaceGrotesk-Medium";
                                font-size: 18px;
                                color: inherit;
                            }

                            &:nth-child(2) {
                                margin-top: 5px;
                                color: #4c4f56cf;
                            }
                        }
                    }
                }

                #liderBox.inputBox {
                    // margin-left: 35px;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    .inputName {
                        font-size: 16px;
                        color: inherit !important;
                        font-family: "SpaceGrotesk-SemiBold";
                        margin-bottom: 5px !important;
                    }

                    .confirmedInformation {
                        margin-bottom: 0 !important;
                    }
                    .formContainer {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        margin-right: 20px;
                        width: auto;
                        margin-top: 10px;
                        input {
                            width: 280px;
                        }
                        .secondaryBtn {
                            min-width: auto;
                            width: 120px;
                        }
                    }
                    .confirmedInformation {
                    }

                    .warningText {
                        width: 100%;
                        margin-bottom: 0 !important;
                    }
                }

                .containerInputCheckbox {
                    a {
                        text-decoration: underline;
                        font-family: "SpaceGrotesk-Medium";
                    }
                }
            }

            .greenBox {
                height: fit-content;
                margin-top: 0;
                width: 350px;
                p {
                    line-height: 1.5;
                }
            }
        }
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;

        .onePerson {
            margin-bottom: 50px;

            .name {
                padding: 0 30px;
                background-color: #80808021;
                border-radius: 5px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: -30px;
                height: 85px;

                p {
                    font-size: 25px;
                    margin: 0;
                    font-family: "SpaceGrotesk-SemiBold";
                }

                .price {
                    text-align: right;
                }

                p {
                    font-size: 23px;
                    margin: 0;
                }
            }

            .optionsContainer {
                margin-bottom: 60px;
                .detailsBox {
                    width: 650px;
                    margin-bottom: 10px;
                    max-width: 90vw;
                    margin-top: 10px;

                    td {
                        margin-bottom: 10px;
                        max-width: 90vw;
                        padding: 16px 0;
                        font-size: 17px;
                        &.firstColumn {
                            width: 55%;
                            font-family: "SpaceGrotesk-SemiBold";
                            font-size: 17px;
                        }

                        &.secondColumn {
                            width: 60%;
                            font-size: 17px;
                            span {
                                font-family: "SpaceGrotesk-Medium";
                                font-size: 14px;
                            }
                        }
                    }
                }

                .vouchersBox {
                    margin: 40px 0;
                    .containerSelect {
                        width: 550px;
                        h4 {
                            font-size: 17px;
                            font-family: "SpaceGrotesk-SemiBold";
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 15px;
                            color: #3c3a3a;
                            cursor: pointer;
                            margin-top: 0;
                        }
                        select {
                            width: 100%;
                            padding: 12px;
                            background-size: 11px;
                            float: none;
                            margin-top: 10px;

                            &[name="town"] {
                                margin-top: 20px;
                            }
                        }

                        .containerInputCheckbox {
                            margin-top: 30px;
                            p {
                                font-family: "SpaceGrotesk-Medium";
                                color: black;
                                font-size: 18px;
                                margin-top: 0;
                                margin-bottom: 0;
                                span {
                                    font-size: 15px;
                                    color: #3c3a3a;
                                    display: block;
                                    margin-top: 5px;
                                }
                            }

                            span.checkmark {
                                top: 3px;
                            }
                            input:checked ~ .checkmark {
                                background-color: white;
                                top: 3px;
                            }
                        }
                    }

                    .vouchersContainer {
                        max-width: 90vw;
                        width: 450px;

                        p {
                            margin-bottom: 8px;
                            font-size: 16px;
                            font-family: "SpaceGrotesk-Bold";
                        }

                        .vouchers {
                            display: flex;
                            justify-content: space-between;

                            input {
                                width: 300px;
                            }

                            .secondaryBtn {
                                margin-right: 0;
                                min-width: 120px;
                            }
                        }

                        .primaryBtn {
                            min-width: 100px;
                            height: auto;
                            padding: 10px;
                            margin: 0;
                        }
                    }
                }
            }

            .detailsBox {
                width: 650px;
                margin-bottom: 30px;
                // max-width: 90vw;

                td {
                    margin-bottom: 10px;
                    max-width: 90vw;
                    padding: 16px 0;
                    &.firstColumn {
                        font-size: 17px;
                        width: 55%;
                        font-family: "SpaceGrotesk-Bold";
                    }

                    &.secondColumn {
                        width: 45%;
                        font-size: 17px;
                        span {
                            font-family: "SpaceGrotesk-Medium";
                            font-size: 14px;

                            &.link {
                                color: #8a8a8a;
                                i.arrow {
                                    border: solid #8a8a8a;
                                    border-width: 0 2px 2px 0;
                                }
                            }
                            i.arrow {
                                border: solid black;
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                padding: 2.5px;
                                transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                margin-left: 4px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
        .secondaryBtn {
            margin-right: 20px;
        }
    }

    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 25px;
        font-family: "SpaceGrotesk-SemiBold";
    }

    .addPromotions {
    }

    .ambassadorContainer {
        max-width: 90vw;
        width: 450px;

        p {
            margin-bottom: 8px;
            font-size: 16px;
            font-family: "SpaceGrotesk-Bold";
        }

        .ambassador {
            display: flex;
            justify-content: space-between;

            input {
                width: 300px;
            }

            .secondaryBtn {
                margin-right: 0;
                min-width: 120px;
            }
        }
    }

    .boxSubmit {
        width: auto;
        background: #00ad34;
        border-radius: 7px;
        display: flex;
        justify-content: space-between;
        padding: 20px 40px;
        box-sizing: border-box;
        margin: 70px 0;

        p {
            color: white;
            margin: 0;
            font-size: 20px;
        }

        .description {
            display: flex;
            align-items: center;
        }

        .priceBox {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-end;
            .price {
                font-size: 22px;
            }

            .smallText {
                font-size: 15px;
                font-family: "SpaceGrotesk";
                color: #fff5e9d9;
                span {
                    font-size: 15px;
                    font-family: "SpaceGrotesk-Bold";
                    color: white;
                }
            }
        }
    }
    .paymentsMethods {
        .containerInputRadio {
            margin: 30px 0 50px;
            .inputRadio {
                font-family: "SpaceGrotesk-Medium";
                font-size: 18px;
                margin-bottom: 15px;
                width: fit-content;
                user-select: text;
                .title {
                    margin: 0;
                    margin-top: 5px;
                    font-family: "SpaceGrotesk-Bold";
                    .postscript {
                        color: #00ad34;
                    }
                }
                span.checkmark {
                    top: 7px;
                }
                .box {
                    width: 100%;
                    height: auto;
                    border: 1px solid #808080a3;
                    border-radius: 5px;
                    margin-top: 20px;
                    padding-left: 30px;
                    padding-bottom: 10px;
                    .title {
                        font-family: "SpaceGrotesk-Bold";
                        color: black;
                        margin: 8px 0;
                        margin-top: 15px;
                        font-size: 16px;
                    }
                    .rate {
                        font-size: 15px;
                        color: #676666;
                        margin: 8px 0;
                        font-family: "SpaceGrotesk-SemiBold";
                    }
                }
            }
        }
        .agreements {
            width: 550px;
            margin-bottom: 50px;
            .containerInputCheckbox {
                margin-bottom: 25px;
                display: flex;
            }

            p.textSmall {
                margin: 0;
                font-size: 16px;
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .primaryBtn {
        min-width: 100px;
        height: auto;
        margin: 0;
    }
}

@media only screen and (max-width: 768px) {
    #stepSix {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;
        overflow-x: hidden;
        #connectionReservation.selectRadio {
            input {
                margin: 0 0 10px;
            }
            .container {
                flex-direction: column;
                
                .textBox {
                    width: auto;
                    p {
                    }
                    form {
                        .containerInputRadio {
                            p {
                                &:first-child {
                                }

                                &:nth-child(2) {
                                }
                            }
                        }
                    }

                    #liderBox.inputBox {
                        .inputName {
                        }

                        .confirmedInformation {
                        }
                        .formContainer {
                            flex-wrap: wrap;
                            width: auto;
                            input {
                            }
                            .secondaryBtn {
                            }
                        }
                        .confirmedInformation {
                        }

                        .warningText {
                        }
                    }

                    .containerInputCheckbox {
                        a {
                        }
                    }
                }

                .greenBox {
                    width: auto;
                    p {
                    }
                }
            }
        }

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;
            margin-top: 50px;
            .onePerson {
                .name {
                    width: 100%;
                    margin: 0;
                    margin-top: 50px;
                    padding: 0 10px;
                    p {
                    }

                    .price {
                    }

                    p {
                    }
                }

                .optionsContainer {
                
                    .detailsBox {
                        width: auto;
                        td {
                            &.firstColumn {
                                width: 50%;
                            }

                            &.secondColumn {
                                // width: auto;
                                width: 50%;
                               text-align: right;
                                span {
                                }
                            }
                        }
                    }

                    .vouchersBox {
                        .containerSelect {
                            h4 {
                            }
                            p {
                            }
                            select {
                                &[name="town"] {
                                }
                            }

                            .containerInputCheckbox {
                                p {
                                    span {
                                    }
                                }

                                span.checkmark {
                                }
                                input:checked ~ .checkmark {
                                }
                            }
                        }

                        .vouchersContainer {
                            p {
                                margin-bottom: 0;
                            }

                            .vouchers {
                                flex-wrap: wrap;
                                input {
                                    margin: 15px 0;
                                }

                                .secondaryBtn {
                                }
                            }

                            .primaryBtn {
                            }
                        }
                    }
                }

                .detailsBox {
                    width: auto;
                    display: grid;
                    tr {
                        display: table;
                    }
                    td {
                        &.firstColumn {
                            width: auto;
                        }

                        &.secondColumn {
                            width: auto;
                            float: right;
                            margin-bottom: 0;
                            span {
                                &.link {
                                    i.arrow {
                                    }
                                }
                                i.arrow {
                                }
                            }
                        }
                    }
                }
            }
            .secondaryBtn {
            }
        }

        h4 {
        }

        .addPromotions {
        }

        .ambassadorContainer {
            p {
                margin-bottom: 10px;
            }

            .ambassador {
                flex-wrap: wrap;
                input {
                    margin: 0 0 10px;
                }

                .secondaryBtn {
                }
            }
        }

        .boxSubmit {
            width: auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
            }

            .description {
                align-items: center;
                text-align: center;
            }

            .priceBox {
                justify-content: center;
                text-align: center;
                align-items: center;
                .price {
                }

                .smallText {
                    span {
                    }
                }
            }
        }
        .paymentsMethods {
            .containerInputRadio {
                .inputRadio {
                    .title {
                        .postscript {
                        }
                    }
                    span.checkmark {
                    }
                    .box {
                        width: auto;
                        padding-right: 20px;
                        .title {
                        }
                        .rate {
                        }
                    }
                }
            }
            .agreements {
                width: auto;
                .containerInputCheckbox {
                }

                p.textSmall {
                    a {
                    }
                }
            }
        }

        .containerButtons {
            display: flex;
            justify-content: center;
           
            .secondaryBtn {
                min-width: 130px;
                margin: auto;
            }
            .primaryBtn {
                min-width: 130px;
                margin: auto;
            }
        }
    }

    #stepSubmit {
        .container {
            p {
                margin: 0
            }
        }
    }
}


