#accomodation,
#insurance {
    padding-left: 10px;
    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 25px;
        padding-left: 30px;
    }
    .greenBox {
        margin-bottom: 50px;
        &.reserveListInfo {
            margin-bottom: 20px;
            margin-top: 20px;
            background-color: #ffc1074f;
            font-weight: 100;
            max-width: 600px;
            width: fit-content;
            span {
                color: orange;
                font-family: "SpaceGrotesk-SemiBold";
            }
            .infoTitle {
                color: orange;
            }
        }
        &.yourGroup {
            background: rgba(255, 193, 7, 0.31);
            width: fit-content;
            margin-top: 30px;
            min-width: 250px;
            .infoText {
                color: orange;
            }
        }
        p {
            max-width: 100%;
        }
    }

    .containerSelect {
        &.changeOption {
            display: flex;
            align-items: center;
            justify-content: flexStart;
            margin-top: 30px;
            margin-left: 30px;
            select {
                max-width: 300px;
                margin: 0;
            }
        }
        .btnBox {
            display: flex;
        }
    }

    .onePerson {
        margin-top: 60px;
        .name {
            padding: 25px 30px;
            width: 100%;
            background-color: #80808021;
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .price {
                text-align: right;
            }

            p {
                font-size: 23px;
                margin: 0;
            }
        }

        .boxes {
            display: flex;
            justify-content: flex-start;
            margin-left: 25px;
            margin-bottom: 30px;

            .containerInput {
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                user-select: none;
                padding: 15px;
                min-width: 240px;
                width: 240px;
                min-height: 200px;
                height: 200px;
                border: 1.5px solid #80808075;
                border-radius: 6px;
                margin-top: 35px;
                margin-right: 30px;
                box-sizing: border-box;
                p.titleText {
                    margin-left: 35px;
                    font-family: SpaceGrotesk-Medium;
                    margin-top: 11px;
                    font-size: 16px;
                    cursor: pointer;
                }

                p.description {
                    font-family: SpaceGrotesk;
                    color: grey;
                    font-size: 14px;
                    margin-top: 0;
                    cursor: pointer;
                }

                p.price {
                    font-size: 20px;
                    text-align: center;
                    line-height: 1;
                    margin-bottom: 10px;
                    margin-top: 35px;
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    transform: translate(-50%);
                }

                input {
                    position: absolute;
                    opacity: 0;
                    height: 0;
                    width: 0;

                    &:hover input ~ .checkmark {
                        background-color: white;
                    }

                    &:checked ~ .checkmark {
                        background-color: white;
                        border: 2px solid black;

                        &:after {
                            display: block;
                        }
                    }

                    &:checked ~ .oneBox {
                        border: 2px solid black;
                    }
                }
                span.checkmark {
                    position: absolute;
                    top: 26px;
                    left: 20px;
                    height: 20px;
                    width: 20px;
                    border-radius: 5px;
                    border: 2px solid #80808075;
                    cursor: pointer;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    &:after {
                        left: 7px;
                        top: 3px;
                        width: 3px;
                        height: 10px;
                        border: solid #0a1e2c;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
                .oneBox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                    margin: -1px;
                    cursor: pointer;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .training {
            .imageTraining {
                width: 200px;
                margin-top: 10px;
                margin-bottom: 15px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }
            .containerInputRadio {
                min-height: 200px;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                user-select: none;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 15px;
                min-width: 240px;
                width: 240px;
                height: auto;
                border: 1.5px solid #80808075;
                border-radius: 6px;
                margin-top: 35px;
                margin-right: 30px;
                box-sizing: border-box;

                &.notInterested {
                    height: 117px !important;
                    min-height: 117px !important;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:hover input ~ .checkmarkRadio {
                        background-color: white;
                    }

                    &:checked ~ .checkmarkRadio {
                        background-color: white;
                        border: 2px solid black;

                        &:after {
                            display: block;
                        }
                    }

                    &:checked ~ .oneBox {
                        border: 2px solid black;
                    }
                }
                span.checkmarkRadio {
                    background-color: white;
                    border-radius: 50%;
                    position: absolute;
                    top: 28px;
                    left: 13px;
                    height: 20px;
                    width: 20px;
                    border: 2px solid #80808075;
                    cursor: pointer;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        cursor: pointer;
                    }

                    &:after {
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #0a1e2c;
                        transform: translate(-50%, -50%);
                    }
                }

                .oneBox {
                    height: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                    margin: -1px;
                    cursor: pointer;
                }

                p.titleText {
                    margin-left: 28px;
                    margin-top: 12px;
                    font-size: 16px;
                    cursor: pointer;
                }

                p.description {
                    font-size: 14px;
                    color: grey;
                    cursor: pointer;
                    margin-top: 0;
                }

                p.price {
                    font-size: 20px;
                    text-align: center;
                    line-height: 1;
                    margin-bottom: 10px;
                    margin-top: 35px;

                    span {
                        font-size: 14px;
                    }
                }

                a {
                    font-size: 13px;
                    color: grey;
                    z-index: 2;
                    &:hover {
                        color: #060606;
                        text-decoration: none;
                    }
                }
            }
        }

        .training.level {
            margin-bottom: 30px;
            .containerInputRadio {
                justify-content: flex-start;
            }
            p.price {
                font-size: 20px;
                text-align: center;
                line-height: 1;
                margin-bottom: 10px;
                margin-top: 35px;
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translate(-50%);
            }
        }

        .detailsBox {
            width: 800px;
            margin-bottom: 10px;
            max-width: 90vw;
            margin-left: 30px;
            margin-top: 10px;

            td {
                margin-bottom: 10px;
                max-width: 90vw;
                padding: 12px 0;
                &.firstColumn {
                    width: 40%;
                    font-family: "SpaceGrotesk-SemiBold";
                }

                &.secondColumn {
                    width: 60%;
                    span {
                        font-family: "SpaceGrotesk-Medium";
                        font-size: 14px;
                    }
                }
            }
        }
    }

    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 25px;
        padding-left: 30px;
    }

    .detailsBox {
        width: 650px;
        margin-bottom: 10px;
        max-width: 90vw;
        margin-left: 30px;
        margin-top: 10px;

        td {
            margin-bottom: 10px;
            max-width: 90vw;
            padding: 12px 0;
            &.firstColumn {
                width: 40%;
                font-family: "SpaceGrotesk-SemiBold";
            }

            &.secondColumn {
                width: 60%;
                span {
                    font-family: "SpaceGrotesk-Medium";
                    font-size: 14px;
                }
            }
        }
    }
    p.infoText {
        margin-top: 0;
        color: #1eb74c;
    }

    i {
        color: #1eb74c;
        margin-left: 0;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 767px) {
    #accomodation,
    #insurance {
        padding-right: 0px;

        .detailsBox {
            width: auto !important;
            margin-left: 0 !important;
        }

        .containerSelect {
            flex-wrap: wrap;
            margin-left: 0 !important;
        }

        select {
            min-width: 250px;
            margin-left: 0 !important;
        }

        .btnBox {
            flex-direction: column;
            width: 280px;
            margin-top: 20px;
            button {
                margin: 0 !important;
                width: 130px;
                margin-bottom: 20px !important;
            }
        }

        .greenBox {
            margin-left: 0;
        }
        h4 {
            padding-left: 0px;
        }

        .detailsBox {
            margin-left: 0;
            margin-bottom: 0;
            margin-top: 15px;
            padding-left: 0px;
            td {
                &.firstColumn {
                    width: auto;
                    font-family: "SpaceGrotesk-SemiBold";
                }

                &.secondColumn {
                    width: 50%;
                }
            }
        }
    }
}
