#oneTrip {
  border: 1px solid #80808038;
  border-radius: 13px;
  height: 330px;
  position: relative;

  .ribbon {
    position: absolute;
    left: -3px;
    top: -30px;
    height: 30px;
    border-radius: 5px 5px 0 0;
    padding: 5px 10px;
    text-align: center;
    border-bottom: 20px solid green;
    background: green;
    width: fit-content;
    p {
      color: white;
      margin: 0;
      font-size: 14px;
    }
  }

  .hidden {
    display: none;
  }

  .photoContainer {
    width: 100%;
    position: relative;
    cursor: pointer;

    .overlayOffer {
      position: absolute;
      width: 100%;
      height: 100%;
      // background: url("./img/ster.png");
      z-index: 1;

      img {
        object-fit: fill;
      }

      p {
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 50%;
        left: 50%;
        color: #012742;
        transform: translate(-50%, -50%);
        margin: 0px;
        padding: 0px;
        font-size: 40px;
        font-family: 'SpaceGrotesk-SemiBold';
      }
    }

    .overlayOffer.responsive {
      display: none;
    }

    .overlayOffer.desktopVersion {
      display: block;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      /* background: linear-gradient(180deg, #000000b3, transparent); */
      border-radius: 10px;
      cursor: pointer;
      background: linear-gradient(
        180deg,
        rgba(142, 158, 173, 0.7) 0%,
        #000101a6 0%,
        rgba(57, 87, 100, 0.04) 96%
      );
      width: 100%;
      height: 100%;
    }
  }

  .tripInfo {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 40px;
    margin: auto;
    left: 0;
    right: 0;
    transform: translate(0%, 0%);
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;

    .infoTripBox {
      p {
        font-size: 16px;
        color: white;
        font-family: 'SpaceGrotesk-Medium';
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          height: 18px;
          filter: none;

          &:first-child {
            height: 20px;
            filter: initial;
            border-radius: 0;
          }
        }
      }
    }

    p.confirmedTrip {
      width: fit-content;
      color: white;
      padding: 2px 10px 2px 7px;
      background: green;
      border-radius: 5px;
      margin-top: 10px;
      font-family: 'SpaceGrotesk';
      font-size: 17px;
      line-height: 2;
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
    }

    .shortInfo {
      width: auto;
      padding-right: 50px;
      line-height: 1.4;
    }
  }

  .priceSection {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    right: 5px;
    border-radius: 10px;
    top: 0;
    height: 100%;
    cursor: pointer;

    .priceBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .discount {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        cursor: pointer;

        .newPrice,
        .text {
          color: #dc2b2f;
          font-size: 30px;
          cursor: pointer;
          text-decoration: none;
          font-family: 'SpaceGrotesk-Medium';
          margin: 0;
          filter: drop-shadow(2px 4px 6px black);
        }

        .text {
          font-size: 15px;
          font-family: 'SpaceGrotesk';
          cursor: pointer;
        }
      }

      .price {
        color: white;
        filter: drop-shadow(2px 4px 6px black);
        font-size: 16px;
        text-decoration: line-through;
        margin: 0;
        cursor: pointer;
        margin-top: 10px;
      }

      .text {
        color: white;
        filter: drop-shadow(2px 4px 6px black);
        margin: 0;
        font-size: 13px;
        cursor: pointer;
      }

      .regularPrice {
        text-align: center;

        .price {
          font-size: 30px;
          text-decoration: none;
          font-family: 'SpaceGrotesk-Medium';
          margin: 0;
          color: white;
          cursor: pointer;
        }

        .text {
          color: white;
          filter: drop-shadow(2px 4px 6px black);
          margin: 0;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
  }

  .detailsBox {
    text-align: center;
    cursor: pointer;

    .amountOfPeople {
      margin-bottom: 10px;
      color: white;
      filter: drop-shadow(2px 4px 6px black);
    }

    .availability {
      font-family: 'SpaceGrotesk-SemiBold';
      margin-top: 5px;
      color: white;
      filter: drop-shadow(2px 4px 6px black);

      &.red {
        background: #860202;
        border-radius: 20px;
        padding: 7px;
        margin-top: 10px;
      }

      &.green {
        background: green;
        border-radius: 20px;
        padding: 7px;
        margin-top: 10px;
      }

      &.orange {
        background: orange;
        border-radius: 20px;
        padding: 7px;
        margin-top: 10px;
      }
    }

    .primaryBtn {
      max-height: 50px;
      // min-width: 100px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .tagsBox {
    width: fit-content;
    bottom: 15px;
    left: 5%;

    .oneTag {
      font-family: 'SpaceGrotesk-Medium';
      border-radius: 5px;
      padding: 10px;
      height: auto;
      width: auto;
      font-size: 14px;
      align-items: center;
      background-color: white;
      display: flex;

      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }
}

#oneTrip.ribbonBorder {
  border: 3px solid green !important;
}

@media only screen and (max-width: 1250px) {
  #oneTrip {
    height: 550px;
    max-width: 400px;
    margin-right: 0;
    margin: auto;
    margin-bottom: 50px;
    flex-wrap: wrap;
    border-radius: 10px;

    .tripInfo p.confirmedTrip {
      top: 0;
      left: 0;
    }

    .photoContainer {
      width: 100% !important;
      cursor: pointer;
      border-radius: 10px;

      .overlay {
        border-radius: 10px;
        cursor: pointer;
        background: linear-gradient(
          180deg,
          #8e9ead9e,
          #000101e0 0,
          #39576491 100%
        );
      }

      img {
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .tripInfo {
      width: auto;
      flex-direction: column;
      padding: 20px;
      padding-top: 35px;

      .infoTripBox {
        p {
          margin: auto;
          width: 100%;
          margin-left: 0px;
          text-align: left;

          .confirmedTrip {
            display: block;
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }

      .tagsBox {
        padding-right: 0;

        .oneTag {
          margin-right: 0;
        }
      }

      .shortInfo {
        padding-right: 0;
      }
    }

    .priceSection {
      width: 100%;
      top: auto;
      bottom: 10%;
      height: auto;
      .discount {
        margin-top: 45px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #oneTrip {
    height: 550px;
    border-radius: 10px 10px 10px 10px;

    .tagsBox {
      width: fit-content;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 150px;
      display: none;
    }

    .photoContainer {
      width: 100% !important;
      height: 100%;

      .overlayOffer.desktopVersion {
        display: none;
      }

      .overlayOffer.responsive {
        display: block;
      }

      .overlayOffer img {
        object-fit: none;
      }

      .overlay {
        border-radius: 10px 10px 10px 10px;
      }

      img {
        border-radius: 10px;
      }
    }

    .tripInfo {
      top: 0;
      position: absolute;
      transform: translate(0%, 0%);
      left: 0;

      h3 {
        text-align: center;
      }

      .infoTripBox {
        margin-top: 20px;
        p {
          .confirmedTrip {
            display: flex;
            margin-top: 0;
            margin-left: 0;
          }
        }
      }

      .tagsBox {
        padding-right: 0;

        .oneTag {
          margin-right: 0;
        }
      }

      .shortInfo {
        padding-right: 0;
      }
    }

    .priceSection {
      width: 100%;
    }
  }
}
