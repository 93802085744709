#workForSkipper {
    padding-top: 70px;
    min-height: 1000px;
    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            margin: 0;
            font-size: 35px;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }
    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 100px;

        .container {
            width: 1100px;
            margin: auto;
            // border: 1px solid grey;
            height: 100%;
            min-height: 800px;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 100px;
                .textSection {
                    width: 40%;
                    h2 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                    }
                }
                .imageSection {
                    width: 45%;
                    height: 320px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;
                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 300px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 300px;
                        }
                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                            margin-top: 20px;
                        }
                    }
                }
                .textSection {
                    width: 45%;
                }
            }
        }
    }
   
}

@media only screen and (max-width: 767px) {
    #workForSkipper {
        
        .slider {
           
            h2 {
                top: 60%;
               
            }
    
            img {
                
            }
        }
        .info {
            
    
            .container {
                box-sizing: border-box;
                
    
                .firstSection,
                .secondSection {
                    padding-top:30px;
                    .textSection {
                        width: 100%;
                       
                        h2 {
                            margin-top: 20px;
                           
                        }
                        P {
                            
                        }
                    }
                    .imageSection {
                        width: 100%;
                        
                        img {
                        
                        }
                    }
                }
    
                .secondSection {
                    .imageSection {
                        
                        img {
                            &.firstPhoto {
                                
                            }
                            &.secondPhoto {
                                width: 100%;
                             
                            }
                        }
                    }
                    .textSection {
                        h2 {
                            margin-top:20px;
                        }
                        
                    }
                }
            }
        }
      
    }
}
