#reservationMenu {
    position: absolute;
    background: #123852fc;
    width: 100%;
    top: 0;
    box-sizing: border-box;
    z-index: 2;

    .menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        padding: 5px 25px;
        box-sizing: border-box;
        .logoBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 30%;

            img {
                height: 50px;
                cursor: pointer;
            }
        }

        .reservationName {
            text-align: center;
            font-size: 25px;
            width: 100%;
            p {
                color: white;
                width: 100%;
            }
        }

        .help {
            width: 30%;
            display: flex;
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            
            p {
                color: white;
                font-size: 16px;
                margin: 0;
                padding: 0;
                cursor: pointer;
            }

            img {
                margin-right: 7px;
                width: 18px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    #menuPage {
        position: absolute;
        width: 100%;
        height: 50px;
        background-color: #123852fc;
        z-index: 1;

        .menu {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            // margin: 0 0 0 20px;

            .logoBox {
                margin-top: 3px;
                padding-left: 20px;

                img {
                    height: auto;
                    width: 120px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #reservationMenu {
        .menu {
            flex-wrap: wrap;
            justify-content: center;

            .logoBox {
                width: 120px;
                height: 40px;
                order: 1;
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .reservationName {
                font-size: 15px;
                order: 3;
                p {
                }
            }

            .help {
                order: 2;
                display: none;
                p {
                    font-size: 14px;
                }

                img {
                    width: 15px;
                }
            }
        }
    }
}
