#payments {
    padding-left: 30px;

    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    .detailsBox {
        width: 650px;
        margin-bottom: 20px;
        margin-top: 10px;

        td {
            margin-bottom: 10px;
            padding: 12px 0;
            &.firstColumn {
                width: 40%;
                font-family: "SpaceGrotesk-SemiBold";
            }

            &.secondColumn {
                width: 60%;
                span {
                    font-family: "SpaceGrotesk-Medium";
                    font-size: 14px;
                }
            }
        }
    }

    .vouchersContainer {
        width: 450px;
        max-width: 90vw;

        p {
            margin-bottom: 8px;
            font-size: 14px;
        }

        .vouchers {
            display: flex;
            justify-content: space-between;

            input {
                width: 250px;
            }
        }

        .primaryBtn {
            min-width: 100px;
            height: auto;
            padding: 10px;
            margin: 0;
        }

        .secondaryBtn {
            max-width: 100px;
            width: 180px;
        }
    }
}

@media only screen and (max-width: 767px) {
    #payments {
        padding-left: 0;
        .detailsBox {
            margin-left: 0px;
            margin-bottom: 10px;
            padding-left: 0;
            width: auto;
            td {
                &.firstColumn {
                    width: 50%;
                    font-family: "SpaceGrotesk-SemiBold";
                }

                &.secondColumn {
                    width: 50%;
                    text-align: right;
                }
            }
        }

        .vouchersContainer {
            width: 500px;
            max-width: 90vw;

            p {
                margin-bottom: 8px;
                font-size: 14px;
            }

            .vouchers {
                flex-wrap: wrap;
            }
        }
    }
}

@media only screen and (max-width: 484px) {
    #payments {
        .vouchersContainer {
            .secondaryBtn {
                margin-top: 20px;
            }
        }
    }
}
