.secureBookingItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    p {
        color: #fff;
        text-align: center;
        margin: 25px 0 0;
        max-width: 300px;
    }

    img {
        width: 100px;
    }
}

@media only screen and (max-width: 425px) {
    .secureBookingItem {
        p {
            max-width: 250px;
        }
    }
}
