#addHtml {
    padding-top: 40px;
    padding-right: 200px;
    padding-left: 200px;
    padding-bottom: 30px;
    background-color: #ffffff;

    .addHtml1,
    .addHtml2,
    .addHtml3 {
        width: 700px;
        margin: auto;
        min-width: fit-content;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        height: auto;
        align-items: center;

        h3 {
            font-family: "SpaceGrotesk-Medium";
            padding: 0;
            margin: 0;
            margin-top: 40px;
            text-align: left;
            margin-bottom: 30px;
        }

        li {
            text-align: left;
            line-height: 1.8;
            font-size: 16px;
        }

        p {
            text-align: left;
            font-size: 16px;

            &.paragraph {
                text-align: left;
                width: 45%;
                margin-bottom: 50px;
                max-width: 90vw;
            }

            span {
                font-family: "SpaceGrotesk-SemiBold";
            }

            &:first-child {
                text-align: center;
                margin: 0;
                font-size: 18px;
            }

            ol {
                margin-top: 20px;

                li {
                    margin: 10px;
                    text-align: left;
                    font-size: 16px;
                }
            }
        }
    }

    .addHtml3 {
        padding-bottom: 50px;
        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }

        p.paragraph {
            margin: auto 0;
        }
        .image {
            height: 350px;
            width: 48%;
        }

        ul {
            margin-bottom: 0;
        }
    }
    .image {
        width: 45%;
        height: 300px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .twoImage {
        width: 45%;
        height: 500px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;

            &:nth-child(2) {
                width: 70%;
            }
        }
    }

    .addHtml2 {
        padding: 30px 0;

        p {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    #addHtml {
        padding-top: 5px;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 50px;
        flex-wrap: wrap;
        width: auto;

        .addHtml1,
        .addHtml2,
        .addHtml3 {
            padding-top: 5px;
            padding-top: 5px;
            flex-wrap: wrap;
            width: auto;
            margin-top: 30px;

            h3 {
                margin-bottom: 0;
                margin-top: 10px;
            }
            p {
                text-align: left;
                font-size: 15px;
                box-sizing: border-box;
                height: 100%;

                &.paragraph {
                    width: 100%;
                    margin-bottom: 0;
                }
            }
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }

        p.paragraph {
            margin: auto 0;
        }
        .image {
            height: 350px;
            width: 48%;
        }
        .image {
            width: 100%;
        }

        .twoImage {
            width: 100%;

            img {
                &:nth-child(2) {
                    width: 100%;
                }
            }
        }

        .addHtml2 {
            h3 {
                margin-bottom: 20px;
                margin-top: 10px;
            }
        }

        .addHtml3 {
            h3 {
                margin-top: 20px;
            }

            .image {
                width: auto;
            }
        }
    }
}
