#tripStatusMenu {
    position: relative;
    .lds-roller {
        width: 80px;
        height: 80px;
        position: relative !important;
        margin: auto;
        left: 0 !important;
    }
    .rwt__tablist:not([aria-orientation="vertical"]) {
        display: flex;
        margin-bottom: 50px;
        border-bottom: 3px solid #dddddd94;

        .rwt__tab {
            justify-content: center;
            align-items: center;
            width: auto;
            // max-width: 120px;
            padding-bottom: 20px !important;
            background: transparent !important;

            &[aria-selected="true"] {
                font-weight: 600;
            }

            &[aria-selected="true"]:after {
                border-bottom: 3px solid black;
                bottom: -3px !important;
            }

            &[aria-selected="false"]:hover {
                background-color: transparent !important;
            }

            &focus {
                background-color: transparent !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #tripStatusMenu .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab {
        justify-content: center;
        width: 50%;
    }

    #tripStatusMenu {
        .rwt__tablist:not([aria-orientation="vertical"]) {
            border-bottom: 3px solid #dddddd94;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
}
