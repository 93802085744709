.OfferSliderWrapper {
  height: auto;
  width: 100%;
  max-width: 600px;
  margin: 0 0 auto 0;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
  font-size: 0.75em;
  padding: 25px;

  .slick-slide {
    height: 100%;
  }

  .slick-arrow,
  .slick-prev {
    font-size: 0;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 50px;
    height: 0;
    width: 0;
  }

  .slick-next,
  .slick-prev {
    height: auto;
    width: auto;
  }

  .slick-next {
    right: -55px;
  }

  .slick-prev {
    left: -54px;
  }

  p {
    font-size: 14px;
  }

  h3 {
    text-align: center;
  }

  .listTabsMenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around;
    line-height: 30px;

    li {
      margin: 5px;
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      flex: 1 0 auto;
      height: auto;

      span {
        cursor: pointer;

        &:hover {
          color: #09874d;
          border-bottom: 1px solid #09874d;
        }
      }
    }

    .activeTab {
      span {
        color: #09874d;
        font-family: "SpaceGrotesk-SemiBold";
      }
    }
  }

  .OfferSliderPortBoxesWrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 20px;

    .OfferSliderPortBox {
      margin: 5px;
      display: inline-block;
      overflow: hidden;
      border-radius: 5px;
      height: 135px;
      width: 30%;
      min-width: 135px;

      .OfferSliderPortImage {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .OfferSliderWrapper {
    max-width: 450px;
  }
}

@media only screen and (max-width: 999px) {
  .OfferSliderWrapper {
    max-width: 100%;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 768px) {
  .OfferSliderWrapper {
    max-width: 90vw;
    box-sizing: border-box;
    width: auto;
    height: 100%;
    order: 1;
    margin-top: 0;
    padding: 15px;

    .popUpImageYachts .popupInner {
      background-color: transparent !important;
      width: 70%;
      margin-top: 5px;

      button .closeButton {
        color: white;
      }
    }

    .popUpImageYachts .popupInner img {
      height: 250px;
    }

    .OfferSliderPortBoxesWrapper {
      justify-content: center;
    }

    h3 {
      width: 100%;
      margin-top: 20px;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 22px;
      margin-top: 20px;
      font-family: "SpaceGrotesk-SemiBold";
    }
  }
}
