#reservationInfo {
    background-color: #e4f4fb;
    width: 100%;
    min-height: 800px;
    padding-bottom: 50px;

    .container {
        width: 1000px;
        margin: auto;
        max-width: 90vw;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-top: 50px;

        h2 {
            font-size: 28px;
            margin-bottom: 15px;
            margin-top: 0;
        }

        p {
            width: 700px;
            text-align: center;
            max-width: 90vw;
        }

        .boxes {
            width: 1100px;
            max-width: 90vw;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            align-items: center;
            margin-top: 50px;

            .oneBox {
                width: 350px;
                box-shadow: 2px 26px 68px -21px #b8b6b8;
                height: 300px;
                border-radius: 5px;
                background-color: white;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: column;
                min-height: 300px;
                padding: 10px 30px;
                box-sizing: border-box;

                p {
                    width: auto;
                    text-align: center;
                    margin: 0;
                    color: rgba(43, 51, 63, 0.86);
                    font-size: 15px;
                }

                h4 {
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .wayOfReservation#notResponsive {
        width: 100%;
        margin-top: 30px;

        .rwt__tablist {
            display: flex;
            width: 600px;
            max-width: 90vw;
        }

        .rwt__tab {
            padding: 20px 25px !important;
            cursor: pointer;

            span {
                display: block;
                font-size: 13px;
            }

            &[aria-selected="true"] {
                border-radius: 5px 5px 0 0;
                background: white;
                font-family: "SpaceGrotesk-Medium";

                &:after {
                    border-bottom: none;
                }
            }
        }

        #basic-tab-one,
        #basic-tab-two,
        #basic-tab-three {
            background: white;
            height: auto;
            text-align: left;
            min-height: 200px;
            padding-right: 40px;
            box-shadow: 2px 26px 68px -21px #b8b6b8;
            border-radius: 5px;
        }

        .rwt__tablist:not([aria-orientation="vertical"]) {
            border-bottom: none;
        }

        #singleReservation,
        #cabinReservation,
        #yachtReservation {
            display: flex;
            justify-content: flex-start;
            padding: 20px 20px 20px 5px;
            align-items: center;

            .image {
                width: 150px;
                height: 120px;
                padding: 0 40px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .text {
                p {
                    text-align: left;
                    color: #696b69;
                    width: auto;
                }
            }
        }
    }

    .wayOfReservation#responsive {
        display: none
    }
}

@media only screen and (max-width: 767px) {
    #reservationInfo {
        .container {
            padding-left: 0;
            padding-right: 0;

            h2 {
                text-align: center;
            }

            p {

                text-align: left;
                width: 100%;

            }

            .boxes {
                flex-direction: column;

                .oneBox {
                    padding: 0 30px;
                    margin-bottom: 20px;
                    width: auto;

                    
                }
            }
        }

        .wayOfReservation {
            .rwt__tablist {}

            .rwt__tab {
                font-size: 15px;

                &[aria-selected="true"] {
                    &:after {}
                }
            }

            #basic-tab-one,
            #basic-tab-two,
            #basic-tab-three {
                padding: 30px;
            }

            .rwt__tablist:not([aria-orientation="vertical"]) {}

            #singleReservation,
            #cabinReservation,
            #yachtReservation {
                padding: 0;
                justify-content: center;
                flex-wrap: wrap;

                .image {
                    img {}
                }

                .text {
                    p {}
                }
            }
        }

        .wayOfReservation#responsive {
            h2 {
                margin-top: 40px;

            }



            .header {
                font-family: 'SpaceGrotesk-SemiBold';
                font-size: 20px;
                color: #444;
            }

            .accordion__button:before {

                order: 2;
            }

            .accordion__button {
                justify-content: space-between;
                width: auto;
                border-radius: 5px;
                background-color: white;
                outline: none;
                display: flex;
                align-items: center;
            }

            .accordion__panel {
                background: white;
                padding-top: 1px;

                .image {
                    // width: 200px;
                    height: 150px;
                    margin: auto;
                    margin-top: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 10px;
                    }
                }
            }

            .accordion {
                margin-top: 20px;
            }


            display: block;
            width: 100%;
        }
    }
}