#yachtSelectionInfo {
    background-color: white;
    width: 100%;
    padding-bottom: 50px;

    .container {
        width: 1100px;
        margin: auto;
        max-width: 90vw;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-top: 40px;

        h2 {
            font-size: 28px;
            margin-bottom: 15px;
            margin-top: 0;
        }

        p {
            width: 500px;
            text-align: center;
            max-width: 90vw;
        }

        .boxes {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;

            .oneBox {
                width: 48%;

                height: auto;
                border-radius: 5px;

                .nameBoatSection {
                    height: 150px;
                    width: 100%;
                    background-color: #4da8e821;
                    border-radius: 5px 5px 0 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    box-sizing: border-box;

                    p {
                        width: auto;
                        margin: 0;
                        margin-left: 50px;
                        font-size: 17px;
                    }

                    .image {
                        width: 200px;
                        height: 120px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 5px;
                        }
                    }
                }

                .description {
                    p {
                        width: auto;
                        // height: 50px;
                        max-width: 90vw;
                        border-bottom: 1px solid #e3e3e3;
                        text-align: left;
                        padding: 20px;
                        color: #808080f2;
                        font-family: "SpaceGrotesk";
                        font-size: 13px;
                        letter-spacing: 0.4px;
                        line-height: 1.4;
                        margin: 0;
                    }
                }
            }
        }
    }

    .containerInputRadio {
        min-height: 200px;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        min-width: 240px;
        width: 240px;
        height: auto;
        border: 1.5px solid #80808075;
        border-radius: 5px;
        margin-top: 35px;
        margin-right: 30px;
        box-sizing: border-box;
        width: 100%;
        margin-top: 0;
        padding: 0;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:hover input~.checkmarkRadio {
                background-color: white;
            }

            &:checked~.checkmarkRadio {
                background-color: white;
                border: 2px solid black;

                &:after {
                    display: block;
                }
            }

            &:checked~.oneBox2 {
                border: 2px solid black;
                margin-top: -2px;
            }
        }

        span.checkmarkRadio {
            background-color: white;
            border-radius: 50%;
            position: absolute;
            top: 65px;
            left: 13px;
            height: 20px;
            width: 20px;
            border: 2px solid #80808075;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                display: none;
                cursor: pointer;
            }

            &:after {
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                // background: #123852fc;
                transform: translate(-50%, -50%);
            }
        }

        .oneBox2 {
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 5px;
            margin: -1px;
            cursor: pointer;
        }
    }

    .boxes#responsive {
        display: none
    }
}

#notResponsive {
    display: block;
}
@media only screen and (max-width: 767px) {
    #yachtSelectionInfo {
        width: auto;

        .container {
            h2 {}

            p {
                text-align: left;
                width: 100%;
                max-width: 100%;
            }

            .boxes {
                width: 100%;
                flex-wrap: wrap;

                .oneBox {
                    width: 100%;
                    margin-bottom: 50px;

                    .nameBoatSection {
                        flex-direction: column;
                        height: 180px;
                        justify-content: center;

                        p {
                            margin-bottom: 10px;
                            margin-left: 0;
                        }

                       
                    }

                    .description {
                        p {}
                    }
                }
            }
        }

        .containerInputRadio {
            span.checkmarkRadio {
                top: 85px;
            }

            input {}

            .oneBox2 {}
        }

        .boxes#responsive {
            padding: 20px;
            h2 {
                margin-top: 40px;
            }

            .header {
                font-family: 'SpaceGrotesk-SemiBold';
                font-size: 20px;
                color: #444;
            }

            .accordion__button:before {

                order: 2;
            }

            .accordion__button {
                justify-content: space-between;
                width: auto;
                border-radius: 5px;
                background-color: #4da8e821;
                outline: none;
                display: flex;
                align-items: center;
            }

            .accordion__panel {
                background: white;
                padding-top: 1px;
                .image {
                    // width: 200px;
                    // height: 100px;
                    margin: auto;
                    margin-top: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }
            }

            .accordion {
                margin-top: 20px;
            }


            display: block
        }

    }

    #notResponsive {
        display: none;
    }

}