#sailingTrainingLp {
    padding-top: 90px;
    min-height: 1000px;
    overflow-x: hidden;

    a {
        margin: auto;
        margin-top: 40px;
    }

    #DayPlan {
        padding-bottom: 70px;
        padding-top: 70px;
    }

    h3, h1 {
        text-align: center;
        width: 800px;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        font-family: "SpaceGrotesk-Bold";
    }

    h2 {
        font-size: 25px;
    }

    .slider {
        width: 100%;
        height: 450px;
        position: relative;

        h2 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            font-size: 35px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }

    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 50px;

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;
            padding-top: 50px;


            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: auto;
                width: 1000px;
                margin: auto;
                flex-wrap: wrap;
                padding-top: 40px;

                .textSection {
                    width: 45%;

                    h2.infoSkipper {
                        margin-bottom: 0;
                        margin-top: 20px;
                        font-size: 20px;
                        font-family: 'SpaceGrotesk';
                    }

                    ol,
                    ul {
                        li {
                            line-height: 1.3;
                            margin-bottom: 15px;

                            span {
                                font-family: "SpaceGrotesk-SemiBold";
                            }
                        }
                    }

                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;

                        span {
                            font-family: "SpaceGrotesk-SemiBold";
                        }
                    }
                }

                .textSection.plan {

                    h2 {
                        margin-bottom: 20px;
                    }

                    P {
                        margin: 10px 0;
                    }
                }

                .imageSection {
                    width: 45%;
                    height: 300px;

                    &#imgDayPlan {
                        height: 400px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: 100%;

                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                            max-height: 280px;
                            // height: 250px;
                        }

                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }

                .textSection {
                    width: 50%;
                }
            }

            .secondSection#whyIsWorth {
                padding-top: 0;
                padding-bottom: 50px;

                h2 {
                    margin-bottom: 0;
                    margin-top: 20px;
                    font-size: 20px;
                    font-family: 'SpaceGrotesk';
                }
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        flex-wrap: wrap;

        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;

            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: center;
            }
        }
    }

    .asInfoContainer {
        width: 1000px;
        padding: 50px;
        padding-bottom: 0;
        margin: auto;
        max-width: 90vw;
        padding-top: 20px;

        div {
            padding: 20px;
            border-radius: 10px;
            box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);

            p {
                margin-top: 20px;
                padding-left: 22px;
            }

            ol,
            ul {
                li {
                    line-height: 1.7;
                    margin-bottom: 15px;

                    span {
                        font-family: "SpaceGrotesk-SemiBold";
                    }
                }
            }
        }
    }
    .secondaryBtn {
        margin: auto;
        margin-top: 40px;
        display: flex;
    }

}

@media only screen and (max-width: 767px) {
    #sailingTrainingLp .info .container .secondSection#whyIsWorth {
     h2 {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 18px;
        font-family: 'SpaceGrotesk';
    }
    ul {
        padding-left: 25px;
    }
}
    #sailingTrainingLp {
        padding-top: 70px;
        h3, h1 {
            width: auto;
            text-align: left;
            font-size: 25px;
        }

        .asInfoContainer {
            padding: 0;
            width: auto;
            ol {
                padding-left: 25px
            }
        }

        .tripInfo {
            h3, h1 {
                text-align: center;
            }
        }

        .slider {
            h2 {}

            img {}
        }

        .info {
            .container {
                box-sizing: border-box;
                padding-top: 10px;

                .firstSection,
                .secondSection {
                    padding-top: 0;
                    width: auto;

                    .textSection {
                        width: 100%;
                        margin-bottom: 30px;

                        h2 {
                            margin-top: 30px;
                            

                        }

                        P {}

                        ol,
                        ul {
                            li {
                                line-height: 1.3;
                                margin-bottom: 15px;

                                span {
                                    font-family: "SpaceGrotesk-SemiBold";
                                }
                            }
                        }
                    }

                    .imageSection {
                        width: 100%;
                        order: 2;
                        img {}
                    }
                }

                .secondSection {
                    .imageSection {
                        img {
                            &.firstPhoto {
                                margin-bottom: 20px;
                            }

                            &.secondPhoto {
                                margin-bottom: 20px;
                                width: 100%;
                            }
                        }
                    }

                    .textSection {
                        h2 {
                            margin-bottom: 20px;
                        }

                        ol,
                        ul {
                            li {
                                line-height: 1.3;
                                margin-bottom: 15px;

                                span {
                                    font-family: "SpaceGrotesk-SemiBold";
                                }
                            }
                        }
                    }
                }
            }
        }

        .tags {
            width: auto;
            justify-content: center;

            .oneTag {
                margin-bottom: 30px;

                img {}

                p {}
            }
        }
        a {
            order: 3;
           
        }
    }
}