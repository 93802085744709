.infoBoxWrapper {
  display: flex;
  column-count: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 20px auto 0;
  padding: 15px;
  margin-top: 300px;
  margin-right: 40px;

  .availability {
    font-family: "SpaceGrotesk-SemiBold";
    margin-top: 5px;
    color: white;

    &.red {
      background: #860202;
      padding: 7px 0;
      margin-top: 10px;
      width: 230px;
      text-align: center;
    }

    &.green {
      background: green;
      padding: 7px 0;
      margin-top: 10px;
      width: 230px;
      text-align: center;
    }

    &.orange {
      background: orange;
      padding: 7px 0;
      margin-top: 10px;
      width: 230px;
      text-align: center;
    }
  }

  .newMessage {
    background: green;
    text-align: center;
    width: 230px;
    padding: 0;
    margin: 0;
    p {
      color: white !important;
      margin: 5px;
      padding: 0;
    }
  }

  .bookButtonWrapper {
    display: none;
  }

  h3 {
    font-family: "SpaceGrotesk-Medium";
    font-size: 26px;
    margin-bottom: 0px;
  }
}

#infoBoxPriceParagraph {
  margin-top: 0px;
}

.infoBoxPrice {
  color: #000000;
  margin-top: 10px;
}

.infoBoxPersons {
  color: #ffffff;
}

.infoBoxAvailable {
  color: #000000;
  font-weight: bold;
}

.infoBoxPriceStrikethrought {
  text-decoration: line-through;
  color: #860202;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .infoBoxWrapper {
    text-align: center;
    margin: auto;
    margin-top: 50px;
    

    .bookButtonWrapper {
      display: block;
      margin: 0;
      padding: 0;
    }

    h3 {
      margin-top: 10px;
    }
  }
}
