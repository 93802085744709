#tourDetails {
    margin-top: 50px;
    width: 90%;
    padding-bottom: 30px;

    .mainTitleTrip {
        display: flex;
        position: relative;
        height: 100px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h2 {
            text-align: left;

            span {
                display: block;
                font-size: 15px;
                margin-top: 10px;
                background: #ffecb2;
                width: max-content;
                padding: 7px 20px;
                border-radius: 5px;
                color: orange;
                font-family: "SpaceGrotesk-SemiBold";
            }
        }

        .priceBox {
            border-radius: 10px;
            padding: 15px;
            // background: #f6fbfd;
            box-shadow: -1px 2px 12px 2px #b8b6b830;
            height: 100%;
            box-sizing: border-box;

            p {
                text-align: center;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 13px;
                width: 120px;

                &.price {
                    font-size: 22px;
                }
            }
        }
    }

    .menuContainer {
        padding-bottom: 5px;
        min-width: 300px;

        .navMenu {
            display: flex;
            justify-content: space-around;
            width: 100%;
            border-bottom: 3px solid #80808029;
            padding-bottom: 13px;
            min-width: 700px;
            padding-top: 10px;

            li {
                list-style-type: none;
                position: relative;

                a {
                    border-bottom: none;
                    text-decoration: none;

                    &.active {
                        padding-bottom: 15px;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            width: 100%;
                            border-bottom: 3px solid #0a1e2c;
                            top: 35px;
                            padding: 0 17px;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #tourDetails {
        width: 100%;

        .mainTitleTrip {
            display: block;
            height: auto;

            h2 {
                margin-bottom: 0;
                margin-top: 0;
            }

            .priceBox {
                width: fit-content;
                margin: auto;
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }

        .menuContainer {
            overflow: auto;
        }
    }
}
