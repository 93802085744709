#responsiveAccommodation {
  display: none;
}

#offerResidenceWrapper {
  .orangeBox {
    position: absolute;
    bottom: -35px;
    background-color: #be4348;
    z-index: 1;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    max-width: 100%;
    p {
      color: white;
      text-align: center;
    }
  }

  .listTabsMenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: left;
    line-height: 30px;
    width: 50%;
    background: #f2f2f2;

    li:hover {
      border-radius: 5px 5px 0 0;
      background: #00000033;
    }

    .activeTab:hover {
      border-radius: 5px 5px 0 0;
      background: #ffffff;
    }

    li {
      font-weight: bold;
      font-size: 1em;
      text-align: center;
      flex: 2 0 auto;
      height: auto;
      box-sizing: border-box;
      padding: 5px;
      cursor: pointer;

      p {
        cursor: pointer;
      }
    }

    .activeTab {
      border-radius: 5px 5px 0 0;
      background: #ffffff;
      box-shadow: -3px -15px 20px 0px rgba(222, 202, 202, 0.21);
      max-width: fit-content;

      p {
        font-family: "SpaceGrotesk-Medium";
      }
    }

    li {
      max-width: fit-content;
      padding: 10px 30px;
    }
  }

  .offerResidenceRoomsTitle {
    text-align: center;
    padding: 0;
  }
}

.residencesTab {
  margin-top: 0px;
  padding: 50px;
  box-shadow: -3px -15px 20px 0px rgba(222, 202, 202, 0.21);
  background: #ffffff;

  h3 {
    margin-top: 0;
    padding-top: 1em;
  }

  .offerResidencesButton {
    display: flex;
    justify-content: center;

    button {
      max-width: 320px;
    }
  }
}

.offerResidenceRoomsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.offerResidenceRoom {
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 50px;
  display: flex;
  background: #f2f2f2;
  box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.15);
  position: relative;

  .infoAboutPlace {
    position: absolute;
    top: 80%;
    color: white;
    left: 50%;
    transform: translate(-50%);
    background: #ffa500bf;
    height: 30px;
    width: 100%;
    text-align: center;

    p {
      min-height: 20px;
      margin: 0;
      padding: 2px;
      color: white;
    }
  }

  .overlayResidence {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0808085c;
    z-index: 1;
    border-radius: 15px;

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }

    div {
      color: #102e42;
      top: 50%;
      left: 50%;
      position: absolute;
      font-size: 60px;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      padding: 5px;
      box-sizing: border-box;
      font-weight: 600;
    }
  }

  .popup-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;

    .popupImgGallery {
      .image {
        img {
          width: 100%;
        }
      }
    }

    .box {
      margin: auto;
      max-width: 1000px;
      padding: 25px;
      border-radius: 5px;
      position: relative;
      background: #fff;
      display: flex;

      .popupContent {
        width: 40%;
        padding-right: 20px;
        box-sizing: border-box;
      }

      .popupImgGallery {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .ColageImages {
          display: flex;
          flex-wrap: wrap;
          width: 60%;

          .image {
            width: 50%;
            box-sizing: border-box;
            padding: 10px;
            height: 200px;

            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }

            img {
              border-radius: 5px;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }

        .PlanImage {
          width: 40%;

          img {
            cursor: pointer;
            max-height: 400px;
            object-fit: contain;
          }

          &:hover {
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }

      .close-icon {
        position: absolute;
        right: 12px;
        top: 4px;
        line-height: 1;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
      }

      .popup-title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0;
      }
    }
  }

  .RoomLeft {
    width: 30%;
    height: 100%;

    .image {
      height: 100%;
      position: relative;

      &:before {
        content: "i";
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        border: 1px solid #fff;
        padding: 2px 10px;
        border-radius: 50%;
        cursor: pointer;
      }

      &:hover {
        opacity: 0.5;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 30px;
      margin-top: 0;
      min-height: 150px;
      height: 100%;
    }

    .offerResidenceRoomTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-size: 17px;
        width: 100%;
        font-family: "SpaceGrotesk-SemiBold";
        margin-top: 5px;
        margin-bottom: 20px;

        span {
          color: #12b342;
          font-size: 16px;
          float: right;
          font-family: "SpaceGrotesk-SemiBold";
        }
      }
    }
  }

  .RoomRight {
    display: flex;
    flex-direction: column;
    width: 70%;
    box-sizing: border-box;
    padding: 15px;

    .RoomRightContent {
      display: flex;
      flex-wrap: wrap;

      .RoomRightItem {
        display: flex;
        align-items: center;
        width: 33%;
        margin-bottom: 25px;

        .RoomRightItem-Value {
          margin: 0;
          color: #0384fe;
          font-weight: 600;
          padding-left: 15px;
          font-size: 20px;
        }

        .RoomRightItem-Icon {
          min-width: 75px;

          p {
            margin: 0 auto;
            font-weight: 600;
            font-size: 10px;
            text-align: center;
            max-width: 70px;
          }
        }

        .RoomRightItem-Icon1 {
          div {
            background: url("./images/offerResidence-icon1.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }

        .RoomRightItem-Icon2 {
          div {
            background: url("./images/offerResidence-icon2.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }

        .RoomRightItem-Icon3 {
          div {
            background: url("./images/offerResidence-icon3.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }

        .RoomRightItem-Icon4 {
          div {
            background: url("./images/offerResidence-icon4.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }

        .RoomRightItem-Icon5 {
          div {
            background: url("./images/offerResidence-icon5.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }

        .RoomRightItem-Icon6 {
          div {
            background: url("./images/offerResidence-icon6.png") no-repeat;
            background-position: center;
            min-height: 55px;
          }
        }
      }
    }

    .RoomRightTitle {
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }

      .priceBox {
        background: #12b342;
        color: #fff;
        padding: 10px 15px;
        border-radius: 30px;
        text-align: center;
        font-size: 18px;
        box-shadow: -9px 9px 20px -10px #00000042;
        font-size: 16px;

        span {
          line-height: 1;
          color: white;
        }

        strong {
          color: #fff;
        }
      }
    }

    .ColageImages {
      width: 58%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      height: 300px;

      .image {
        width: 200px;
        height: 140px;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }

        img {
          height: 100%;
          cursor: pointer;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
          // box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.20);
          margin-bottom: 12px;
        }
      }
    }

    .PlanImage {
      width: 200px;
      height: 100%;
      margin-left: 10px;
      cursor: pointer;

      img {
        height: 290px;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
        // box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.20);
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .offerResidenceRoom {
    .RoomRight {
      .RoomRightContent {
        .RoomRightItem {
          flex-direction: column;

          .RoomRightItem-Value {
            padding-left: 0;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .offerResidenceRoom .overlayResidence {
    img {
      object-fit: cover;
    }

    div {
      font-size: 40px;
    }
  }

  #offerResidenceWrapper {
    .listTabsMenu {
      width: 100%;
      overflow: auto;
    }

    .offerResidenceRoomsTitle {
      margin: 50px 0;
      font-size: 18px;
      text-align: left;
    }
  }

  .offerResidenceRoom {
    .popup-box {
      .box {
        flex-direction: column;

        .popupContent {
          width: 100%;
          padding: 0;
        }

        .popupImgGallery {
          width: 100%;
        }
      }
    }
  }

  .residencesTab {
    padding: 15px;
  }

  .offerResidenceRoomsWrapper {
    width: auto;
  }

  .offerResidenceRoomsWrapper .offerResidenceRoom {
    padding: 0;
    box-sizing: border-box;
    flex-direction: column;

    .RoomLeft {
      width: 100%;
      height: 150px;

      .image {
        img {
          border-radius: 0;
        }
      }
    }

    .RoomRight {
      width: 100%;

      .ColageImages {
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        justify-content: space-between;
        height: 180px;

        .image {
          min-width: 150px;
          margin: 0 5px;
        }
      }
    }

    .PlanImage {
      margin-left: 0;
      height: auto;
      text-align: center;
      margin-top: 10px;
      width: 200px;

      img {
        height: 250px;
        object-fit: contain;
        border-radius: 10px;
        transform: rotate(90deg);
        width: 100%;
        margin-top: -30px;
      }
    }
  }

  #responsiveAccommodation {
    display: block;
    .orangeBox {
      position: absolute;
      // bottom: -35px;
      // background-color: rgb(255, 236, 178);
      background-color: #be4348;
      // color: white;
      z-index: 1;
      padding: 10px;
      border-radius: 0 0 10px 10px;
      p {
        color: white;
        text-align: center;
      }
    }

    .accordion__button:before {
      order: 2;
    }

    .accordion__button {
      justify-content: space-between;
      width: auto;
      border-radius: 5px;
      background-color: white;
      outline: none;
      display: flex;
      align-items: center;
    }

    .accordion__panel {
      background: white;
      padding: 0;
    }

    .accordion {
      margin-top: 20px;
    }

    .offerResidenceRoomTitle {
      h5 {
        margin: 0;
        font-size: 18px;
        margin-left: 10px;

        span {
          color: green;
          font-family: "SpaceGrotesk-SemiBold";
          display: block;
          margin-top: 10px;
          font-size: 15px;

          strong {
            color: green;
          }
        }
      }
    }

    .offerResidenceRoom {
      padding: 0;
      width: 100%;
      // max-width: 80vw;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;

      p {
        font-size: 15px;
      }

      .offerResidenceRoomTitle {
        align-items: baseline;
        flex-direction: column;

        h5 {
          margin: 0;
          margin-bottom: 10px;

          span {
            margin-top: 10px;
            float: none !important;
            display: flex;
          }
        }
      }

      .offerResidenceRoomImagesWrapper {
        flex-wrap: nowrap;
        flex-direction: row;

        img {
          max-width: 70%;

          margin-right: 20px;
        }
      }
    }

    .offerResidenceRoom {
      padding: 0;
      box-sizing: border-box;
      flex-direction: column;
      box-shadow: none;
      background: none;

      .RoomLeft {
        width: 100%;
        height: 150px;

        img {
          border-radius: 0;
        }
      }

      .RoomRight {
        width: 100%;

        .RoomRightTitle {
          display: none;
        }

        .ColageImages {
          flex-wrap: nowrap;
          flex-direction: row;
          overflow-x: auto;
          width: 95%;
          justify-content: space-between;
          height: 180px;
          display: flex;

          .image {
            min-width: 150px;
            margin: 0 5px;
            max-height: 150px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 5px;
              // box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.20);
              margin-bottom: 12px;
            }
          }
        }
      }

      .PlanImage {
        overflow: hidden;

        img {
          height: 250px;
          object-fit: contain;
          border-radius: 10px;
          transform: rotate(90deg);
          width: 100%;
          margin-top: -30px;
        }
      }
    }
  }
}

#offerResidenceWrapper {
  .listTabsMenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: left;
    line-height: 30px;
    width: 50%;
    background: #f2f2f2;

    li {
      font-weight: bold;
      font-size: 1em;
      text-align: center;
      flex: 2 0 auto;
      height: auto;
      box-sizing: border-box;
      padding: 5px;
      cursor: pointer;

      p {
        cursor: pointer;
      }
    }

    .activeTab {
      border-radius: 5px 5px 0 0;
      background: #ffffff;
      box-shadow: -3px -15px 20px 0px rgba(222, 202, 202, 0.21);
      max-width: fit-content;
    }

    li {
      max-width: fit-content;
      padding: 10px 30px;
    }
  }

  .offerResidenceRoomsTitle {
    text-align: center;
    padding: 20px 0;
  }
}

.residencesTab {
  margin-top: 0px;
  padding: 50px;
  box-shadow: -3px -15px 20px 0px rgba(222, 202, 202, 0.21);
  background: #ffffff;

  h3 {
    margin-top: 0;
    padding-top: 1em;
  }
}

.offerResidenceRoomsWrapper.academy {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1100px;
  margin: auto;

  .offerResidenceRoom {
    width: 45%;
    display: block;
    border: 1px solid #80808070;
    border-radius: 5px;
    padding: 0 15px 15px;
    box-sizing: border-box;
    margin-bottom: 20px;

    p {
      margin-bottom: 30px;
      margin-top: 0;
      min-height: 150px;
    }

    .offerResidenceRoomTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-size: 17px;
        width: 100%;
        font-family: "SpaceGrotesk-SemiBold";

        span {
          color: #12b342;
          font-size: 16px;
          float: right;
          font-family: "SpaceGrotesk-SemiBold";
        }
      }
    }

    .offerResidenceRoomImagesWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      img {
        max-width: 45%;
        height: 150px;
        border-radius: 5px;
        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
        width: 100%;
        margin-bottom: 20px;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #offerResidenceWrapper {
    display: none;

    .listTabsMenu {
      width: 100%;

      li {
        padding: 10px 10px;
        font-size: 14px;
      }
    }

    .offerResidenceRoomsTitle {
      margin: 50px 0;
      font-size: 18px;
      text-align: left;
    }
  }

  .residencesTab {
    padding: 15px;
  }

  .offerResidenceRoomsWrapper.academy {
    .offerResidenceRoom {
      padding: 0 10px;
      width: 100%;

      p {
        font-size: 15px;
      }

      .offerResidenceRoomTitle {
        align-items: baseline;
        flex-direction: column;
        margin: 20px 0;

        h5 {
          margin: 0;
          margin-bottom: 10px;
        }
      }

      .offerResidenceRoomImagesWrapper {
        flex-wrap: nowrap;
        flex-direction: row;

        img {
          max-width: 70%;
          object-fit: cover;
          margin-right: 20px;
        }
      }
    }
  }
}
