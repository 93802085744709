#secureBookingWrapper {
    padding: 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("./img/secure-booking_bg.jpg") center no-repeat;
    background-size: cover;

    .secureBookingTitle {
        text-align: center;
        padding: 0 15px;
        margin-bottom: 50px;

        h2 {
            color: #fff;
            margin: 0;
        }

        p {
            color: #fff;
        }
    }

    .slick-prev {
        left: 25px;
    }

    .slick-next {
        right: 25px;
    }

    .slick-arrow {
        z-index: 1;
        width: 45px;
    }

    .slick-arrow:before {
        font-size: 40px;
    }

    .slick-dots {
        bottom: 0;
    }

    .slick-dots li button::before {
        font-size: 30px;
        color: white;
    }

    .slick-dots li {
        width: 50px;
    }
}

@media only screen and (max-width: 770px) {
    #secureBookingWrapper {
        .slick-slider {
            padding-bottom: 75px;
        }
    }
}

@media only screen and (max-width: 480px) {
    #secureBookingWrapper {
        .slick-list,
        .slick-slider,
        .slick-track {
            height: auto;
        }

        .slick-slider {
            width: 100%;
        }
    }
}
