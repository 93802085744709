#stepsNumber {
    height: 320px;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 290px;
        padding-top: 80px;
        box-sizing: border-box;
        margin: auto;
        position: relative;
        width: 75%;
        padding-right: 0;
        padding-left: 0;

        .line {
            position: absolute;
            border-top: 2px solid #c3edff;
            width: 95%;
            z-index: -1;
            left: 50%;
            top: 185px;
            transform: translate(-50%);
        }

        .oneBox {
            position: relative;
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .circle {
                width: 52px;
                height: 52px;
                border-radius: 50%;
                background-color: #c3edff;
                display: flex;
                justify-content: center;
                p {
                    display: flex;
                    position: inherit;
                    align-items: center;
                    font-family: "SpaceGrotesk-Medium";
                    font-size: 22px;
                    width: auto;
                }

                &.active {
                    background-color: #ffd15f;
                }
            }

            p {
                position: absolute;
                top: 50px;
                width: 130px;
                text-align: center;
                font-size: 15px;

                &.title {

                    &.active {
                        font-family: "SpaceGrotesk-Medium";
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #stepsNumber {
        padding: 20px 0;
        height: 180px;
        .container {
            height: 200px;
            width: 80%;
            padding-top: 100px;
            
            .line {
                top: inherit;
            }

            .oneBox {
                .circle {
                    width: 35px;
                    height: 35px;
                    p {
                        font-size: 15px;
                    }

                    &.active {
                    }
                }

                p {
               

                &.title {
                    display: none;
                    &.active {
                       
                    }
                }
            }
            }
        }
    }
}
