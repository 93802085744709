#sailingTraining {
    padding-top: 90px;
    min-height: 1000px;
    // overflow-x: hidden;

    #boxFiltersContainer {
        flex-direction: row;
        justify-content: flex-start;

        .boxFilter {
            padding: 10px;
        }
    }

    .slider {
        width: 100%;
        height: 450px;
        position: relative;

        h2, h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            font-size: 35px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }

    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 100px;

                .textSection {
                    width: 40%;

                    h2, h1 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }

                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;

                        span {
                            font-family: "SpaceGrotesk-SemiBold";
                        }
                    }
                }

                .textSection.plan {
                    h2, h1 {
                        margin-bottom: 20px;
                    }

                    P {
                        margin: 10px 0;
                    }
                }

                .imageSection {
                    width: 45%;
                    height: 320px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;

                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }

                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }

                .textSection {
                    width: 45%;
                }
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        flex-wrap: wrap;

        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;

            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: center;
            }
        }
    }

    .asInfoContainer {
        width: 900px;
        padding: 50px;
        padding-bottom: 0;
        margin: auto;
        max-width: 90vw;


        ul {
            li {
                line-height: 1.7;
                margin-bottom: 15px;

                span {
                    font-family: "SpaceGrotesk-SemiBold";
                }
            }
        }
    }

    .container {

        .firstSection,
        .secondSection {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: auto;
            flex-wrap: wrap;
            padding-top: 60px;

            .textSection {
                width: 40%;

                span {
                    font-family: "SpaceGrotesk-SemiBold";
                }

                h2, h1 {
                    font-size: 25px;
                    margin-bottom: 0;
                    margin-top: 10px;
                }

                P {
                    font-size: 16px;
                    font-family: "SpaceGrotesk";
                    line-height: 1.6;
                    margin-bottom: 25px;
                }
            }

            .imageSection {
                width: 45%;
                height: 320px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                }
            }
        }

        .secondSection {
            flex-direction: row-reverse;

            .imageSection {
                width: 40%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: auto;
                max-height: auto;

                img {
                    &.firstPhoto {
                        width: 90%;
                        height: 200px;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 250px;
                    }

                    &.secondPhoto {
                        width: 80%;
                        height: 150px;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 200px;
                        margin-top: 20px;
                    }
                }
            }

            .textSection {
                width: 45%;
            }
        }
    }

    .secondaryBtn {
        margin: auto;
        margin-top: 50px;
        display: flex;
    }
}

@media only screen and (max-width: 767px) {
    #sailingTraining {
        padding-top: 70px;
        #tripFilter {
            display: block;
            transform: none;
        }
        .secondaryBtn {
           
            width: 300px;
        }
        #boxFiltersContainer {
            flex-direction: column;
            padding-bottom: 0;
            
        }

        .asInfoContainer {
            padding-left: 20px;
        }

        .tripInfo {
            h3 {
                text-align: center;
            }
        }

        .slider {
            h2, h1 {}

            img {}
        }

        .info {
            .container {
                box-sizing: border-box;
                padding-top: 40px;

                .firstSection,
                .secondSection {
                    padding-top: 0;

                    .textSection {
                        width: 100%;

                        h2, h1 {
                            margin-top: 30px;
                        }

                        P {}
                    }

                    .imageSection {
                        width: 100%;

                        img {}
                    }
                }

                .secondSection {
                    .imageSection {
                        img {
                            &.firstPhoto {
                                margin-bottom: 20px;
                            }

                            &.secondPhoto {
                                margin-bottom: 20px;
                                width: 100%
                            }
                        }
                    }

                    .textSection {
                        h2, h1 {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }

        .tags {
            width: auto;
            justify-content: center;

            .oneTag {
                margin-bottom: 30px;

                img {}

                p {}
            }
        }
    }
}