.oneCruiseProgramBox {
    width: auto;
    height: 350px;
    text-align: center;
    padding: 30px 0;
    max-width: 350px;
    margin: auto;
    cursor: pointer;
    
    img {
        width: 70px;
        height: 70px;
    }

    .imageHover {
        display: none;
    }

    .image {
        display: block;
    }

    &:hover h4,
    &:active h4,
    &:focus-within h4 {
        color: #ffd15f;
    }
    &:hover p,
    &:active p,
    &:focus-within p {
        color: #ffd15f;
    }
    &:hover .imageHover,
    &:active .imageHover,
    &:focus-within .imageHover {
        display: block;
    }

    &:hover .image,
    &:active .image,
    &:focus-within .image {
        display: none;
    }

    &:hover .primaryBtn,
    &:active .primaryBtn,
    &:focus-within .primaryBtn {
        border: 1px solid #ffd15f;
        background-color: #ffd15f;
        color: black;
    }

    i {
        color: white;
        font-size: 42px;
    }

    h4 {
        color: white;
        font-size: 25px;
        text-align: center;
        margin: 30px 0;
        font-family: "SpaceGrotesk";
    }

    p {
        text-align: center;
        font-family: "SpaceGrotesk";
        color: white;
        padding: 0 20px;
        min-height: 100px;
    }

    button {
        background-color: transparent;
        color: white;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        border-left: 2px solid white;
        border-right: 2px solid white;
        margin-top: 20px;
    }

    button:hover,
    button:active {
        color: black;
        background-color: #ffd15f;
        box-shadow: none;
        border: none;
    }
}

@media only screen and (max-width: 767px) {
    .oneCruiseProgramBox {

        button {
            margin-top: 0px;
        }
    }

    .slick-dots {
        bottom: 10px;
    }
}
