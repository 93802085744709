#cruiseList {
    padding: 0;
    background-color: white;
    h1 {
        text-align: center;
    }

    h3 {
        margin-top: -5px;
    }

    button {
        display: flex;
        margin: auto;
    }
}

@media only screen and (max-width: 767px) {
    #cruiseList {
        button {
            margin-bottom: 50px;
        }
    }
}
