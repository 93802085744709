#panelMenu {
   
        .primaryBtn {
          margin-top: 20px;
            margin-left: 20px;
        
    }
    .panelBox {
        width: 80%;
        height: 100%;
        margin-left: auto;
        min-height: 700px;
    }

    .rwt__tab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 20px !important;
        cursor: pointer;
        font-weight: 500;
    }

    .rwt__tabs[data-rwt-vertical="true"] {
        display: flex;
        height: auto;
        margin-left: 160px;
    }

    .rwt__tablist[aria-orientation="vertical"] {
        width: 220px;
        margin-top: 250px;
        height: fit-content;
        border-right: none !important;
        background: white;
        border-radius: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 0.13);
    }
    #vertical-tab {
        &-one,
        &-two,
        &-three,
        &-four,
        &-five,
        &-six,
        &-seven {
            background-color: white;
            min-height: 700px;
            padding: 100px 25px 10px 25px;
            padding-bottom: 70px;
        }
    }

    .arrow {
        border: solid #041928;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: none;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    
    .left {
        display: inline-block;
        transform: rotate(-230deg);
        -webkit-transform: rotate(-230deg);
        margin-right: 5px;
    }
   

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
        right: 0 !important;
        border-right: none !important;
        background: #8080801f;
        width: 100%;
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"] > .arrow {
        display: inline-block;
        margin-left: auto;
        margin-top: 6px;
    }

   

    
}

@media only screen and (max-width: 767px) {
    #panelMenu {
        padding-top: 100px;
        .panelBox {
            margin-left: 0;
            width: 100%;
        }
        .rwt__tabs[data-rwt-vertical="true"] {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-left: 0;
        }

        .rwt__tablist[aria-orientation="vertical"] {
            margin-right: 1rem;
            width: 250px;
            justify-content: center;
            margin: 20px auto !important;
        }

        .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            border: transparent;
        }

        #vertical-tab-one,
        #vertical-tab-two,
        #vertical-tab-three,
        #vertical-tab-four,
        #vertical-tab-five,
        #vertical-tab-six,
        #vertical-tab-seven {
            padding: 20px;
            padding-bottom: 70px;
            width: auto;
        }
    }
}
