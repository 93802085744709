#sectionResort {
  margin-top: -150px;
}

#sectionOfferTripMap {
  padding-left: 70px;
  padding-right: 70px;
  background-color: #f2f2f2;
  padding-bottom: 50px;

  .disclaimer {
    font-size: 15px;
    color: #808080de;
    font-family: "SpaceGrotesk";
    margin: 0;
  }

  .sectionOfferTripMapInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 50px;
    width: 1100px;
    margin: auto;
    height: 100%;

    img {
      max-width: 45%;
      border-radius: 5px;
      margin: 0;
      object-fit: cover;
      max-height: 400px;
      height: 100%;
    }

    .sectionOfferTripMapShortInfo {
      p {
        margin: 0;
        text-align: center;
      }
    }
  }
}

.OfferTripMapWrapper {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  height: 720px;
}

#offerTripMapHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  h1 {
    margin-bottom: 25px;
    font-size: 35px;
  }
  h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 999px) {
  .OfferTripMapWrapper {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  #sectionOfferTripMap {
    padding: 0 20px 50px 20px;
  }

  #sectionOfferTripMap > div.OfferTripMapWrapper > div:nth-child(1) {
    order: 2;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  #sectionResort {
    margin-top: 0;
  }
  #sectionOfferTripMap {
    .disclaimer {
      margin-top: 40px;
      font-size: 13px;
    }
    .sectionOfferTripMapInfoWrapper {
      flex-direction: column;
      padding-bottom: 30px;
      width: 100%;

      img {
        margin: 10px;
        width: 100%;
        max-width: 100%;
        margin-top: 50px;
        height: 200px;
        object-fit: cover;
      }

      .sectionOfferTripMapShortInfo {
        width: 100%;
      }
    }
  }

  #offerTripMapHeader {
    h1 {
      padding: 0;
      margin: 0;
      font-size: 25px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
