#aboutUs {
    padding-top: 70px;
    min-height: 1000px;
    .slider {
        width: 100%;
        height: 350px;
        position: relative;
        h2,
        h1 {
            color: white;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            margin: 0;
            font-size: 35px;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.9);
        }
    }
    .info {
        background-color: white;
        width: 100%;
        height: 100%;
        min-height: 800px;
        padding-bottom: 100px;

        .container {
            width: 1100px;
            margin: auto;
            height: 100%;
            min-height: 800px;
            max-width: 95vw;

            .firstSection,
            .secondSection {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: auto;
                flex-wrap: wrap;
                padding-top: 100px;
                .textSection {
                    width: 40%;
                    h2,
                    h1 {
                        font-size: 25px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                    }

                    P {
                        font-size: 16px;
                        font-family: "SpaceGrotesk";
                        line-height: 1.6;
                        a {
                            text-decoration: none;
                            font-family: "SpaceGrotesk-Medium";
                        }
                        i.arrow {
                            border: solid black;
                            border-width: 0 2.3px 2.3px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                            margin-left: 6px;
                            margin-bottom: 2px;
                        }
                    }
                }
                .imageSection {
                    width: 45%;
                    height: 320px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                    }
                }
            }

            .secondSection {
                .imageSection {
                    width: 40%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: auto;
                    max-height: auto;
                    img {
                        &.firstPhoto {
                            width: 100%;
                            height: 200px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 250px;
                        }
                        &.secondPhoto {
                            width: 80%;
                            height: 150px;
                            border-radius: 8px;
                            object-fit: cover;
                            height: 200px;
                            margin-top: 20px;
                        }
                    }
                }
                .textSection {
                    width: 45%;
                }
            }
        }
    }
    .tags {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: auto;
        margin-top: 70px;
        .oneTag {
            width: 300px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px 25px 40px;
            box-shadow: 1px 4px 19px 7px rgba(0, 0, 0, 0.06);
            border-radius: 15px;
            img {
                width: 70px;
                height: 70px;
            }

            p {
                font-size: 15px;
                color: black;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #aboutUs {
        padding-top: 70px;
        .info {
            .container {
                box-sizing: border-box;
                .firstSection,
                .secondSection {
                    padding-top: 20px;
                    .textSection {
                        width: 100%;
                        h2,
                        h1 {
                            margin-top: 30px;
                        }
                        P:first-child {
                            margin-top: 30px;
                        }
                        .primaryBtn {
                            margin-bottom: 50px;
                        }
                    }
                    .imageSection {
                        width: 100%;
                        align-items: center;
                        height: auto;
                        order: 1;

                        img {
                            margin-top: 20px;
                        }
                    }
                }

                .secondSection {
                    .imageSection {
                        img {
                            &.firstPhoto {
                                width: 100%;
                            }
                            &.secondPhoto {
                                width: 100%;
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .textSection {
                        h2,
                        h1 {
                            margin-top: 45px;
                        }
                    }
                }
            }
        }
    }
}
