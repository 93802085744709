#newsLetterContainer {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("./img/newsletter-bg.jpg") center no-repeat;
    background-size: cover;

    .containerInputCheckbox span.checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid grey;
        cursor: pointer;
    }

    .containerInputCheckbox span.checkmark:before {
        width: 20px;
        height: 20px;
        border: 2px solid #121111;
        border-radius: 7px;
        margin-left: -1px;
        margin-top: -1px;
    }

    .containerInputCheckbox span.checkmark:after {
        left: 8px;
        top: 6px;
        width: 4px;
        height: 7px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .newsLetterWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px 0;

        .newsLetterTitle {
            text-align: center;
            padding: 0 25px;

            h1 {
                font-size: 30px;
                font-weight: 500;
                margin-top: 0;
                color: #fff;
            }

            p {
                color: #fff;
            }
        }

        .btnBox {
            height: 100%;
        }

        .secondaryBtn {
            width: 150px;
            margin-top: 0;
        }

        .form {
            max-width: 505px;
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 25px;
            position: relative;

            .newsLetterBox form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
            }

            .responseModal {
                background: #fff;
                text-align: center;
                padding: 10px;
                border-radius: 12px;
                font-weight: 600;
                position: absolute;
                width: -webkit-fill-available;
                bottom: 0;
            }

            input[type="email"] {
                width: 300px;
                border-radius: 10px;
                outline: none;
                border: 1px solid #8080808c;
                padding-left: 10px;
                margin-right: 20px;
            }

            ::placeholder {
                color: lightgrey;
                font-size: 15px;
            }

            label {
                font-size: 12px;
                color: #423b3bc4;
                margin-left: 10px;

                p {
                    color: #fff;
                }
            }

            .agree {
                display: flex;
                justify-content: flex-start;
                margin-top: 20px;

                input {
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                    border: 1px solid grey;
                    padding-right: 0;
                    box-sizing: border-box;
                    padding: 23px 15px;
                }

                .textSmall {
                    color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #newsLetterContainer {
        padding: 30px 0;
        justify-content: unset;

        .newsLetterWrapper {
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;

            .form {
                flex-direction: column;
                width: 90vw;
                input[type="email"] {
                    margin-bottom: 30px;
                    margin-right: 0;
                    width: 280px;
                }

                label {
                    margin-left: 10px;
                    text-align: left;
                }

                .newsLetterBox form {
                    flex-direction: column;
                    .secondarybtn {
                        padding: 5px;
                    }
                }
                .agree {
                    margin-top: 25px;
                    justify-content: center;
                }
            }
            .newsLetterTitle {
                width: auto;
                margin-right: 0;
            }
        }
    }
}
