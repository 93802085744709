#infoDestination {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 50px 0 0;
    margin-bottom: 20px;
    margin-top: 20px;

    .container {
        width: 1100px;
        margin: auto;
        height: 100%;
        max-width: 95vw;

        .firstSection {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: auto;
            flex-wrap: wrap;
            .textSection {
                width: 40%;
                h2 {
                    font-size: 25px;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                P {
                    font-size: 16px;
                    font-family: "SpaceGrotesk";
                    line-height: 1.6;
                    margin-bottom: 25px;
                }
            }
            .imageSection {
                width: 45%;
                height: 320px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #infoDestination {
        padding-top: 0;
        .container {
            box-sizing: border-box;
            .firstSection,
            .secondSection {
                .textSection {
                    width: 100%;
                    h2 {
                    }
                    P:first-child {
                        margin-top: 30px;
                    }
                    .primaryBtn {
                        margin-bottom: 50px;
                    }
                }
                .imageSection {
                    width: 100%;
                    align-items: center;
                    img {
                    }
                }
            }

            .secondSection {
                .imageSection {
                    img {
                        &.firstPhoto {
                            width: 100%;
                        }
                        &.secondPhoto {
                            width: 100%;
                        }
                    }
                }
                .textSection {
                }
            }
        }
    }
}
