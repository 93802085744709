#filteredTrips {
    background-color: white;
    margin-left: auto;
    margin-right: 0;
    padding-bottom: 50px;
    padding-top: 20px;

    p.confirmedTrip {
        width: fit-content;
        color: white;
        padding: 2px 10px 2px 7px;
        background: green;
        border-radius: 5px;
        margin-top: 10px;
        font-family: "SpaceGrotesk";
        font-size: 15px;
        line-height: 2;
        position: absolute;
        margin: 0;
        span {
            color: white;
            margin-right: 8px;
        }
    }

    h2 {
        text-align: center;
        margin: 0px;
        padding: 30px 0 40px;
    }

    .container {
        width: auto;

        #oneTripFiltered {
            border: 1px solid #80808038;
            border-radius: 10px;
            cursor: pointer;
            position: relative;
            max-height: 320px;

            .hidden {
                display: none;
            }

            .photoContainer {
                width: 100%;
                cursor: pointer;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100px;
                    border-radius: 10px;
                    cursor: pointer;
                    background: linear-gradient(
                        180deg,
                        rgba(142, 158, 173, 0.7) 0%,
                        #000101b0 0%,
                        rgba(57, 87, 100, 0.04) 96%
                    );
                    width: 100%;
                    height: 100%;
                }
            }

            .tripInfo {
                width: 100%;
                position: absolute;
                top: 0;
                padding: 40px;
                margin: auto;
                left: 0;
                right: 0;
                transform: translate(0%, 0%);
                color: #fff;
                cursor: pointer;
                box-sizing: border-box;
                display: flex;
                height: 100%;
                justify-content: space-between;

                .infoTripBox {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    p {
                        font-size: 16px;
                        color: white;
                        font-family: "SpaceGrotesk-Medium";
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .confirmedTrip {
                            font-size: 15px;
                            font-weight: 600;
                            margin-top: 100px;
                        }

                        img {
                            height: 18px;
                            filter: none;
                            cursor: pointer;

                            &:first-child {
                                height: 20px;
                                filter: initial;
                                border-radius: 0;
                            }
                        }
                    }
                }

                .shortInfo {
                    width: auto;
                    padding-right: 50px;
                    line-height: 1.4;
                }
            }

            .priceSection {
                width: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                right: 5px;
                border-radius: 10px;
                top: 0;
                height: 100%;
                cursor: pointer;

                .priceBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .discount {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;
                        cursor: pointer;

                        .newPrice,
                        .text {
                            color: #dc2b2f;
                            cursor: pointer;
                            font-size: 30px;
                            text-decoration: none;
                            font-family: "SpaceGrotesk-Medium";
                            margin: 0;
                            filter: drop-shadow(2px 4px 6px black);
                        }

                        .text {
                            font-size: 15px;
                            font-family: "SpaceGrotesk";
                        }
                    }

                    .price {
                        color: white;
                        filter: drop-shadow(2px 4px 6px black);
                        font-size: 16px;
                        text-decoration: line-through;
                        margin: 0;
                        margin-top: 10px;
                        cursor: pointer;
                    }

                    .text {
                        color: white;
                        filter: drop-shadow(2px 4px 6px black);
                        margin: 0;
                        font-size: 13px;
                    }

                    .regularPrice {
                        text-align: center;

                        .price {
                            font-size: 30px;
                            text-decoration: none;
                            font-family: "SpaceGrotesk-Medium";
                            margin: 0;
                            color: white;
                        }

                        .text {
                            color: white;
                            filter: drop-shadow(2px 4px 6px black);
                            margin: 0;
                            font-size: 13px;
                        }
                    }
                }
            }

            .detailsBox {
                text-align: center;
                cursor: pointer;

                .amountOfPeople {
                    margin-bottom: 10px;
                    color: white;
                    filter: drop-shadow(2px 4px 6px black);
                }

                .availability {
                    font-family: "SpaceGrotesk-SemiBold";
                    margin-top: 5px;
                    color: white;
                    filter: drop-shadow(2px 4px 6px black);

                    &.red {
                        background: #860202;
                        border-radius: 20px;
                        padding: 7px;
                        margin-top: 10px;
                    }

                    &.green {
                        background: green;
                        border-radius: 20px;
                        padding: 7px;
                        margin-top: 10px;
                    }

                    &.orange {
                        background: orange;
                        border-radius: 20px;
                        padding: 7px;
                        margin-top: 10px;
                    }
                }

                .primaryBtn {
                    max-height: 50px;
                    // min-width: 100px;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }

            .tagsBox {
                width: fit-content;
                bottom: 15px;
                left: 5%;

                .oneTag {
                    font-family: "SpaceGrotesk-Medium";
                    border-radius: 5px;
                    padding: 10px;
                    height: auto;
                    width: auto;
                    font-size: 14px;
                    align-items: center;
                    background-color: white;
                    display: flex;

                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #filteredTrips {
        margin-top: 50px;
        width: 100%;
        margin: auto;
        width: 100%;
        background-color: #fff;
        min-height: 700px;
        padding: 0;
        margin: 0;

        p.confirmedTrip {
            left: 50%;
            transform: translate(-50%);
        }

        h2 {
            margin-bottom: 0;
            margin-top: 40px;
        }

        .container {
            margin: auto;
            padding: 0 20px;
            box-sizing: border-box;

            #oneTripFiltered {
                height: 500px;
                min-height: 450px;
                max-width: 400px;
                margin: auto auto 50px;
                flex-wrap: wrap;

                .tagsBox {
                    width: fit-content;
                    bottom: 0%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    min-width: 150px;
                    display: none;
                }

                .photoContainer {
                    width: 100% !important;
                    height: 200px;
                    height: 100%;

                    .overlay {
                        border-radius: 10px 10px 0 0;
                    }

                    img {
                        border-radius: 10px;
                    }
                }

                .tripInfo {
                    width: auto;
                    top: 0;
                    position: absolute;
                    transform: translate(0%, 0%);
                    left: 0;
                    flex-direction: column;

                    h3 {
                        text-align: center;
                    }

                    .infoTripBox {
                        p {
                            .confirmedTrip {
                                display: block;
                                margin-top: 10px;
                                margin-left: 0;
                            }
                        }
                    }

                    .tagsBox {
                        padding-right: 0;

                        .oneTag {
                            margin-right: 0;
                        }
                    }

                    .shortInfo {
                        padding-right: 0;
                    }
                }

                .priceSection {
                    width: 100%;
                    // position: absolute;
                    // top: 45%;
                    height: auto;
                    cursor: pointer;
                }
            }
        }
    }
}
