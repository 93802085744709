#participants {
    margin-top: 60px;
    width: 100%;
    padding-left: 10px;
    h2 {
        text-align: center;
        margin-bottom: 40px;
    }
    .name {
        padding: 25px 30px;
        width: 100%;
        background-color: #80808021;
        border-radius: 5px;
        box-sizing: border-box;

        p {
            font-size: 23px;
            margin: 0;
        }
    }

    h4 {
        font-size: 23px;
        border-bottom: 1px solid #80808021;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-top: 25px;
        padding-left: 30px;
    }
    .detailsBox {
        width: 800px;
        margin-bottom: 10px;
        margin-left: 30px;
        margin-top: 10px;

        td {
            margin-bottom: 10px;
            max-width: 90vw;
            padding: 12px 0;
            &.firstColumn {
                width: 40%;
                font-family: "SpaceGrotesk-SemiBold";
            }

            &.secondColumn {
                width: 60%;
                span {
                    font-family: "SpaceGrotesk-Medium";
                    font-size: 14px;
                }
            }
        }
    }
    .primaryBtn {
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media only screen and (max-width: 767px) {
    #participants {
        padding-left: 0px;
        .name {
            padding-left: 15px;
        }

        h4 {
            padding-left: 5px;
        }
        .detailsBox {
            margin-left: 5px;
            margin-bottom: 10px;
            width: auto;
            padding: 10px;
            td {
                &.firstColumn {
                    width: 40%;
                    font-family: "SpaceGrotesk-SemiBold";
                }

                &.secondColumn {
                    width: 50%;
                    text-align: right;
                }
            }
        }
    }
}
