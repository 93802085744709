#gallery {
    background-color: white;
    width: 100%;
    min-height: 600px;
    padding-bottom: 80px;
    .container {
        width: 1000px;
        margin: auto;
        max-width: 90vw;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-top: 40px;
        h2 {
            font-size: 28px;
            margin-bottom: 15px;
            margin-top: 0;
        }
        p {
            width: 700px;
            text-align: center;
            max-width: 90vw;
            margin-top: 0;
            margin-bottom: 0;
        }

        form {
            width: auto;
            max-width: 90vw;
            box-sizing: border-box;

            select {
                min-width: 300px;
                margin-top: 20px;
                width: 450px;
                border-radius: 5px;
                border: 1px solid grey;
                padding-right: 0;
                font-size: 17px;
                box-sizing: border-box;
                padding: 20px;
                float: left;
                -webkit-appearance: none;
                background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
                    no-repeat right #dddddd08;
                background-position-x: 97%;
                cursor: pointer;
            }
        }

        .boxes {
            width: 1150px;
            max-width: 90vw;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;
            flex-wrap: wrap;

            .oneBox {
                width: 270px;
                box-shadow: 2px 26px 68px -21px #b8b6b8;
                border-radius: 7px;
                box-sizing: border-box;
                border: 1px solid #80808021;
                margin-bottom: 50px;
                cursor: pointer;
                p {
                    width: auto;
                    text-align: left;
                    margin: 0;
                    color: rgb(43, 51, 63);
                    font-size: 15px;
                    cursor: pointer;
                }

                .imageContainer {
                    width: 100%;
                    height: 170px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 7px 7px 0 0;
                        cursor: pointer;
                    }
                    h4 {
                        margin: auto;
                        font-size: 22px;
                        cursor: pointer;
                    }
                }

                .textContainer {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 30px 20px;
                    cursor: pointer;
                    p {
                        margin: 10px 0;
                        &.tripName {
                            font-family: "SpaceGrotesk-SemiBold";
                            margin-bottom: 10px;
                            margin-top: 0;
                            font-size: 17px;
                            cursor: pointer;
                        }
                        &.placeName {
                            color: #808080bf;
                            font-size: 14px;
                            margin-bottom: 20px;
                            margin-top: 0;
                        }
                    }

                    a {
                        cursor: pointer;
                        text-decoration: none;
                        font-family: "SpaceGrotesk-SemiBold";
                        font-size: 15px;
                        i.arrow {
                            border: solid #000000e8;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 2.5px;
                            transform: rotate(-45deg);
                            margin-left: 6px;
                            margin-bottom: 0px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

#gallery.country {
    padding-bottom: 0;
    min-height: auto;
}

@media only screen and (max-width: 767px) {
    #gallery {
        .container {
            h2 {
                text-align: center;
            }
            p {
            }

            form {
                select {
                    width: auto;
                }
            }

            .boxes {
                justify-content: center;
                .oneBox {
                    margin-bottom: 30px;
                    p {
                    }

                    .imageContainer {
                        img {
                        }
                        h4 {
                        }
                    }

                    .textContainer {
                        p {
                            &.tripName {
                            }
                            &.placeName {
                            }
                        }

                        a {
                            i.arrow {
                            }
                        }
                    }
                }
            }
        }
    }
}
