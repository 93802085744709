#programList {
    min-height: 1000px;
    padding-top: 50px;
}

@media only screen and (max-width: 767px) {
    #programList {
        padding-top: 70px;
    }
}
