#reset.newPass {
    .passwordBox {
        position: relative;
        width: 100%;
        max-width: 350px;

        .fas,
        .fa {
            position: absolute;
            top: 53%;
            right: 12px;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    input[name="newPassword"],
    input[name="newPasswordRepeat"] {
        margin-top: 0;
    }
}
