#yourTrips {
    margin-top: 50px;
    width: 100%;

    h2 {
        text-align: center;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 767px) {
    #yourTrips {
        margin-top: 50px;
        width: 100%;
        margin: auto;
        h2 {
            margin-bottom: 60px;
            margin-top: 40px;
        }
    }
}
