#stepOne {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 70px 140px;
    .lds-roller {
        top: 100%;
    }


    h3 {
        font-size: 25px;
        border-bottom: 1px solid #8080804a;
        padding-bottom: 30px;
        font-family: "SpaceGrotesk-SemiBold";
        margin-bottom: 35px;
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: none;
        min-width: 600px;
        padding-right: 70px;

        .selectRadio {
            form {
                margin-top: 30px;

                .containerInputRadio {
                    p {
                        margin: 0;

                        &:first-child {
                            font-family: "SpaceGrotesk-Medium";
                            font-size: 18px;
                        }

                        &:nth-child(2) {
                            margin-top: 5px;
                            color: #4c4f56cf;
                        }
                    }
                }
            }
        }

        #amountPerson.counterContainer {
            padding-top: 30px;
            padding-bottom: 30px;

            .container {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;

                .counterbox {
                    form {
                        .formCounter {
                            display: flex;
                            width: 220px;
                            justify-content: space-between;

                            button.less,
                            button.more {
                                min-width: auto;
                                border-radius: 50%;
                                width: 50px;
                                padding: 0;
                                cursor: pointer;

                                p {
                                    font-size: 28px;
                                    font-family: "SpaceGrotesk-Medium";
                                    margin: 0;
                                    cursor: pointer;

                                    &.minus {
                                        margin-top: -5px;
                                    }
                                }
                            }

                            input {
                                width: 65px;
                                text-align: center;
                                font-family: "SpaceGrotesk-Medium";
                            }
                        }
                    }
                }

                .redBox {
                    p {
                        line-height: 1.8;
                    }
                }
            }
        }

        #connectionReservation.selectRadio {
            padding-top: 30px;

            .container {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;

                .textBox {
                    width: 460px;
                    margin-bottom: 30px;
                    .boxButton {
                        display: block;
                        margin-top: 20px;

                        .secondaryBtn {
                            
                        }
                    }

                    p {
                        margin-top: 0;
                        margin-right: 50px;
                        font-size: 15px;
                        color: #4c4f56cf;
                        margin-bottom: 30px;
                    }

                    form {
                        .containerInputRadio {
                            height: auto;
                            width: 450px;

                            p {
                                margin: 0;

                                &:first-child {
                                    font-family: "SpaceGrotesk-Medium";
                                    font-size: 18px;
                                    color: inherit;
                                }

                                &:nth-child(2) {
                                    margin-top: 5px;
                                    color: #4c4f56cf;
                                }
                            }
                        }
                    }

                    #liderBox.inputBox {
                        // margin-left: 35px;
                        margin-top: 10px;
                        margin-bottom: 15px;

                        .inputName {
                            font-size: 16px;
                            color: inherit !important;
                            font-family: "SpaceGrotesk-SemiBold";
                            margin-bottom: 5px !important;
                        }

                        .confirmedInformation {
                            margin-bottom: 0 !important;
                        }

                        .formContainer {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            margin-right: 20px;
                            width: 420px;
                            margin-top: 10px;

                            input {
                                width: 280px;
                            }

                            

                                .secondaryBtn {
                                    min-width: auto;
                                    width: 120px;
                                }
                            
                        }

                        .confirmedInformation {}

                        .warningText {
                            width: 100%;
                            margin-bottom: 0 !important;
                        }
                    }

                    .containerInputCheckbox {
                        a {
                            text-decoration: underline;
                            font-family: "SpaceGrotesk-Medium";
                        }
                    }
                }

                .greenBox {
                    height: fit-content;
                    margin-top: 0;
                    width: 350px;

                    p {
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #stepOne {
        flex-direction: column;
        padding: 0;
        align-items: center;
        padding: 30px 0;

        .container {
            max-width: 90vw;
            min-width: auto;
            order: 2;
            padding: 0;

            .selectRadio {
                form {
                    .containerInputRadio {
                        p {
                            &:first-child {}

                            &:nth-child(2) {}
                        }
                    }
                }
            }

            #amountPerson.counterContainer {
                .container {
                    flex-direction: column;

                    .counterbox {
                        form {
                            .formCounter {

                                button.less,
                                button.more {
                                    p {
                                        &.minus {}
                                    }
                                }

                                input {}
                            }
                        }
                    }

                    .redBox {
                        width: auto;

                        p {}
                    }
                }
            }

            #connectionReservation.selectRadio {
                .container {
                    flex-direction: column;

                    .textBox {
                        width: auto;

                        p {}

                        form {
                            .containerInputRadio {
                                p {
                                    &:first-child {}

                                    &:nth-child(2) {}
                                }
                            }
                        }

                        #liderBox.inputBox {
                            .inputName {}

                            .confirmedInformation {}

                            .formContainer {
                                flex-wrap: wrap;
                                width: auto;

                                input {}

                                .secondaryBtn {}
                            }

                            .confirmedInformation {}

                            .warningText {}
                        }

                        .containerInputCheckbox {
                            a {}
                        }
                    }

                    .greenBox {
                        width: auto;

                        p {}
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 484px) {
    #stepOne {
        h3 {}

        .container {
            padding: 15px;
            box-sizing: border-box;

            .selectRadio {
                form {
                    .containerInputRadio {
                        p {
                            &:first-child {}

                            &:nth-child(2) {}
                        }
                    }
                }
            }

            #amountPerson.counterContainer {
                .container {
                    .counterbox {
                        form {
                            .formCounter {

                                button.less,
                                button.more {
                                    p {
                                        &.minus {}
                                    }
                                }

                                input {}
                            }
                        }
                    }

                    .redBox {
                        p {}
                    }
                }
            }

            #connectionReservation.selectRadio {
                .container {
                    .textBox {
                        p {}

                        form {
                            .containerInputRadio {
                                width: auto;

                                p {
                                    &:first-child {}

                                    &:nth-child(2) {}
                                }
                            }
                        }

                        #liderBox.inputBox {
                            .inputName {}

                            .confirmedInformation {}

                            .formContainer {
                                input {
                                    margin: 10px 0 20px;
                                    width: 100%;
                                }

                                .secondaryBtn {}
                            }

                            .confirmedInformation {}

                            .warningText {}
                        }

                        .containerInputCheckbox {
                            a {}
                        }
                    }

                    .greenBox {
                        p {}
                    }
                }
            }
        }
    }
}