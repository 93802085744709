#offerAttractionsSection.bf {
  padding: 70px;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 0;

  h3 {
    text-align: center;
  }

  .descriptionAttr {
    width: 700px;
    margin: auto;
    max-width: 90vw;
    text-align: left;
    padding: 0;

    p {
      text-align: left;
      padding: 0;
      margin: 10px 0;
    }
  }

  p {
    text-align: left;
    padding: 0 15px;
    font-size: 15px;
  }

  .offerAttractionsListWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 1100px;
    margin: auto;
    padding-bottom: 50px;

    .offerSingleAttraction {
      height: 250px;

      .attractionBox {
        width: 100%;
        height: 100%;

        img {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          filter: brightness(0.6);
          object-fit: cover;
        }

        h5 {
          position: absolute;
          font-family: "SpaceGrotesk";
          margin: auto;
          z-index: 1;
          top: 50%;
          left: 50%;
          color: #ffffff;
          text-align: center;
          transform: translate(-50%, -50%);
          width: 90%;
          line-height: 1.7;
          font-size: 18px;
          filter: drop-shadow(2px 4px 6px black);
        }

        p {
          margin: 20px;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #offerAttractionsSection.bf {
    padding: 20px;
    overflow-x: auto;

    .offerAttractionsListWrapper {
      width: max-content;
      flex-wrap: nowrap;
      overflow: hidden;
      padding-bottom: 0;

      .offerSingleAttraction {
        max-width: 250px;
        margin-right: 20px;
      }
    }
  }
}
