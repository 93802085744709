#generalInfo {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 800px;
    padding-bottom: 100px;

    .container {
        width: 1100px;
        margin: auto;
        // border: 1px solid grey;
        height: 100%;
        min-height: 800px;
        max-width: 95vw;

        .firstSection,
        .secondSection {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: auto;
            flex-wrap: wrap;
            padding-top: 60px;
            .textSection {
                width: 40%;
                h2, h1 {
                    font-size: 25px;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                P {
                    font-size: 16px;
                    font-family: "SpaceGrotesk";
                    line-height: 1.6;
                    margin-bottom: 25px;
                }
            }
            .imageSection {
                width: 45%;
                height: 320px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    box-shadow: -1px 6px 26px -9px rgba(0, 0, 0, 0.45);
                }
            }
        }

        .secondSection {
            // flex-direction: row-reverse;

            .imageSection {
                width: 40%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: auto;
                max-height: auto;
                img {
                    &.firstPhoto {
                        width: 90%;
                        height: 200px;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 250px;
                    }
                    &.secondPhoto {
                        width: 80%;
                        height: 150px;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 200px;
                        margin-top: 20px;
                    }
                }
            }
            .textSection {
                width: 45%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #generalInfo {
        .container {
            box-sizing: border-box;
            .firstSection,
            .secondSection {
               
                .textSection {
                    width: 100%;
                    h2, h1 {
                    }
                    P:first-child {
                        margin-top:30px;
                    }
                    .primaryBtn {
                        margin-bottom: 50px;
                    }
                }
                .imageSection {
                    width: 100%;
                    align-items: center;
                    order: 2;
                    img {
                    }
                }
            }

            .secondSection {
               
                .imageSection {
                    img {
                        &.firstPhoto {
                            width: 100%;
                        }
                        &.secondPhoto {
                            width: 100%;
                        }
                    }
                }
                .textSection {
                }
            }
        }
    }
}
