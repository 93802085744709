#singleDiscount {
    width: 1100px;
    margin: auto;
    // width: 100%;
    min-height: 300px;
    border-radius: 5px;
    box-shadow: 2px 26px 68px -21px #b8b6b8;
    display: flex;
    margin-bottom: 50px;
    height: auto;
    margin-top: 50px;

    &.singlePromotion .photoContainer .overlay {
        background-color: rgba(146, 2, 31, 0.39);
    }

    &.singlePartner .photoContainer .overlay {
        background-color: rgba(6, 172, 216, 0.31);
    }

    .photoContainer {
        width: 35%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 0 0 10px;
        }

        h4 {
            position: absolute;
            font-size: 20px;
            text-align: center;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
            color: white;
            width: 100%;
            font-weight: 500;
        }

        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(6, 216, 86, 0.27);
            position: absolute;
            top: 0;
            border-radius: 10px 0 0 10px;

            .text {
                position: absolute;
                top: 50%;
                width: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
                text-align: center;

                p {
                    color: white;

                    &.name {
                        font-size: 20px;
                        margin: auto;
                    }

                    &.discount {
                        font-size: 28px;
                        margin: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .tripInfo {
        padding: 30px;
        width: 100%;
        padding: 40px 50px 40px 50px;

        h3 {
            margin: 0;
        }

        .infoTripBox {
            margin-top: 20px;
            margin-bottom: 20px;

            i {
                margin-left: 2px;
            }

            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 5px;

                img {
                    width: 20px;
                    height: auto;
                    margin-right: 10px;
                }

                .confirmedTrip {
                    color: #237000;
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }

        .optionsBox {
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;

            p {
                margin: 10px 0;
                font-size: 15px;
                

                &:first-child {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            ul {
                margin: 0;

                li {
                    line-height: 1.5;
                    font-size: 15px;
                }
            }
        }
    }
}



@media only screen and (max-width: 767px) {
    #singleDiscount {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
        max-width: 90vw;
        margin-bottom: 50px;

        .tripInfo {
            padding: 20px;
            box-sizing: border-box;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                margin: 10px 0 20px;
            }

            .optionsBox {
                margin-top: 0;
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;

                ul {
                    margin: 0;
                }
                
            }
        }

        .photoContainer {
            width: 100%;
            height: 200px;

            .overlay {
                border-radius: 10px 10px 0 0;

                .text {

                    p.name,
                    p.discount {
                        margin: 0;
                    }
                }
            }

            img {
                border-radius: 10px 10px 0 0;
            }
        }
    }
}