#tripBox {
    height: fit-content;
    border: 1px solid #8080802b;
    // margin-top: 25px;
    border-radius: 10px;
    box-shadow: -1px 14px 20px 3px rgba(0, 0, 0, 0.07);
    position: sticky;
    width: 270px;
    min-width: 270px;
    top: 10px;
    .imageContainer {
        width: 100%;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }

    .textBox {
        padding: 20px;
        text-align: center;
        h4 {
            margin: 0;
            font-family: "SpaceGrotesk-SemiBold";
            margin-bottom: 20px;
            font-size: 22px;
            border: none;
            padding-bottom: 0;
        }
        .date {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 7px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        .chooseOptions {
            border-top: 1px solid #80808036;
            text-align: left;
            padding-bottom: 15px;
            border-bottom: 1px solid #80808036;
            .title {
                color: grey;
                font-size: 15px;
                margin-bottom: 10px;
            }

            .options {
                margin-bottom: 0;
                font-size: 14px;
                margin-top: 5px;
            }

            .price {
                font-family: "SpaceGrotesk-Medium";
                margin-top: 0;
                font-size: 16px;
            }
        }

        .price {
            font-size: 25px;
            margin-bottom: 0;
            font-family: "SpaceGrotesk-SemiBold";
            margin-top: 15px;
        }

        .postscript {
            font-size: 14px;
            width: 160px;
            margin: auto;
            margin-bottom: 10px;
        }

        .rebate {
            color: #00ad34e6;
            font-size: 13px;
            margin: 8px 0px;
            text-align: left;
            font-family: 'SpaceGrotesk-Bold';
             span {
                // font-family: "SpaceGrotesk-SemiBold";
                color: #00ad34;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #tripBox {
        order: 1;
        position: relative;
        .imageContainer {
            img {
            }
        }

        .textBox {
            h4 {
            }
            .date {
                img {
                }
            }

            .chooseOptions {
                .title {
                }

                .options {
                }

                .price {
                }
            }

            .price {
            }

            .postscript {
            }

            .rebate {
                span {
                }
            }
        }
    }
}
