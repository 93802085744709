#vouchers {
    margin-top: 50px;
    width: 100%;

    .greenBox p {
        max-width: fit-content;
    }

    .containerButton {
        display: flex;
        flex-direction: column;
        width: fit-content;
        justify-content: space-between;
    }

    h2 {
        text-align: center;
        margin-bottom: 60px;
    }

    /* VOUCHERS TABLE */
    .vouchersTable {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border-spacing: 0;

        .tableHead {
            background-color: #e4f4fb;
            border-radius: 8px 8px 0 0;
            border-bottom: 0;
            max-height: 80px;
        }

        th {
            padding: 30px 30px 30px 30px;
            color: #1d313e;
            text-align: center;
            font-family: "SpaceGrotesk-SemiBold";
            width: 30%;
            font-size: 14.5px;

            &:first-child {
                border-radius: 10px 0 0 0;
                width: 150px;
                min-width: fit-content;
                input {
                    width: max-content;
                }
            }

            &:nth-child(2) {
                width: min-content;
                min-width: fit-content;
            }
            &:nth-child(3) {
                margin-left: 10px;
            }

            &:last-child {
                border-radius: 10px 0 0 0;
                border-radius: 0 10px 0 0;
            }

            &:last-child {
                width: fit-content;
                min-width: 140px;
                text-align: center;
            }
        }

        td {
            padding: 20px 30px 20px 30px;
            // cursor: pointer;
            width: 30%;
            font-size: 14.5px;
            text-align: center;

            &:first-child {
                width: 150px;
                input {
                    width: 150px;
                    margin-top: 0;
                }
            }

            &:nth-child(2) {
                width: min-content;
                min-width: fit-content;
            }

            &:last-child {
                width: fit-content;
                min-width: 140px;
                text-align: center;
            }
            span {
                font-family: "SpaceGrotesk-Medium";
            }

            input {
                height: 30px;
                border-radius: 7px;
                font-size: 16px;
                font-family: "SpaceGrotesk";
                box-sizing: border-box;
                padding: 23px 15px;
                margin-top: 10px;

                &:focus {
                    outline: #326bc8 auto 1px;
                    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.19);
                }
            }
        }

        tr {
            border-bottom: 1px solid #80808012;
            // cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    p.infoText {
        margin-top: 0;
        color: #1eb74c;
    }

    .primaryBtn {
        display: flex;
        margin-top: 47px;
        justify-content: center;
        align-items: center;
    }

    h4 {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 996px) {
    #vouchers {
        margin-top: 50px;
        width: 100%;
        margin: auto;

        h2 {
            margin-bottom: 60px;
            margin-top: 40px;
        }

        .primaryBtn {
            display: flex;
            margin: auto;
            margin-top: 47px;
        }
    }
}

@media (max-width: 768px) {
    #vouchers {
        .vouchersTable {
            .tableHead {
                position: absolute;
                top: -9999em;
                left: -9999em;
            }

            tr {
                display: block;
                width: auto;
                border-radius: 10px;
                margin-bottom: 20px;
                border-bottom: 0;
                box-shadow: 3px 3px 26px 2px rgba(173, 173, 173, 33%);
                padding: 10px 0;
            }

            td {
                // padding-left: 50%;
                display: block;
                width: auto;
                border: none;
                text-align: left;
                padding: 10px 20px;

                input {
                    margin-top: 10px;
                }

                &:first-child {
                    width: auto;
                    display: flex;
                    flex-direction: column;
                    input {
                        margin-top: 10px;
                    }
                }
                &:last-child {
                  text-align: left;
                }

                &:before {
                    content: attr(data-label);
                    display: inline-block;
                    font-weight: bold;
                    line-height: 1.5;
                    // margin-left: -100%;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 20em) {
    #vouchers {
        .vouchersTable {
            td {
                padding-left: 0.75em;

                &:before {
                    display: block;
                    margin-bottom: 0.75em;
                    margin-left: 0;
                }
            }
        }
    }
}
